import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Ricon,
  theme,
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { DialogContentType } from 'store/globalDialog/atoms';
import useGlobalDialog from 'store/globalDialog/useGlobalDialog';

export const CommonDialog = ({
  title,
  content,
  color = 'primary',
  trigger,
  isTitleAlert = true,
}: DialogContentType) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { removeCurrent } = useGlobalDialog();

  return (
    <Dialogue open={true}>
      <DialogueTitle>
        {isTitleAlert && (
          <Stack sx={{ mr: '8px' }}>
            <Ricon icon="ri-alert-fill" svgProps={{ fill: theme.palette.error.main, width: 22, height: 22 }} />
          </Stack>
        )}
        {t(title)}
      </DialogueTitle>
      <DialogueContent>{t(content)}</DialogueContent>
      <DialogueActions>
        {Object.entries(trigger).map(([key, value]) => (
          <Button
            key={key}
            onClick={async () => {
              setIsLoading(true);
              try {
                if (value.onClick) await value.onClick();
              } finally {
                setIsLoading(false);
                removeCurrent();
              }
            }}
            size={36}
            sx={{ minWidth: '100px' }}
            color={key === 'action' ? color : 'grey'}
            variant={key === 'action' ? 'contained' : 'outlined'}
            loading={key !== 'cancel' && isLoading}
          >
            {t(value.text)}
          </Button>
        ))}
      </DialogueActions>
    </Dialogue>
  );
};
