import { OrderItem } from 'client/types.gen';
import { useRecoilState } from 'recoil';
import { remakeSelectToothState } from 'shared/atoms/remakeSelectTooth/atom';

export const useRemakeSelectTooth = () => {
  const [selectedOrderItems, setSelectedOrderItems] = useRecoilState(remakeSelectToothState);

  const selectedGroupId = Array.from(
    new Set(
      selectedOrderItems?.map((item) => {
        if (item.groupId) return item.groupId;
        return String(item.toothNumber);
      }),
    ),
  );

  const handleSelectOrderItem = (orderItem: OrderItem[]) => {
    if (!selectedOrderItems) {
      setSelectedOrderItems([...orderItem]);
    } else {
      const isExsitOrderItem = orderItem.some((item) =>
        selectedGroupId.includes(item.groupId || String(item.toothNumber)),
      );

      if (isExsitOrderItem) {
        setSelectedOrderItems(
          selectedOrderItems.filter(
            (selectedItem) =>
              !orderItem.some((order) => {
                if (order.groupId) return order.groupId === selectedItem.groupId;
                return order.toothNumber === selectedItem.toothNumber;
              }),
          ),
        );
      } else {
        setSelectedOrderItems([...selectedOrderItems, ...orderItem]);
      }
    }
  };

  const resetSelectOrderItem = () => {
    setSelectedOrderItems(null);
  };

  return { selectedOrderItems, selectedGroupId, handleSelectOrderItem, resetSelectOrderItem };
};
