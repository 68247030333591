import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, DialogueContent } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import ProsthesisButtonList from 'pages/NewOrder/components/Prescription/ProductOptionDialog/ProsthesisButtonList';
import { RADIO_CHIP_BUTTON_TYPE } from 'pages/NewOrder/constants/prescription';

export type ProthesisSelectionPropType = {
  onProsthesisClick: (prosthesis: string) => void;
};

function ProsthesisSelection({ onProsthesisClick }: ProthesisSelectionPropType) {
  const { t } = useTranslation();
  const [selectedChip, setSelectedChip] = useState('All options');

  return (
    <DialogueContent>
      <Stack gap="16px">
        <Stack flexDirection="row" gap="8px">
          {RADIO_CHIP_BUTTON_TYPE.map(({ label, key }) => {
            return (
              <Chip
                key={label}
                label={t(key)}
                color={selectedChip === label ? 'primary' : 'default'}
                onClick={() => {
                  setSelectedChip(label);
                }}
              />
            );
          })}
        </Stack>

        <Stack gap="8px">
          <ProsthesisButtonList onProsthesisClick={onProsthesisClick} />
        </Stack>
      </Stack>
    </DialogueContent>
  );
}

export default ProsthesisSelection;
