import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Cicon, IconButton } from '@imago-cloud/design-system';
import { ButtonProps } from '@imago-cloud/design-system/lib/esm/Components/Inputs/Button/Button';
import { Stack } from '@mui/material';
import { ROUTES } from 'constants/routes';

interface NewCaseButtonProps extends ButtonProps<'button'> {
  isCollapse?: boolean;
}

export default function NewCaseButton({ isCollapse, ...others }: Readonly<NewCaseButtonProps>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      {isCollapse ? (
        <IconButton
          variant="cta"
          sx={{
            marginBottom: '28px',
          }}
        >
          <Cicon icon="CtaAdd" />
        </IconButton>
      ) : (
        <Button
          variant="cta"
          startIcon={<Cicon icon="CtaAdd" />}
          id="newCaseButton"
          data-cy="newCaseButton"
          disableFocusRipple
          onClick={() => navigate(`${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.newOrder}`)}
          {...others}
        >
          {!isCollapse && <Stack sx={{ whiteSpace: 'nowrap' }}>{t('user_side_snb.btn_new')}</Stack>}
        </Button>
      )}
    </>
  );
}
