import { Address } from 'client/types.gen';
import { TFunction } from 'i18next';

export const capitalizeFirstLetter = (str: string) => str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();

export const formattedAddress = ({ fullAddress, detailAddress, zipCode }: Address) => {
  return `(${zipCode}) ${fullAddress} ${detailAddress} `;
};

export const formattedPrice = (price: number, t?: TFunction) =>
  `${t ? t('currency_yen', { variable: price.toLocaleString() }) : `JPY ${price.toLocaleString()}`}`;
