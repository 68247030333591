import { theme } from '@imago-cloud/design-system';

const DEFAULT = {
  display: 'none',
};

const AVAILABLE = {
  '&': {
    fill: '#FFF',
    stroke: theme.palette.divider,
  },
  '&:hover': {
    fill: theme.palette.primary.light,
    // stroke: theme.palette.divider,
  },
};

const SELECTED = {
  fill: theme.palette.blue[500],
  stroke: theme.palette.blue[500],
};

export const BRIDGE_STYLE = {
  DEFAULT,
  AVAILABLE,
  SELECTED,
};

export type BRIDGE_STYLE_KEY = keyof typeof BRIDGE_STYLE;
