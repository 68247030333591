import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import vtkGenericRenderWindow from '@kitware/vtk.js/Rendering/Misc/GenericRenderWindow';
import { IMeshItem } from 'shared/types/viewer';
import { createGenericRenderWindow } from 'shared/utils/Viewer/rendering';

export interface VTKContextType {
  renderingDataLists: { main: IMeshItem[]; sub: IMeshItem[] };
  setRenderWindowContainer: (ref: HTMLElement, type: 'main' | 'sub') => void;
  renderingToRenderer: (meshList: IMeshItem[], type: 'main' | 'sub') => void;
  renderWindows: { main: vtkGenericRenderWindow | undefined; sub: vtkGenericRenderWindow | undefined };
  handleResetCamera: (type: 'main' | 'sub') => void;
}

const VTKContext = createContext<VTKContextType | undefined>(undefined);

const VtkProvider = ({ children }: { children: ReactNode }) => {
  const [renderWindows, setRenderWindows] = useState<{
    main: vtkGenericRenderWindow | undefined;
    sub: vtkGenericRenderWindow | undefined;
  }>({
    main: undefined,
    sub: undefined,
  });

  const [renderingDataLists, setRenderingDataLists] = useState<{ main: IMeshItem[]; sub: IMeshItem[] }>({
    main: [],
    sub: [],
  });

  const initializeRenderWindows = () => {
    const newMainRenderWindow = createGenericRenderWindow();
    const newSubRenderWindow = createGenericRenderWindow();
    setRenderWindows({
      main: newMainRenderWindow,
      sub: newSubRenderWindow,
    });
  };

  const setRenderWindowContainer = (ref: HTMLElement, type: 'main' | 'sub') => {
    const renderWindow = renderWindows[type];
    if (!renderWindow) return;
    renderWindow.setContainer(ref);
    renderWindow.resize();
  };

  const updateRenderer = (
    renderWindow: vtkGenericRenderWindow,
    newRenderingDataList: IMeshItem[],
    type: 'main' | 'sub',
  ) => {
    const renderer = renderWindow.getRenderer();
    setRenderingDataLists((prevRenderingDataLists) => {
      const prevRenderingDataList = prevRenderingDataLists[type];
      if (prevRenderingDataList.length > 0) {
        for (const item of prevRenderingDataList) {
          const renderingObject = item.renderingData.renderingObject;
          if (!renderingObject) continue;
          renderingObject.removeFromRenderer(renderer);
          renderingObject.update();
        }
        renderWindow.getRenderWindow().render();
      }

      for (const item of newRenderingDataList) {
        const renderingObject = item.renderingData.renderingObject;
        if (!renderingObject) continue;
        renderingObject.addToRenderer(renderer);
        renderingObject.update();
      }

      renderer?.resetCamera();
      renderer?.resetCameraClippingRange();
      const cam = renderWindow.getRenderer().getActiveCamera();
      const size = renderWindow.getOpenGLRenderWindow().getSize();
      const ratio = Math.min(size[0] / size[1], size[1] / size[0]);
      cam.setParallelScale(cam.getParallelScale() / ratio);
      renderWindow.getRenderWindow().render();

      return {
        ...prevRenderingDataLists,
        [type]: newRenderingDataList,
      };
    });
  };

  const renderingToRenderer = (newRenderingDataList: IMeshItem[], type: 'main' | 'sub') => {
    const renderWindow = renderWindows[type];
    if (!renderWindow) return;
    updateRenderer(renderWindow, newRenderingDataList, type);
  };

  const handleResetCamera = (type: 'main' | 'sub') => {
    const renderWindow = renderWindows[type];
    if (!renderWindow) return;
    renderWindow.getRenderer().resetCamera();
    renderWindow.getRenderer().resetCameraClippingRange();

    const cam = renderWindow.getRenderer().getActiveCamera();
    const size = renderWindow.getOpenGLRenderWindow().getSize();
    const ratio = size[0] < size[1] ? size[0] / size[1] : size[1] / size[0];
    const ps = cam.getParallelScale();
    cam.setParallelScale(ps / ratio);

    renderWindow.getRenderWindow().render();
  };

  useEffect(() => {
    initializeRenderWindows();
  }, []);

  return (
    <VTKContext.Provider
      value={{
        renderingDataLists,
        renderWindows,
        setRenderWindowContainer,
        renderingToRenderer,
        handleResetCamera,
      }}
    >
      {children}
    </VTKContext.Provider>
  );
};

export default VtkProvider;

export const useVTKContext = (): VTKContextType => {
  const context = useContext(VTKContext);
  if (context === undefined) {
    throw new Error('useVTKContext must be used within a VtkProvider');
  }
  return context;
};
