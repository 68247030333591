import React, { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Address,
  dentitionType,
  designManufactureCombination,
  FileInfo,
  OrderCreateRequest,
  OrderItemRequest,
  OrderShippingCreateRequest,
  PatientRequest,
  PersonName,
  ReceiverInfoRequest,
  RemakeInfoRequest,
} from 'client/types.gen';

export type BinaryFileObject = { file: File; thumbnail?: Blob };

export type CreateOrderRequestForClient = Omit<OrderCreateRequest, 'orderItems'> & {
  orderItems: Array<OrderItemRequest>;
  printingFilesDisplay: Array<string>;
  binaryFiles: Array<BinaryFileObject>; // with Thumbnail if 3D model file.
  remakeOrderInfo?: RemakeInfoRequest | null;
};

type NewOrderDataContextValue = {
  orderData: CreateOrderRequestForClient;
  setOrderData: Dispatch<SetStateAction<CreateOrderRequestForClient>>;
  shippingData: OrderShippingCreateRequest;
  setShippingData: Dispatch<SetStateAction<OrderShippingCreateRequest>>;
};

export const NewOrderDataContext = createContext<NewOrderDataContextValue | null>(null);

export default function NewOrderDataProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [orderData, setOrderData] = useState<CreateOrderRequestForClient>(OrderRequestDefaultValues);
  const [shippingData, setShippingData] = useState<OrderShippingCreateRequest>(OrderShippingRequestDefaultValues);
  const location = useLocation();
  useEffect(() => {
    return () => {
      setOrderData(OrderRequestDefaultValues);
      setShippingData(OrderShippingRequestDefaultValues);
    };
  }, [location]);

  const contextValue = useMemo(
    () => ({ orderData, setOrderData, shippingData, setShippingData }),
    [orderData, shippingData],
  );

  return <NewOrderDataContext.Provider value={contextValue}>{children}</NewOrderDataContext.Provider>;
}

export const patientDefaultValues = {
  name: '',
  birth: '',
  gender: 'MALE',
  age: '',
  tryInDate: '',
  visitDate: '',
} as PatientRequest;

const OrderShippingRequestDefaultValues = {
  orderId: '',
  receiver: {} as ReceiverInfoRequest,
  shippingAddress: {} as Address,
  deliveryInstruction: '',
} as OrderShippingCreateRequest;

export const OrderRequestDefaultValues = {
  ordererName: {} as PersonName,
  patient: patientDefaultValues,
  designConfirmProcessIncluded: false,
  designManufactureCombination: 'DESIGN_AND_MANUFACTURE' as designManufactureCombination,
  dentitionType: 'PERMANENT' as dentitionType,
  orderItems: [] as Array<OrderItemRequest>,
  patientFiles: [] as Array<FileInfo>, // file id 추가
  patientPrintingFiles: [] as Array<FileInfo>, // 업로드한 파일 id 이름
  note: '',
  remakeOrderInfo: null,
  // for FE
  printingFilesDisplay: [] as Array<string>, // 파일 이름
  binaryFiles: [],
} as CreateOrderRequestForClient;
