import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROLE } from 'constants/role';
import { usePermission } from 'shared/hooks/usePermission';

import { getSendbirdSessionToken } from '../../../api/sendbird';
import { useUserQuery } from '../../../api/userQuery';
import { RetryChatInfo } from '../../../pages/OrderDetail/OrderDetail';
import { LoadingCenter } from '../../ui/loading/LoadingCenter';
import GroupChannelRetry from './GroupChannelRetry';

const myColorSet = {
  '--sendbird-light-primary-100': '#fff',
  '--sendbird-light-primary-200': '#6BD0FF',
  '--sendbird-light-primary-300': '#00A3EC',
  '--sendbird-light-primary-400': '#0083CA',
  '--sendbird-light-primary-500': '#333333',
};

interface CustomSendbirdProviderProps {
  children: ReactNode;
  retryChatInfo?: RetryChatInfo;
}

function CustomSendbirdProvider({ children, retryChatInfo }: Readonly<CustomSendbirdProviderProps>) {
  const { pathname } = useLocation();
  const { role } = usePermission();
  const {
    data: { data },
  } = useUserQuery();

  const [chatUserInfo, setChatUserInfo] = useState({
    userId: '',
    nickname: '',
  });
  const [sessionToken, setSessionToken] = useState('');

  useEffect(() => {
    if (!data || !role) return;
    const getChatUserInfo = () => {
      // sendbird Id
      const isMyOrdersPage = ['placed-order', 'order/new'].some((path) => pathname.includes(path));
      const userId = isMyOrdersPage ? (data?.userId as string) : (data?.organization?.id as string);

      // Nickname
      const isUserSidePage = [ROLE.USER_MANAGER, ROLE.USER_MEMBER].includes(role) || isMyOrdersPage;
      const nicknameByPathname = isUserSidePage
        ? `${data?.name?.firstName} ${data?.name?.lastName}`
        : data?.organization?.name;

      return { userId, nickname: nicknameByPathname as string };
    };

    const chatUserInfo = getChatUserInfo();
    // TODO: useQuery if required
    getSendbirdSessionToken(chatUserInfo.userId)
      .then((res) => {
        setSessionToken(res.data?.token as string);
      })
      .catch((err) => {
        console.log('Sendbird', err);
        setSessionToken('-1');
        setChatUserInfo({ userId: 'unknown', nickname: 'unknown' });
      });
    setChatUserInfo(chatUserInfo);

    return () => {
      setChatUserInfo((prev) => ({
        userId: '',
        nickname: '',
      }));
      setSessionToken((prev) => '');
    };
  }, [pathname, data, role, getSendbirdSessionToken]);

  if (sessionToken === '' || chatUserInfo.userId === '') return <LoadingCenter />;

  return (
    <SendbirdProvider
      // key={sessionToken}
      appId={process.env.REACT_APP_SENDBIRD_APP_ID as string}
      userId={chatUserInfo.userId}
      colorSet={myColorSet}
      accessToken={sessionToken}
    >
      {retryChatInfo && <GroupChannelRetry retryChatInfo={retryChatInfo} />}
      {children}
    </SendbirdProvider>
  );
}

export default CustomSendbirdProvider;
