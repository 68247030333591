import { useMutation, useSuspenseQuery, UseSuspenseQueryResult } from '@tanstack/react-query';
import { ResponseDtoUserGetResponse, UserOrganizationUpdateRequest } from 'client/types.gen';

import { queryClient } from '../shared/providers';
import { kyInstance } from './kyInstance';
import { getUser } from './user';

export const useUserQuery = (): UseSuspenseQueryResult<ResponseDtoUserGetResponse> => {
  return useSuspenseQuery({
    queryKey: ['userData'],
    queryFn: getUser,
  });
};

export const usePostUserQuery = () => {
  return useMutation({
    mutationFn: async ({ organizationId }: UserOrganizationUpdateRequest) =>
      kyInstance.post(`connect/users`, { json: { organizationId: organizationId } }),
  });
};

//get setting info for language
export const useSettingQuery = (): UseSuspenseQueryResult<{
  success: boolean;
  message: string;
  data: { language: string; dentalNotation: string; dateFormat: string };
}> => {
  return useSuspenseQuery({
    queryKey: ['setting'],
    queryFn: () => kyInstance.get('cloud/v4/setting/system').json(),
  });
};
// language post
export const useLanguageMutation = () => {
  const { data } = useSettingQuery();
  const settingData = data?.data;

  return useMutation({
    // mutationKey:[],
    mutationFn: async (language: string) =>
      kyInstance.put('cloud/v4/setting/system', { json: { ...settingData, language } }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['userData'] }),
  });
};
