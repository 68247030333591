import { useTranslation } from 'react-i18next';
import { css, GlobalStyles as GlobalStyle, Theme } from '@mui/material';

const GlobalStyles = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { fontFamily } = getFontStyleByLanguage(language);

  return <GlobalStyle styles={(theme) => globalStyle(theme, fontFamily)} />;
};

export default GlobalStyles;

const globalStyle = (theme: Theme, fontFamily?: string) => css`
  body {
    color: ${theme.palette.text.primary};
    ${fontFamily ? `font-family: ${fontFamily}` : ''}
  }
`;
const getFontStyleByLanguage = (language: string) => {
  switch (language) {
    case 'ja': {
      return {
        fontFamily: 'Inter, Noto Sans JP, Arial, Helvetica, sans-serif',
      };
    }
    case 'en':
    default: {
      return { fontFamily: 'Inter, Arial, Helvetica, sans-serif' };
    }
  }
};
