import { useTranslation } from 'react-i18next';
import { Button, Cicon, Dialogue, DialogueActions, DialogueContent, DialogueTitle } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { ICloseButtonOnlyDialogueProps } from 'shared/hooks/useDialogue';

export const UploadLimitDialogue = ({ open, onClose }: ICloseButtonOnlyDialogueProps) => {
  const { t } = useTranslation();

  return (
    <Dialogue open={open}>
      <DialogueTitle>
        <Stack gap="8px" direction="row" alignItems="center">
          <Cicon
            icon="Warning"
            svgProps={{
              width: 22,
              height: 22,
            }}
          />
          Upload limit exceeded
        </Stack>
      </DialogueTitle>
      <DialogueContent sx={{ padding: '0 28px 28px 28px' }}>A maximum of 10MB can be uploaded.</DialogueContent>
      <DialogueActions>
        <Button size="36" color="error" variant="contained" onClick={onClose} sx={{ minWidth: '100px' }}>
          Confirm
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
