import { useContext } from 'react';

import { NewOrderDataContext } from './NewOrderDataProvider';

export default function useNewOrderDataContext() {
  const context = useContext(NewOrderDataContext);

  if (!context) {
    throw new Error('Order Data Context cannot be used outside the Order Page');
  }

  return context;
}
