import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import BackStepTitle from 'pages/NewOrder/components/BackStepTitle';
import SplitHalfLayout from 'pages/NewOrder/components/SplitHalfLayout';
import { OrderInfo } from 'pages/NewOrder/components/Submit/OrderInfo';
import { PatientInfo } from 'pages/NewOrder/components/Submit/PatientInfo';
import { SubmitOrder } from 'pages/NewOrder/components/Submit/SubmitOrder';
import { ScrollArea } from 'shared/styles/scrollArea';

import CustomSendbirdProvider from '../../../../shared/hooks/Sendbird/CustomSendbirdProvider';

type SubmitPagePropsType = {
  onPrevious: () => void;
};

export const Submit = ({ onPrevious }: SubmitPagePropsType) => {
  const { t } = useTranslation();

  return (
    <SplitHalfLayout>
      <SplitHalfLayout.Left sxProps={{ position: 'relative' }}>
        <BackStepTitle stepName={t('order_overview.title')} order={'3/3'} onPrevious={onPrevious} isDirty={true} />
        <ScrollArea sx={{ width: '100%', height: '100vh', overflow: 'hidden auto', marginTop: '84px' }}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              width: '100%',
              height: '100%',
              padding: '0 68px 10px 68px',
              overflow: 'auto',
            }}
          >
            <PatientInfo />
            <OrderInfo />
          </Stack>
        </ScrollArea>
      </SplitHalfLayout.Left>
      <SplitHalfLayout.Right sxProps={{ position: 'relative' }}>
        <CustomSendbirdProvider>
          <SubmitOrder />
        </CustomSendbirdProvider>
      </SplitHalfLayout.Right>
    </SplitHalfLayout>
  );
};
