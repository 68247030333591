import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, theme, Typography } from '@imago-cloud/design-system';
import { DragDrop } from '@imago-cloud/design-system/lib/esm/Components/CiIcon/assets';
import { alpha, Stack } from '@mui/material';
import { useFileUploadWithDnD } from 'shared/hooks/useFileUploadWithDnD';

import useGlobalDialog from '../../../../store/globalDialog/useGlobalDialog';
import { IMPORT_FILE_LIMITATIONS } from '../../constants/dialog';
import { validateFileLimits } from '../../utils/validateFileLimit';

type DndUploadPropType = {
  currentFiles: File[];
  saveFiles: (files: File[] | null) => void;
};

export default function DndUpload({ currentFiles, saveFiles }: DndUploadPropType) {
  const { t } = useTranslation();
  const { addDialog } = useGlobalDialog();
  const { isDragOver, handleDragOver, handleDragLeave, handleDrop, handleFileChange } = useFileUploadWithDnD({
    callback: (files) => {
      if (files && files.length > 0) {
        const { isFileSizeLimit, isFileCountLimit } = validateFileLimits(files, currentFiles);

        if (isFileCountLimit || isFileSizeLimit) {
          addDialog(IMPORT_FILE_LIMITATIONS);
          return;
        }
        saveFiles(files);
      }
    },
  });

  return (
    <Stack
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        border: isDragOver ? `4px dashed ${theme.palette.primary.main}` : '',
        backgroundColor: isDragOver ? alpha(theme.palette.primary.main, 0.08) : '',
      }}
    >
      <input
        hidden
        type="file"
        id="file-input"
        multiple
        accept="*/*"
        onChange={handleFileChange}
        // ref={(input) => input && !dragOver && input.click()}
      />
      <Stack alignItems="center" gap="20px">
        <DragDrop width="140" height="140" />
        <Stack alignItems="center" gap="8px">
          <Typography variant="H18">{t('import_data_field.drag_and_drop_file')}</Typography>
          <Button variant="outlined" size="30" color="grey" component="label" htmlFor="file-input">
            {t('import_data_field.btn_browse')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
