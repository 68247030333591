import { GroupChannelHeader } from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import React from 'react';
import { IconButton, Ricon, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

interface CustomGroupChannelHeaderProps {
  channelName: string;
  onBackButtonClick: () => void;
  onChatToggleButtonClick: () => void;
}

export default function CustomGroupChannelHeader({
  channelName,
  onChatToggleButtonClick,
  onBackButtonClick,
}: CustomGroupChannelHeaderProps) {
  return (
    <GroupChannelHeader
      renderLeft={() => (
        <Stack onClick={onBackButtonClick}>
          <Ricon icon="ri-arrow-left-line" svgProps={{ width: '24px', height: '24px' }} />
        </Stack>
      )}
      renderMiddle={() => {
        return <Typography variant="H18">{channelName}</Typography>;
      }}
      renderRight={() => (
        <IconButton onClick={onChatToggleButtonClick} size="32" icon_size="small" color="grey900" variant="transparent">
          <Ricon icon="ri-close-line" svgProps={{ width: '24px', height: '24px' }} />
        </IconButton>
      )}
    />
  );
}
