import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, TextFieldProps } from '@imago-cloud/design-system';

type RHFSelectPropType = TextFieldProps & {
  name: string;
  selectMenuItemList: Array<{ label: string; value: string }>;
  disabled?: boolean;
  onSelectChange?: () => void;
  rules?: any;
};
export default function RHFSelect({
  name,
  selectMenuItemList,
  disabled = false,
  onSelectChange,
  rules,
  label_visible,
  ...props
}: RHFSelectPropType) {
  const { t } = useTranslation();
  const { control, trigger, watch } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          onChange(e.target.value as string);
          if (onSelectChange) onSelectChange();
          trigger(name);
        };
        return (
          <Select
            name={name}
            value={watch(name) || ''}
            disabled={disabled}
            onChange={handleChange}
            onBlur={(e) => {
              trigger(name);
            }}
            error={!!error}
            helperText={error?.message}
            sx={{ width: '100%' }}
            label_visible={label_visible ?? 'all'}
            {...props}
          >
            {selectMenuItemList.map((item) => (
              <MenuItem key={item.label} value={item.value}>
                {t(item.label)}
              </MenuItem>
            ))}
          </Select>
        );
      }}
      rules={{
        ...rules,
      }}
    />
  );
}
