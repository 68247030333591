import { useTranslation } from 'react-i18next';
import { theme as ITheme, ThemeProvider } from '@imago-cloud/design-system';
import { useI18nFont } from '@imago-cloud/front-module/lib/esm/imago-i18n';
import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

type ImagoThemeProviderPropType = { children: React.ReactNode };
export const ImagoThemeProvider = ({ children }: ImagoThemeProviderPropType) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { typography: i18nTypography, MuiTypography: i18nMuiTypography } = useI18nFont(language);

  const getImagoTheme = () =>
    createTheme({
      ...ITheme,
      components: {
        ...ITheme.components,
        MuiInputBase: {
          defaultProps: {
            autoComplete: 'off',
          },
        },
        MuiTypography: {
          ...i18nMuiTypography,
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 1280,
          lg: 1920,
          xl: 2560,
        },
      },
      typography: {
        ...i18nTypography,
      } as TypographyOptions,
    });
  const theme = getImagoTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
