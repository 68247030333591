import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermission } from 'shared/hooks/usePermission';

interface PrivateRouteProps {
  children: React.ReactNode;
  entryPermission: string;
}

export default function PrivateRoute({ children, entryPermission }: PrivateRouteProps) {
  const navigate = useNavigate();
  const { accessiblePermissions } = usePermission();

  const hasPermission = accessiblePermissions.includes(entryPermission);

  useLayoutEffect(() => {
    if (!hasPermission) {
      return navigate('/dashboard/placed', { replace: true });
    }
  }, [accessiblePermissions, navigate]);

  return hasPermission ? <>{children}</> : null;
}
