import { useTranslation } from 'react-i18next';
import { CardContainer } from 'shared/ui/Global/CardContainer';
import { ToothContent } from 'shared/ui/toothProductDetail/ToothContent';

export const AdditionalOptions = ({
  printing3DModelLengths,
  price,
}: {
  printing3DModelLengths: number;
  price: number;
}) => {
  const { t } = useTranslation();
  if (printing3DModelLengths === 0) return null;
  const label = `${printing3DModelLengths} ${t(`patient_page_info.${printing3DModelLengths > 1 ? 'orders' : 'order'}`)}`;

  return (
    <CardContainer>
      <ToothContent
        title={t('patient_page_info.title_additional_options')}
        rows={[
          {
            title: t('patient_page_info.subtitle_3d_model'),
            label,
            additionalPrice: price * printing3DModelLengths,
          },
        ]}
      />
    </CardContainer>
  );
};
