import React from 'react';
import { useTranslation } from 'react-i18next';
import { Ricon, theme, Tooltip, TooltipProps } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

type NoticeTooltip = {
  description: string;
  placement?: TooltipProps['placement'];
  iconColor?: string;
  iconSize?: number;
};

export const NoticeTooltip = ({
  description,
  placement = 'right',
  iconColor = theme.palette.primary.main,
  iconSize = 30,
}: NoticeTooltip) => {
  const { t } = useTranslation();
  const transDescription = t(description).split('\n');
  const isSingleDescription = transDescription.length === 1;
  return (
    <Tooltip
      placement={placement}
      title={
        <ul
          style={{
            margin: 0,
            listStyleType: isSingleDescription ? 'none' : 'disc',
            padding: isSingleDescription ? 0 : '0 0 0 14px',
          }}
        >
          {transDescription.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      }
    >
      <Stack sx={{ alignItems: 'center' }}>
        <Ricon icon={'ri-information-line'} svgProps={{ width: iconSize, height: iconSize, fill: iconColor }} />
      </Stack>
    </Tooltip>
  );
};
