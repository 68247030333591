import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Typography,
} from '@imago-cloud/design-system';
import { List, ListItem, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { organizationDataState } from 'shared/atoms/organization/atoms';
import { FormProvider } from 'shared/ui';
import { RHFSelect } from 'shared/ui/RHF';
import { useGlobalDialog } from 'store';

import { usePostOrganization } from '../../../api/organization';
import { usePostUserQuery } from '../../../api/userQuery';
import { corporateNumberType } from '../../../client';
import { ORGANIZATION_TYPE_LIST } from '../../constants/organization';
import useOrganizationHookFormHandling, { OrganizationFormType } from '../../hooks/useOrganizationHookFormHandling';
import {
  OrganizationAddressField,
  OrganizationFieldProps,
  OrganizationNameField,
  OrganizationOrganizationCodeField,
  OrganizationPhoneNumberField,
} from './CommonField';
import { ORGANIZATION_DIALOGUE_STATE } from './organizationForm';
import { SearchPhoneNumberField } from './SearchPhoneNumberField';

export const CompleteProfileDialogue = () => {
  const { t } = useTranslation();
  const { removeDialog } = useGlobalDialog();

  const { methods, onBlur } = useOrganizationHookFormHandling();
  const {
    handleSubmit,
    watch,
    setError,
    formState: { isValid },
  } = methods;
  const originalPhoneNumber = useRef(watch('businessPhone'));

  const { mutateAsync: postOrganizationMutate, isPending: isPostOrganizationPending } = usePostOrganization();
  const [organizationData, setOrganizationData] = useRecoilState(organizationDataState);
  const { mutateAsync: postUserMutate, isPending: isPostUserPending } = usePostUserQuery();

  const isNoSearchResult = (organizationData && !Object.keys(organizationData).length) ?? false;
  const [isDialogueState, setIsDialogueState] = useState<keyof typeof ORGANIZATION_DIALOGUE_STATE>('completeProfile');

  const onSubmit = async (data: OrganizationFormType) => {
    let organizationId = '';
    const requestData = {
      name: data.name,
      address: data.address,
      corporateNumberType: data.corporateNumberType,
      corporateNumber: data.corporateNumber,
      businessPhone: data.businessPhone,
    };
    if (organizationData && Object.keys(organizationData).length > 0) {
      organizationId = organizationData.id ?? '';
    } else {
      try {
        await postOrganizationMutate(requestData, {
          onSuccess: (res) => {
            organizationId = res?.data?.id ?? '';
          },
        });
      } catch (e: any) {
        const errorData = await e.response.json();
        if (errorData.errorCode === 'CONNECT:ORGANIZATION_PHONE_NUMBER_ALREADY_EXISTS') {
          setError('businessPhone', {
            message: 'Organization already exists',
          });
          return;
        }
      }
    }
    await postUserMutate({ organizationId });
    removeDialog('completeProfileDialog');
    window.location.reload();
  };

  const onConfimButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (isNoSearchResult && isDialogueState === 'completeProfile') {
      setIsDialogueState('registerOrganization');
    } else {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    setOrganizationData(null);
  }, []);

  return (
    <Dialogue
      open={true}
      PaperProps={{
        sx: {
          width: '540px !important',
          minWidth: '540px',
        },
      }}
    >
      <DialogueTitle>{t(ORGANIZATION_DIALOGUE_STATE[isDialogueState].title)}</DialogueTitle>
      <DialogueContent sx={{ paddingTop: 0, maxHeight: 'calc(100vh - 200px)' }}>
        <FormProvider id={'completeProfileDialogue'} methods={methods}>
          <Stack direction="column" gap="20px">
            <List sx={{ ml: '24px', padding: 0 }}>
              {ORGANIZATION_DIALOGUE_STATE[isDialogueState].list.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: 'list-item',
                    listStyleType: 'disc',
                    p: 0,
                    wordBreak: 'break-word',
                  }}
                >
                  {t(item.value)}
                </ListItem>
              ))}
            </List>
            {isDialogueState === 'completeProfile' && (
              <CompleteProfileContent onBlur={onBlur} originalPhoneNumber={originalPhoneNumber} />
            )}
            {isDialogueState === 'registerOrganization' && <RegisterOrganizationContent onBlur={onBlur} />}
          </Stack>
        </FormProvider>
      </DialogueContent>
      <DialogueActions>
        <Button
          type="submit"
          size="36"
          disabled={
            !isValid ||
            (watch('businessPhone') !== originalPhoneNumber.current && isDialogueState === 'completeProfile')
          }
          loading={isPostOrganizationPending || isPostUserPending}
          onClick={onConfimButtonClick}
          sx={{ minWidth: '100px' }}
        >
          {isNoSearchResult && isDialogueState === 'completeProfile'
            ? t('search_organization_dialog.btn_register_new_organization')
            : t('search_organization_dialog.btn_confirm')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};

const CompleteProfileContent = ({
  onBlur,
  originalPhoneNumber,
}: OrganizationFieldProps & { originalPhoneNumber: MutableRefObject<string | null> }) => {
  const { t } = useTranslation();
  const [organizationData, setOrganizationData] = useRecoilState(organizationDataState);
  const isNoSearchResult = (organizationData && !Object.keys(organizationData).length) ?? false;
  const isFieldDisabled = !organizationData || (organizationData && Object.keys(organizationData).length > 0);
  return (
    <Stack>
      {/*Phone number*/}
      <SearchPhoneNumberField
        onBlur={onBlur}
        isNoSearchResult={isNoSearchResult}
        originalPhoneNumber={originalPhoneNumber}
      />
      {organizationData && Object.keys(organizationData).length > 0 && (
        <Stack direction="column" gap="40px">
          {/*Organization name*/}
          <OrganizationNameField sx={{ mt: '40px' }} disabled={isFieldDisabled} />
          {/*Organization address*/}
          <OrganizationAddressField onBlur={onBlur} disabled={isFieldDisabled} />
        </Stack>
      )}
    </Stack>
  );
};

const RegisterOrganizationContent = ({ onBlur }: OrganizationFieldProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const currentCorporateNumberType = watch('corporateNumberType') as corporateNumberType;
  return (
    <>
      <Stack direction="column" gap="60px">
        {/*Phone number*/}
        <OrganizationPhoneNumberField onBlur={onBlur} />
        {/*Organization type*/}
        <Stack direction="column" gap="20px">
          <Typography variant="H16">{t('organization.organization_type')}</Typography>
          <RHFSelect
            label=""
            label_visible={'none'}
            name="corporateNumberType"
            selectMenuItemList={ORGANIZATION_TYPE_LIST}
          />
        </Stack>
        {/*Organization code*/}
        <OrganizationOrganizationCodeField onBlur={onBlur} codeType={currentCorporateNumberType} />
        {/*Organization name*/}
        <OrganizationNameField onBlur={onBlur} />
        {/*Organization address*/}
        <OrganizationAddressField onBlur={onBlur} />
      </Stack>
    </>
  );
};
