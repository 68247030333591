import React, { useMemo, useState } from 'react';
import { IconButton, Label, Ricon, Tooltip } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useUserToothNumbering } from 'shared/hooks/useUserToothNumbering';
import { useVisibleToothLabels } from 'shared/hooks/useVisibleToothLabels';

import { OrderItemResponse } from '../../../client';

export const ToothInfoLabel = ({ orderItems }: { orderItems: OrderItemResponse[] }) => {
  const MAX_LABEL_WIDTH = 330;
  const LABEL_GAP = 6;

  const { getGroupedProsthesisLabels } = useUserToothNumbering();
  const teethLabelsByProthesis = useMemo(() => getGroupedProsthesisLabels(orderItems), [orderItems]);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen((prev) => !prev);
  };

  const closeTooltip = () => {
    setTooltipOpen(false);
  };

  const { isActiveViewMoreButton, visibleLabels, labelRefs, joinedLabels } = useVisibleToothLabels(
    teethLabelsByProthesis,
    MAX_LABEL_WIDTH,
    LABEL_GAP,
  );

  const onViewMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleTooltip();
  };

  return (
    <Stack sx={{ flexDirection: 'row', gap: '8px', position: 'relative' }}>
      <CalculatingLabels labels={teethLabelsByProthesis} labelRefs={labelRefs} />
      <VisibleLabels labels={visibleLabels} isSingleLabel={visibleLabels.length === 1} />
      {/* Viwe More Button*/}
      {isActiveViewMoreButton && (
        <Tooltip
          title={joinedLabels}
          placement={'bottom'}
          open={tooltipOpen}
          onClose={closeTooltip}
          sx={{ zIndex: 9 }}
          disableHoverListener
        >
          <IconButton
            size="28"
            color="grey900"
            icon_size="small"
            variant="transparent"
            hover_color="black"
            onClick={onViewMoreButtonClick}
          >
            <Ricon icon={'ri-more-fill'} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

const CalculatingLabels = ({
  labels,
  labelRefs,
}: {
  labels: string[];
  labelRefs: React.MutableRefObject<(HTMLSpanElement | null)[]>;
}) => (
  <Stack sx={{ visibility: 'hidden', position: 'absolute' }}>
    {labels.map((label, index) => (
      <Label
        key={label}
        sx={{ minWidth: 'fit-content' }}
        ref={(el) => {
          labelRefs.current[index] = el;
        }}
      >
        {label}
      </Label>
    ))}
  </Stack>
);

const VisibleLabels = ({ labels, isSingleLabel }: { labels: string[]; isSingleLabel: boolean }) => {
  const labelStyle = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 294,
  };

  return (
    <>
      {labels.map((label) => (
        <Label
          variant="outlined"
          key={label}
          sx={{
            ...(isSingleLabel && labelStyle),
            display: 'inline-block',
            width: 'fit-content',
            minWidth: 'fit-content',
            lineHeight: 'normal',
          }}
        >
          {label}
        </Label>
      ))}
    </>
  );
};
