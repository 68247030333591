import { Dialogue, DialogueContent, IconButton, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';

import { ConvertFileType, totalFilesResponseType } from './Databox';
import { SubViewer } from './Viewer/SubViewer';

interface PreviewDialogProps {
  open: boolean;
  handleClose: () => void;
  previewUrl: string | null;
  fileId: string;
  fileName: string;
  createdAt: string;
  is3DModel: boolean;
  totalFilesResponse: totalFilesResponseType;
  location: ConvertFileType['location'];
}

export const PreviewDialog: React.FC<PreviewDialogProps> = ({
  open,
  handleClose,
  previewUrl,
  is3DModel,
  location,
  totalFilesResponse,
  fileId,
  fileName,
  createdAt,
}) => {
  return (
    <Dialogue
      open={open}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { minWidth: '1120px', borderRadius: '12px', position: 'relative' } }}
    >
      <DialogueContent sx={{ p: 0 }}>
        <Stack
          sx={{
            width: '100%',
            height: '600px',
            backgroundColor: theme.palette.background.canvas,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {is3DModel ? (
            <SubViewer fileId={fileId} totalFilesResponse={totalFilesResponse} location={location} />
          ) : (
            <Box
              component="img"
              src={previewUrl || '/shippingDefaultImg.png'}
              alt="Large Preview"
              sx={{ width: '622px', height: '353px', objectFit: 'cover' }}
            />
          )}
        </Stack>
        <Stack sx={{ p: '20px', gap: '8px', height: '100px' }}>
          <Typography variant={'H24'}>{fileName}</Typography>
          <Typography variant={'Caption12'} color={theme.palette.text.thirdary}>
            {dayjs(createdAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </Stack>
      </DialogueContent>
      <IconButton
        size={'48'}
        color={'grey900'}
        variant={'transparent'}
        sx={{ position: 'absolute', top: '20px', right: '20px' }}
        onClick={handleClose}
      >
        <Ricon icon={'ri-close-line'} />
      </IconButton>
    </Dialogue>
  );
};
