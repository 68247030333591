import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Cicon,
  Divider,
  IconButton,
  Label,
  Link as ImagoLink,
  MenuItem,
  Popover,
  Ricon,
  theme,
  Tooltip,
  Typography,
} from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { ConnectLogo } from 'assets/index';
import { ENV_CONFIG } from 'constants/envConfig';
import { ROUTES } from 'constants/routes';
import { usePopover } from 'shared/hooks';
import { usePermission } from 'shared/hooks/usePermission';

import { postLogout } from '../../../api/auth';
import { useLanguageMutation, useUserQuery } from '../../../api/userQuery';
import { LANGUAGE_MENU_ITEM } from '../../constants/language';

export default function Header() {
  const { t } = useTranslation();
  const { role } = usePermission();
  const isPartnerLab = role?.includes('connect:partnerlab');

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        height: '44px',
        padding: '8px 20px 8px 16px',
        borderBottom: '1px solid',
        borderColor: 'grey.300',
        backgroundColor: 'bg.white',
      }}
    >
      {/* Left */}
      <Stack gap={'8px'} flexDirection={'row'}>
        <Link
          to={`${ROUTES.ORDER_ROUTER.root}/${isPartnerLab ? ROUTES.ORDER_ROUTER.receivedOrderList : ROUTES.ORDER_ROUTER.placedOrderList}`}
          style={{ all: 'unset', cursor: 'pointer' }}
        >
          <Stack direction="row" gap="8px" alignItems="center">
            <ConnectLogo />
            <Typography variant="Subtitle_Semibold18">Dentbird Connect</Typography>
          </Stack>
        </Link>
        {isPartnerLab && (
          <Label variant={'ghost'} color={'primary'}>
            {t('partner_lab_app_bar.label_partner_lab')}
          </Label>
        )}
      </Stack>
      {/* Right */}
      <Stack direction="row" gap="16px" alignItems="center">
        <Button size="30" color="grey" variant="outlined" href={process.env.REACT_APP_CLOUD_CLIENT_URL}>
          {t('app_bar.btn_go_to_dentbird_cloud')}
        </Button>
        <Stack direction="row" gap="4px">
          <Header.Button
            tooltipTitle={t('appbar_tool_tip.community')}
            url={ENV_CONFIG.CI_MEDICAL_COMMUNITY}
            icon={'ri-facebook-circle-line'}
          />
          <Header.Button
            tooltipTitle={t('appbar_tool_tip.help_center')}
            url={'https://dentbird.com'}
            icon={'ri-question-line'}
          />
          <Header.NotificationButton />
          <Header.LanguageButton />
          <Header.ProfileButton />
        </Stack>
      </Stack>
    </Stack>
  );
}

type HeaderButtonPropType = {
  tooltipTitle: string;
  url: string;
  icon: string;
};
const NavigateButton = ({ tooltipTitle, url, icon }: HeaderButtonPropType) => {
  const navigate = useNavigate();
  const isNavigate = url.startsWith('/');

  const handleIconClick = () => {
    if (isNavigate) {
      navigate(url);
    }
  };
  return (
    <Tooltip title={tooltipTitle}>
      {isNavigate ? (
        <IconButton
          size="28"
          color="grey900"
          icon_size="small"
          hover_color="grey"
          variant="transparent"
          onClick={handleIconClick}
        >
          <Ricon icon={icon} />
        </IconButton>
      ) : (
        <ImagoLink
          color="primary"
          href={url}
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
          data-cy="LinkIcon"
        >
          <IconButton size="28" variant="transparent" color="black">
            <Ricon icon={icon} />
          </IconButton>
        </ImagoLink>
      )}
    </Tooltip>
  );
};

const NotificationButton = () => {
  const { t } = useTranslation();
  const { anchorRef, handleAnchorRef, handleClose, open, id } = usePopover();

  const isNewNotificationExist = true;
  return (
    <>
      <Tooltip title={t('appbar_tool_tip.notification')}>
        <Box>
          <IconButton
            size="28"
            variant="transparent"
            color="black"
            onClick={handleAnchorRef}
            data-cy="notificationIcon"
          >
            <Cicon icon={isNewNotificationExist ? 'NotificationOn' : 'NotificationOff'} />
          </IconButton>
        </Box>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        keepMounted
        anchorEl={anchorRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        sx={{ zIndex: 10 }}
        PaperProps={{
          sx: {
            width: '360px',
            padding: '8px !important',
          },
        }}
        // slotProps={{
        //   paper: {
        //     sx: {
        //       width: '360px',
        //       padding: '8px !important',
        //     },
        //   },
        // }}
      >
        <div style={{ width: '100%', height: '600px' }}></div>
      </Popover>
    </>
  );
};

const ProfileButton = () => {
  const { t } = useTranslation();
  const { anchorRef, handleAnchorRef, handleClose, open, id } = usePopover();
  const { data: userData } = useUserQuery();

  if (!userData?.data) {
    return null;
  }

  return (
    <>
      <Tooltip title={t('appbar_tool_tip.account')}>
        <Box>
          <IconButton
            size="28"
            variant="transparent"
            color="black"
            onClick={handleAnchorRef}
            data-cy="notificationIcon"
          >
            <Ricon icon={'ri-user-3-line'} />
          </IconButton>
        </Box>
      </Tooltip>
      {userData.data && (
        <Popover
          id={id}
          open={open}
          keepMounted
          anchorEl={anchorRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClose}
          sx={{ zIndex: 10 }}
          PaperProps={{
            sx: {
              width: '360px',
              padding: '8px !important',
            },
          }}
          // slotProps={{
          //   paper: {
          //     sx: {
          //       width: '360px',
          //       padding: '8px !important',
          //     },
          //   },
          // }}
        >
          <Stack direction="column">
            <Stack direction="row" gap="16px" alignItems="center" sx={{ padding: '12px' }}>
              <Avatar src={undefined} size={48} data-cy="avatar" />
              <Stack direction="column" sx={{ maxWidth: '256px' }}>
                <Typography
                  variant="H18"
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >{`${userData.data?.name?.firstName} ${userData.data?.name?.lastName}`}</Typography>
                <Typography
                  variant="Body16"
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {userData.data?.email}
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                window.open(`${process.env.REACT_APP_CLOUD_CLIENT_URL as string}/myinfo/account`, '_blank');
              }}
            >
              {t('dialog_account.menu_my_info')}
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await postLogout();
                handleClose();
              }}
            >
              {t('dialog_account.menu_sign_out')}
            </MenuItem>
          </Stack>
        </Popover>
      )}
    </>
  );
};

const LanguageButton = () => {
  const { t, i18n } = useTranslation();
  const { mutateAsync } = useLanguageMutation();
  const { anchorRef, handleAnchorRef, handleClose, open, id } = usePopover();

  const { data, isLoading } = useUserQuery();
  const userData = data?.data;

  const handleLanguageChange = async (language: string) => {
    await mutateAsync(language);
    i18n.changeLanguage(language);
    handleClose();
  };

  return (
    <>
      <Tooltip title={t('appbar_tool_tip.language')}>
        <Box>
          <IconButton size="28" variant="transparent" color="black" onClick={handleAnchorRef}>
            <Ricon icon={'ri-global-line'} />
          </IconButton>
        </Box>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        keepMounted
        anchorEl={anchorRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        sx={{ zIndex: 10 }}
        PaperProps={{
          sx: {
            width: '160px !important',
            padding: '8px !important',
          },
        }}
      >
        <Stack>
          {LANGUAGE_MENU_ITEM.map(({ label, value }) => {
            const isDisabled = value !== 'ja' && value !== 'en';
            const isSelected = value === userData?.localeCode;
            return (
              <Tooltip
                title={value !== 'ja' && value !== 'en' ? t('tooltip.language_support') : ''}
                placement="left"
                disableHoverListener={value === 'ja' || value === 'en'}
                key={value}
              >
                <Box>
                  <MenuItem
                    selected={isSelected}
                    onClick={() => handleLanguageChange(value)}
                    disabled={isDisabled}
                    sx={{
                      backgroundColor: isDisabled && isSelected ? `${theme.palette.action.selected} !important` : '',
                    }}
                  >
                    {label}
                  </MenuItem>
                </Box>
              </Tooltip>
            );
          })}
        </Stack>
      </Popover>
    </>
  );
};

Header.Button = NavigateButton;
Header.NotificationButton = NotificationButton;
Header.ProfileButton = ProfileButton;
Header.LanguageButton = LanguageButton;
