import { useMutation, UseQueryResult } from '@tanstack/react-query';
import { FileMessageCreateParams } from '@sendbird/chat/message';
import { UploadFilesResponse } from 'client/types.gen';
import { BinaryFileObject } from 'pages/NewOrder/context/NewOrderDataProvider';

import { kyInstance } from './kyInstance';

const getFormData = ({ file, thumbnail }: BinaryFileObject) => {
  const formData = new FormData();
  formData.append('file', file);
  if (thumbnail) {
    formData.append('thumbnail', thumbnail);
  }
  return formData;
};

export const useUploadFile = () => {
  return useMutation({
    mutationFn: async ({ file, thumbnail }: BinaryFileObject): Promise<UploadFilesResponse> => {
      const body = getFormData({ file, thumbnail });
      return kyInstance
        .post('connect/files', {
          body,
        })
        .json();
    },
  });
};

export const useAddOtherFiles = () => {
  return useMutation({
    mutationFn: async ({
      orderId,
      fileInfos,
    }: {
      orderId: string;
      fileInfos: Array<{ fileId: string; createdAt: string }>;
    }) => {
      return kyInstance
        .patch(`connect/orders/${orderId}/files`, {
          json: { fileInfos: fileInfos },
        })
        .json();
    },
  });
};

export const useUploadOtherFile = (orderId: string, refetch?: () => Promise<UseQueryResult>) => {
  const { mutateAsync: uploadFile } = useUploadFile();
  const { mutateAsync: addOtherFiles } = useAddOtherFiles();

  return async (params: FileMessageCreateParams) => {
    const file = params.file;
    if (file) {
      const res = await uploadFile({ file: file as File });
      if (res.success && res.data?.id) {
        const { id } = res.data;
        const createdAt = new Date().toISOString();
        const fileInfos = [{ fileId: id, createdAt }];
        await addOtherFiles({ orderId, fileInfos });
        refetch && (await refetch());
      }
    }
    return params;
  };
};
