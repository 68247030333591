import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, DialogueActions, DialogueContent, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import {
  AsyncMaterialOptionList,
  AsyncMethodOptionList,
  AsyncShapeOptionList,
} from 'pages/NewOrder/components/Prescription/ProductOptionDialog/OptionLists';

type MethodMaterialSelectionPropType = {
  selectedProsthesis: string;
  onPrevious: () => void;
  onApplyClick: ({
    method,
    material,
    shape,
    productId,
    requireScanBodyAndImplantInfo,
  }: {
    method: string;
    material: string;
    shape: string;
    productId: string;
    requireScanBodyAndImplantInfo: boolean;
  }) => void;
};
function MethodMaterialSelection({
  selectedProsthesis,
  onPrevious,
  onApplyClick,
}: Readonly<MethodMaterialSelectionPropType>) {
  const { t } = useTranslation();

  const [selectedMethod, setSelectedMethod] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [selectedShape, setSelectedShape] = useState('');
  const [productId, setProductId] = useState('');
  const [requireScanBodyAndImplantInfo, setRequireScanBodyAndImplantInfo] = useState(false);

  return (
    <>
      <Stack
        flexDirection="row"
        sx={{ padding: '12px 24px', width: '100%', height: '52px', backgroundColor: theme.palette.grey[100] }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="Subtitle_Semibold16">
          <Trans i18nKey={'dialog_product_option.type'} values={{ variable: selectedProsthesis }} />
        </Typography>
        <Button onClick={onPrevious} size="20" font="semibold" variant="text2">
          {t('dialog_product_option.btn_change')}
        </Button>
      </Stack>

      <DialogueContent>
        <Stack gap="20px" sx={{ marginTop: '20px' }}>
          <Stack justifyContent="space-between" gap="40px">
            {/* Method */}
            <Stack>
              <Stack gap="8px">
                <Typography variant="H14" sx={{ color: theme.palette.text.thirdary }}>
                  {t('dialog_product_option.title_method')}
                </Typography>
                <AsyncMethodOptionList
                  selectedProsthesis={selectedProsthesis}
                  selectedValue={selectedMethod}
                  outerOnChange={(e) => setSelectedMethod(e.target.value)}
                />
              </Stack>
            </Stack>

            {/*  Material */}
            {selectedMethod && (
              <Stack>
                <Stack gap="8px">
                  <Typography variant="H14" sx={{ color: theme.palette.text.thirdary }}>
                    {t('dialog_product_option.title_material')}
                  </Typography>
                  <AsyncMaterialOptionList
                    selectedProsthesis={selectedProsthesis}
                    selectedMethod={selectedMethod}
                    selectedValue={selectedMaterial}
                    outerOnChange={(e) => setSelectedMaterial(e.target.value)}
                  />
                </Stack>
              </Stack>
            )}

            {selectedMaterial && (
              <Stack>
                <Stack gap="8px">
                  <Typography variant="H14" sx={{ color: theme.palette.text.thirdary }}>
                    {t('dialog_product_option.title_shape')}
                  </Typography>
                  <AsyncShapeOptionList
                    selectedValue={selectedShape}
                    selectedProsthesis={selectedProsthesis}
                    selectedMethod={selectedMethod}
                    selectedMaterial={selectedMaterial}
                    outerOnChange={(e) => setSelectedShape(e.target.value)}
                    innerOnChange={(item) => {
                      setProductId(item.productId);
                      setRequireScanBodyAndImplantInfo(item.requireScanBodyAndImplantInfo);
                    }}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </DialogueContent>

      <DialogueActions>
        <Button
          onClick={() => {
            onApplyClick({
              method: selectedMethod,
              material: selectedMaterial,
              shape: selectedShape,
              productId,
              requireScanBodyAndImplantInfo,
            });
            setSelectedMethod('');
            setSelectedMaterial('');
            setSelectedShape('');
            setProductId('');
            setRequireScanBodyAndImplantInfo(false);
          }}
          disabled={!selectedMethod || !selectedMaterial || !selectedShape}
          size="48"
          sx={{ width: '100%' }}
        >
          {t('dialog_product_option.btn_apply')}
        </Button>
      </DialogueActions>
    </>
  );
}

export default MethodMaterialSelection;
