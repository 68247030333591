import React, { MutableRefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton, Loading, Ricon, Typography } from '@imago-cloud/design-system';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { InputProps, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { organizationDataState } from 'shared/atoms/organization/atoms';
import { handleNumberFieldInputValidate } from 'shared/utils/inputHandler';

import { useGetOrganizationUsingPhoneNumber } from '../../../api/organization';
import { RHFTextField } from '../RHF';

type CorporateNumberFieldProps = {
  onBlur: InputProps['onBlur'];
  isNoSearchResult: boolean;
  originalPhoneNumber: MutableRefObject<string | null>;
};

export const SearchPhoneNumberField = ({
  onBlur,
  isNoSearchResult,
  originalPhoneNumber,
}: CorporateNumberFieldProps) => {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useGetOrganizationUsingPhoneNumber();

  const [organizationData, setOrganizationData] = useRecoilState(organizationDataState);
  const { watch, reset, setValue } = useFormContext();
  const handlePhoneNumberSearch = async () => {
    const phoneNumber = watch('businessPhone');
    if (!phoneNumber) return;

    try {
      originalPhoneNumber.current = phoneNumber;
      const organizationSearchResponse = await mutateAsync(phoneNumber);
      const searchedOrganization = organizationSearchResponse.data;
      if (searchedOrganization) {
        setOrganizationData(searchedOrganization);
        reset({
          name: searchedOrganization.name,
          address: searchedOrganization.address,
          businessPhone: watch('businessPhone'),
        });
      }
    } catch (e: any) {
      const errorData = await e.response.json();
      if (errorData.errorCode === 'CONNECT:ORGANIZATION_NOT_FOUND') {
        setOrganizationData({});
        reset({
          businessPhone: watch('businessPhone'),
          corporateNumberType: 'DENTAL_CLINIC',
          address: {
            country: watch('address.country'),
          },
        });
      }
    }
  };

  return (
    <Stack direction="column">
      <Stack gap="20px">
        <Typography variant="H16">{t('search_organization_dialog.phone_number')}</Typography>
        <RHFTextField
          name="businessPhone"
          label={t('search_organization_dialog.enter_organization_phone_number') as string}
          label_visible="none"
          helperText={t('search_organization_dialog.helper_text') as string}
          inputProps={{
            maxLength: 28,
            onInput: handleNumberFieldInputValidate,
          }}
          rules={{ required: t('organization_name.please_enter_organization_phone_number') }}
          InputProps={{
            endAdornment: isPending ? (
              <IconButton size="24" variant="transparent" data-cy="searchLoading">
                <Loading type="basic" size="14" />
              </IconButton>
            ) : (
              <IconButton size="36" variant="transparent" data-cy="submitSearch" onClick={handlePhoneNumberSearch}>
                <Ricon
                  icon="ri-search-line"
                  svgProps={{
                    fill: grey[800],
                    width: 24,
                    height: 24,
                  }}
                />
              </IconButton>
            ),
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handlePhoneNumberSearch();
            }
          }}
        />
      </Stack>
      {isNoSearchResult && (
        <Stack
          justifyContent="center"
          alignItems="center"
          gap="4px"
          sx={{
            padding: '32px 16px',
            marginTop: '20px',
            backgroundColor: 'grey.100',
            borderRadius: '8px',
          }}
        >
          <Ricon
            icon="ri-close-circle-fill"
            svgProps={{
              width: 32,
              height: 32,
              fill: grey[800],
            }}
          />
          <Stack justifyContent="space-between" alignItems="center">
            <Typography variant="Subtitle_Semibold14" color="text.secondary">
              {t('search_organization_dialog.no_search_result')}
            </Typography>
            <Typography variant="Body14" color="text.secondary">
              {t('search_organization_dialog.register_new_organization')}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
