export const BRIDGE_DATA_SET = [
  { id: '1-2', cx: '6', cy: '281', r: '5.5' },
  { id: '2-3', cx: '7', cy: '226', r: '5.5' },
  { id: '3-4', cx: '19', cy: '174', r: '5.5' },
  { id: '4-5', cx: '38', cy: '122', r: '5.5' },
  { id: '5-6', cx: '71', cy: '80', r: '5.5' },
  { id: '8-9', cx: '194', cy: '6', r: '5.5' },
  { id: '6-7', cx: '95', cy: '50', r: '5.5' },
  { id: '7-8', cx: '135', cy: '23', r: '5.5' },
  { id: '9-10', cx: '257', cy: '23', r: '5.5' },
  { id: '10-11', cx: '296', cy: '54', r: '5.5' },
  { id: '11-12', cx: '319', cy: '80', r: '5.5' },
  { id: '12-13', cx: '345', cy: '125', r: '5.5' },
  { id: '13-14', cx: '367', cy: '174', r: '5.5' },
  { id: '14-15', cx: '377', cy: '227', r: '5.5' },
  { id: '15-16', cx: '379', cy: '284', r: '5.5' },
  { id: '35-36', cx: '378', cy: '404', r: '5.5' },
  { id: '34-35', cx: '378', cy: '461', r: '5.5' },
  { id: '33-34', cx: '357', cy: '519', r: '5.5' },
  { id: '32-33', cx: '329', cy: '567', r: '5.5' },
  { id: '31-32', cx: '298', cy: '606', r: '5.5' },
  { id: '30-31', cx: '265', cy: '633', r: '5.5' },
  { id: '29-30', cx: '231', cy: '647', r: '5.5' },
  { id: '28-29', cx: '194', cy: '653', r: '5.5' },
  { id: '27-28', cx: '157', cy: '649', r: '5.5' },
  { id: '26-27', cx: '119', cy: '635', r: '5.5' },
  { id: '25-26', cx: '85', cy: '609', r: '5.5' },
  { id: '24-25', cx: '53', cy: '571', r: '5.5' },
  { id: '23-24', cx: '25', cy: '521', r: '5.5' },
  { id: '22-23', cx: '7', cy: '457', r: '5.5' },
  { id: '21-22', cx: '6', cy: '401', r: '5.5' },
];
