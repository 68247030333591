import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export default function useMediaResponsive(
  query: 'up' | 'down' | 'between' | 'only',
  key: Breakpoint,
  end?: Breakpoint,
) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key));
  const mediaDown = useMediaQuery(theme.breakpoints.down(key));
  const mediaBetween = useMediaQuery(theme.breakpoints.between(key, end ?? ('lg' as Breakpoint)));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(key));

  if (query === 'up') {
    return mediaUp;
  }
  if (query === 'down') {
    return mediaDown;
  }
  if (query === 'between') {
    return mediaBetween;
  }
  if (query === 'only') {
    return mediaOnly;
  }
  return null;
}
