import { useEffect, useState } from 'react';
import { IconButton, Loading, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { ImagePreview } from 'assets/index';
import { FILE_EXTENSIONS_3D, FILE_MIME_TYPES_IMG } from 'shared/constants/file';
import { getThumbnailBlob } from 'shared/hooks/useModelThumbnail';

type ThumbnailPropType = {
  file: File;
  cancelFile: (fileName: string) => void;
  storeThumbnail: (thumbnail: Blob) => void;
};

const LOADING_OBJ_URL = 'LOADING';
export default function Thumbnail({ file, cancelFile, storeThumbnail }: ThumbnailPropType) {
  const [objectUrl, setObjectUrl] = useState<string | null>(LOADING_OBJ_URL);
  const isLoading = objectUrl === LOADING_OBJ_URL;

  useEffect(() => {
    let url = '';
    const ext = file.name.split('.').pop();
    if (!ext) return; // TODO: Unsupported file format alert

    //3d file 인지 아닌지에 따라서 가공을 다르게 해야함.
    const getBlob = async (argFile: File) => await getThumbnailBlob(argFile);

    if (FILE_EXTENSIONS_3D.includes(ext)) {
      getBlob(file).then((blob) => {
        if (!blob) return;
        url = URL.createObjectURL(blob);
        setObjectUrl(url);
        storeThumbnail(blob);
      });
    } else {
      if (!FILE_MIME_TYPES_IMG.includes(file.type)) {
        return setObjectUrl(null);
      }
      url = URL.createObjectURL(file);
      setObjectUrl(url);
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [file]);

  return (
    <Stack alignItems="center" gap="8px" sx={{ maxWidth: '240px', width: '240px' }}>
      <Stack
        sx={{
          position: 'relative',
          width: '100%',
          height: '160px',
          border: `1px solid ${theme.palette.grey[500]}`,
          borderRadius: '8px',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{ position: 'absolute', top: 4, right: 4 }}>
          <IconButton
            onClick={() => cancelFile(file.name)}
            variant="transparent"
            size="28"
            color="grey800"
            hover_color="black"
            icon_size="small"
          >
            <Ricon icon={'ri-close-fill'} />
          </IconButton>
        </Box>
        {isLoading && <Loading size="24" type="basic" />}
        {!isLoading && objectUrl === null && <ImagePreview />}
        {!isLoading && objectUrl && (
          <img src={objectUrl} alt={'preview'} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        )}
      </Stack>

      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography
          sx={{
            width: '100%',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="Caption12"
        >
          {file.name}
        </Typography>
      </Box>
    </Stack>
  );
}
