import { CSSProperties, ElementType } from 'react';
import { Stack } from '@mui/material';
import { useGetOverflow } from 'shared/hooks/useGetOverFlow';

export const ScrollArea = ({
  sx,
  component,
  children,
}: {
  sx?: CSSProperties;
  component?: ElementType;
  children: React.ReactNode;
}) => {
  const { y, containerRef } = useGetOverflow();

  return (
    <Stack sx={{ ...sx }} ref={containerRef}>
      <Stack sx={{ width: `calc(100% + ${y ? '14px' : '0px'})` }} component={component || 'div'}>
        {children}
      </Stack>
    </Stack>
  );
};
