import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton, Ricon } from '@imago-cloud/design-system';
import { alpha, Stack, useTheme } from '@mui/material';
import { ROUTES } from 'constants/routes';

export default function CostInfoButton({ isCollapse }: Readonly<{ isCollapse: boolean }>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isActive = matchPath({ path: ROUTES.COST_INFO_ROUTER.root, end: true }, pathname);

  return (
    <>
      {isCollapse ? (
        <IconButton
          variant="outlined"
          size="48"
          sx={{
            margin: '0 auto',
            backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.1) : theme.palette.common.white,
            borderColor: theme.palette.primary.main,
          }}
        >
          <Ricon
            icon="ri-coins-fill"
            svgProps={{
              fill: isActive ? theme.palette.primary.main : '',
            }}
          />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          size="48"
          startIcon={
            <Ricon
              icon="ri-coins-fill"
              svgProps={{
                fill: theme.palette.primary.main,
              }}
            />
          }
          sx={{
            width: '100%',
            backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.1) : theme.palette.common.white,
          }}
          id="costInfoButton"
          data-cy="costInfoButton"
          disableFocusRipple
          onClick={() => navigate(ROUTES.COST_INFO_ROUTER.root)}
        >
          {!isCollapse && <Stack sx={{ whiteSpace: 'nowrap' }}>{t('user_side_snb.cost_information')}</Stack>}
        </Button>
      )}
    </>
  );
}
