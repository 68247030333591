import { useRef } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const usePrintRx = () => {
  const upperRef = useRef<HTMLDivElement>(null);
  const lowerRef = useRef<HTMLDivElement>(null);

  const handleGeneratePDF = async () => {
    const upperElement = upperRef.current;
    const lowerElement = lowerRef.current;

    if (!upperElement || !lowerElement) return;

    const dpi = 300; // 고해상도 PDF를 위한 DPI 설정
    const scale = dpi / 96; // 배율 조정
    const imgWidth = 210; // A4 너비 (mm)
    const pageHeight = 297; // A4 높이 (mm)

    const pdf = new jsPDF('p', 'mm', 'a4');
    const totalPages = 2; // 페이지 총 개수 (상단 + 하단)

    // 페이지 번호를 PDF에 추가하는 함수
    const addPageNumber = (pdfInstance: jsPDF, pageNumber: number) => {
      pdfInstance.setFontSize(8);
      pdfInstance.text(
        `Page ${pageNumber} of ${totalPages}`,
        pdfInstance.internal.pageSize.getWidth() / 2 - 5,
        pageHeight - 10,
      );
    };

    // 1. 상단 부분 캡처 및 PDF에 추가
    const upperCanvas = await html2canvas(upperElement, { scale, useCORS: true });
    const upperImgData = upperCanvas.toDataURL('image/jpeg', 0.8);
    const upperHeight = (upperCanvas.height * imgWidth) / upperCanvas.width;

    pdf.addImage(upperImgData, 'JPEG', 0, 0, imgWidth, upperHeight);
    addPageNumber(pdf, 1); // 첫 페이지 번호 추가

    // 2. 하단 부분 캡처 및 다음 페이지에 추가
    pdf.addPage(); // 새 페이지 추가
    const lowerCanvas = await html2canvas(lowerElement, { scale, useCORS: true });
    const lowerImgData = lowerCanvas.toDataURL('image/jpeg', 0.8);
    const lowerHeight = (lowerCanvas.height * imgWidth) / lowerCanvas.width;

    pdf.addImage(lowerImgData, 'JPEG', 0, 0, imgWidth, lowerHeight);
    addPageNumber(pdf, 2); // 두 번째 페이지 번호 추가

    // 3. PDF 미리보기 또는 다운로드
    const blobURL = pdf.output('bloburl');
    window.open(blobURL, '_blank');
  };

  return { upperRef, lowerRef, handleGeneratePDF };
};
