import { useTranslation } from 'react-i18next';
import { Chip, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

import { formattedPrice } from '../../utils/formatString';

export const ToothContentRow = ({
  title,
  label,
  additionalPrice,
  color = theme.palette.text.secondary,
  isSelected = false,
}: {
  title: string;
  label: string;
  additionalPrice?: number;
  color?: string;
  isSelected?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <Stack sx={{ width: '100%', gap: '4px', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant="Body14" sx={{ color, whiteSpace: 'noWrap' }}>
          {title}
        </Typography>
        <Chip
          variant={'outlined'}
          color={isSelected ? 'primary' : 'default'}
          label={label}
          size="small"
          sx={{
            maxWidth: 'fit-content',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            backgroundColor: 'white !important',
            color: `${color} !important`,
          }}
        />
      </Stack>
      {additionalPrice !== undefined && (
        <Typography variant="Subtitle_Semibold14" sx={{ color: 'text.thirdary', whiteSpace: 'noWrap' }}>
          {formattedPrice(additionalPrice ?? 0, t)}
        </Typography>
      )}
    </Stack>
  );
};
