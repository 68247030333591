import { useRecoilState } from 'recoil';

import { DEFAULT_TOP_ALERT_STATE, topAlertState } from './atoms';

const useTopAlert = () => {
  const [topAlert, setTopAlert] = useRecoilState(topAlertState);

  const initTopAlert = () => {
    setTopAlert(DEFAULT_TOP_ALERT_STATE);
  };
  const DEFAULT_TOP_ALERT_DURATION = 6_000;

  return {
    topAlert,
    setTopAlert,
    initTopAlert,
    DEFAULT_TOP_ALERT_DURATION,
  };
};

export default useTopAlert;
