import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettingQuery, useUserQuery } from '../api/userQuery';

const i18nLang = ['en', 'ja'];

export const I18nHoc = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();

  const { data: userData, isLoading } = useUserQuery();
  const userInfo = userData?.data;
  const { data: systemData } = useSettingQuery();
  const systemInfo = systemData.data;

  useEffect(() => {
    const lang = userInfo?.localeCode?.toLowerCase() || systemInfo?.language || 'ja';
    i18n.changeLanguage(i18nLang.includes(lang) ? lang : 'ja');
  }, [userInfo, systemInfo, i18n]);

  if (isLoading) return null;
  return <>{children}</>;
};
