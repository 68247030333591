import React, { Dispatch, SetStateAction, useState } from 'react';
import { Trans } from 'react-i18next';
import { Dialogue, DialogueTitle, Typography } from '@imago-cloud/design-system';
import { OrderItemRequest } from 'client/types.gen';
import MethodMaterialSelection from 'pages/NewOrder/components/Prescription/ProductOptionDialog/MethodMaterialSelection';
import ProthesisSelection from 'pages/NewOrder/components/Prescription/ProductOptionDialog/ProsthesisSelection';
import { CustomSelectorToFDI, FDIToPalmer } from 'shared/utils/convertToPalmer';

type productOptionDialogPropType = {
  open: boolean;
  onClose: () => void;
  productToAdd: OrderItemRequest;
  setProductToAdd: Dispatch<SetStateAction<OrderItemRequest>>;
  onApplyClick: ({
    method,
    material,
    shape,
    productId,
    requireScanBodyAndImplantInfo,
  }: {
    method: string;
    material: string;
    shape: string;
    productId: string;
    requireScanBodyAndImplantInfo: boolean;
  }) => void;
};

type ProductOptionDialogStep = 'ProthesisSelection' | 'MethodMaterialSelection';
export default function ProductOptionDialog({
  open,
  onClose,
  productToAdd,
  setProductToAdd,
  onApplyClick,
}: productOptionDialogPropType) {
  const [step, setStep] = useState<ProductOptionDialogStep>('ProthesisSelection');

  return (
    <Dialogue open={open}>
      <DialogueTitle
        isClose={true}
        onClose={() => {
          setStep('ProthesisSelection');
          onClose();
        }}
      >
        <Typography variant="H16">
          <Trans
            i18nKey={FDIToPalmer[CustomSelectorToFDI[productToAdd.toothNumber]]?.full}
            values={{ variable: FDIToPalmer[CustomSelectorToFDI[productToAdd.toothNumber]]?.num ?? '##' }}
          />
        </Typography>
      </DialogueTitle>

      {/* Step 1 */}
      {step === 'ProthesisSelection' && (
        <ProthesisSelection
          onProsthesisClick={(prosthesis: string) => {
            setStep('MethodMaterialSelection');
            setProductToAdd((prev) => ({
              ...prev,
              prosthesis,
            }));
          }}
        />
      )}

      {/* Step 2 */}
      {step === 'MethodMaterialSelection' && (
        <MethodMaterialSelection
          selectedProsthesis={productToAdd.prosthesis}
          onApplyClick={(data) => {
            setStep('ProthesisSelection');
            onApplyClick(data);
            onClose();
          }}
          onPrevious={() => setStep('ProthesisSelection')}
        />
      )}
    </Dialogue>
  );
}
