import React from 'react';
import { Loading } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const LoadingCenter = () => (
  <Stack
    sx={{
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Loading size="44" type="basic" />
  </Stack>
);
