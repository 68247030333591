import { useRecoilState } from 'recoil';

import { bottomSnackbarState, DEFAULT_BOTTOM_SNACKBAR_STATE } from './atoms';

const useBottomSnackbar = () => {
  const [bottomSnackbar, setBottomSnackbar] = useRecoilState(bottomSnackbarState);

  const initBottomSnackbar = () => {
    setBottomSnackbar(DEFAULT_BOTTOM_SNACKBAR_STATE);
  };

  const DEFAULT_BOTTOM_SNACKBAR_DURATION = 6_000;

  return {
    bottomSnackbar,
    setBottomSnackbar,
    initBottomSnackbar,
    DEFAULT_BOTTOM_SNACKBAR_DURATION,
  };
};

export default useBottomSnackbar;
