import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Cicon, theme, Typography } from '@imago-cloud/design-system';
import { alpha, Stack } from '@mui/material';
import { FILE_UPLOAD_LIMITATIONS } from 'pages/OrderDetail/constants/dialog';
import { FILE_MIME_TYPES_IMG } from 'shared/constants/file';
import { useFileUploadWithDnD } from 'shared/hooks/useFileUploadWithDnD';
import { validateFile } from 'shared/utils/file';
import useGlobalDialog from 'store/globalDialog/useGlobalDialog';

import { FileType } from './FileUploadWithPreview';

export const FILE_UPLOAD_GUIDELINES = {
  ORGANIZATION: 'dialog_upload_image.format',
  MANUFACTURING: 'patient_page_status.upload_your_shipping_invoice',
};
export const ImageFileUpload = ({
  setFile,
  description,
  isDisabled,
}: {
  setFile: React.Dispatch<React.SetStateAction<FileType>>;
  description: string;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();

  const MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024; // 10mb
  const { addDialog, removeCurrent } = useGlobalDialog();

  const { isDragOver, handleDragOver, handleDragLeave, handleDrop, handleFileChange } = useFileUploadWithDnD({
    multiple: false,
    callback: (files) => {
      if (files && files.length > 0) {
        const file = files[0];

        if (!validateFile(file, MAX_FILE_SIZE_BYTES, FILE_MIME_TYPES_IMG)) {
          addDialog(FILE_UPLOAD_LIMITATIONS(removeCurrent));
          setFile(null);
          return;
        }

        setFile(file);
      } else {
        setFile(null);
      }
    },
  });

  return (
    <Stack
      sx={{
        width: '100%',
        // height: '248px',
        py: '24px',
        borderRadius: '8px',
        border: isDragOver ? `4px dashed ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey['200']}`,
        backgroundColor: isDragOver ? alpha(theme.palette.primary.main, 0.08) : theme.palette.background.canvas,
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Stack sx={{ height: '100px', position: 'relative', justifyContent: 'center', alignItems: 'center', mb: '16px' }}>
        <Stack
          sx={{
            position: 'absolute',
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            backgroundColor: alpha('#FFFFFF', 0.48),
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              backgroundColor: '#FFFFFF',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Cicon icon={'UploadArrow'} />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ alignItems: 'center' }}>
        <Typography variant={'H14'}>{t('dialog_upload_image.subtitle_drag_and_drop')}</Typography>
        <Typography variant={'Caption12'} color={theme.palette.text.thirdary} sx={{ mb: '8px' }}>
          {t(description)}
        </Typography>
        <Button
          component="label"
          size={'36'}
          color={'grey'}
          shadow={true}
          variant={'outlined'}
          hover_color={'grey'}
          disabled={isDisabled}
        >
          {t('dialog_upload_image.btn_browse')}
          <input type="file" hidden onChange={handleFileChange} accept=".jpeg, .jpg, .bmp, .png" />
        </Button>
      </Stack>
    </Stack>
  );
};
