import { atom } from 'recoil';

export interface IError {
  open: boolean;
  component: React.ComponentType<any> | null;
}
export const errorState = atom<IError>({
  key: 'errorState',
  default: {
    open: false,
    component: null,
  },
});
