import { useRecoilState } from 'recoil';
import { userRoleState } from 'shared/atoms/role/atom';

import { ROLE } from '../../constants/role';
import { PermissionItem } from '../types/role';

export const usePermission = () => {
  const [userRoleConfig, setUserRoleConfig] = useRecoilState(userRoleState);
  const role = userRoleConfig.role;

  const accessibleClientPaths = userRoleConfig.permissions.map(({ clientPath }) => clientPath).filter((path) => path);
  const accessiblePermissions = userRoleConfig.permissions.map((snb) => snb?.permissionId);

  const setValidatedUserRoleConfig = (config: { role: string | undefined; permissions: PermissionItem[] }) => {
    if (config.role === undefined || !Object.values(ROLE).includes(config.role)) {
      window.location.replace(process.env.REACT_APP_CLOUD_CLIENT_URL as string);
      return;
    }
    setUserRoleConfig({
      role: config.role as keyof typeof ROLE,
      permissions: config.permissions,
    });
  };

  return { role, userRoleConfig, setValidatedUserRoleConfig, accessibleClientPaths, accessiblePermissions };
};
