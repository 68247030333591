import { Stack } from '@mui/material';
import { OrderDetailResponse } from 'client/types.gen';
import {
  AdditionalOptions,
  OrderInformation,
  OrderNote,
  OrderPrice,
  ShippingInformation,
} from 'pages/OrderDetail/components/Rx';
import { ToothCards } from 'shared/ui/toothProductDetail/ToothCards';

export const RxContent = ({ orderDetail }: { orderDetail: OrderDetailResponse }) => {
  const {
    designManufactureCombination,
    note,
    deliveryInstruction,
    orderItems,
    receiver,
    shippingAddress,
    shippingFee,
    printing3DModelPrice,
    billingAmount,
    orderAmount,
    patientPrintingFilesResponse,
  } = orderDetail;

  return (
    <Stack sx={{ gap: '16px' }}>
      <OrderInformation orderDetail={orderDetail} />
      <OrderNote note={note} />
      <ToothCards orderItems={orderItems} />
      <AdditionalOptions printing3DModelLengths={patientPrintingFilesResponse.length} price={printing3DModelPrice} />
      <OrderPrice
        shippingFee={shippingFee}
        printing3DModelPrice={patientPrintingFilesResponse.length * printing3DModelPrice}
        billingAmount={billingAmount}
        orderAmount={orderAmount}
      />
      {designManufactureCombination !== 'DESIGN' && (
        <ShippingInformation
          receiver={receiver}
          shippingAddress={shippingAddress}
          deliveryInstruction={deliveryInstruction}
        />
      )}
    </Stack>
  );
};
