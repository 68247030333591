export const ENV_CONFIG = {
  MAIN_SERVER_URL: process.env.REACT_APP_MAIN_SERVER_URL,
  ACCOUNTS_CLIENT_URL: process.env.REACT_APP_ACCOUNTS_CLIENT_URL,
  CLOUD_CLIENT_URL: process.env.REACT_APP_CLOUD_CLIENT_URL,
  CI_MEDICAL_COMMUNITY: process.env.REACT_APP_CI_MEDICAL_COMMUNITY!,
  HELP_CENTER_URL: process.env.REACT_APP_HELP_CENTER_URL,
};
const validateEnvConfig = () => {
  const invalidConfig = Object.entries(ENV_CONFIG)
    .filter(([key, value]) => !value)
    .map(([key]) => key);

  if (invalidConfig.length > 0) {
    throw new Error(`Env Config is not defined: ${invalidConfig.join(', ')}`);
  }
};

validateEnvConfig();
