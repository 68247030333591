import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MeshRenderingObject from '@imago/iwtk.js/dist/data/MeshRenderingObject';
import { Button, Cicon, IconButton, ModelSlider, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useVTKContext } from 'shared/hooks/useVTKContext';
import { IMeshItem } from 'shared/types/viewer';

import { EllipsisLabel } from './EllipsisLabel';

const ModelListButton = ({ isExpand, onClick }: { isExpand: boolean; onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <Button
      size="36"
      color="grey"
      shadow={true}
      hover_color="black"
      variant="outlined"
      startIcon={<Cicon icon="TreeIconsShow" />}
      sx={{
        ':hover': {
          backgroundColor: theme.palette.action.hover,
        },
        ...(isExpand && {
          display: 'none',
        }),
      }}
      onClick={onClick}
    >
      {t('model_list.body')}
    </Button>
  );
};

interface ISlider extends IMeshItem {
  onOpacityChange: (renderingObject: MeshRenderingObject, value: number) => void;
}
const Slider = ({ name, renderingData, onOpacityChange }: ISlider) => {
  const [isVisible, setIsVisible] = useState(true);
  const [opacity, setOpacity] = useState(1);

  const onUpdateOpacity = (opacity: number) => {
    if (renderingData?.renderingObject) {
      onOpacityChange(renderingData?.renderingObject, opacity);
      setOpacity(opacity);
    }
  };

  const handleVisibilityButtonClick = () => {
    setIsVisible((prev) => {
      const newValue = !prev;
      onUpdateOpacity(newValue === true ? 1 : 0);
      return newValue;
    });
  };
  const handleSliderChange = (_: Event, value: number | number[]) => {
    onUpdateOpacity(value as number);
  };

  return (
    <ModelSlider
      variant="narrow"
      sx={{ width: '100%' }}
      value={opacity}
      label={<EllipsisLabel label={name} />}
      onChange={handleSliderChange}
      component={
        <Stack onClick={handleVisibilityButtonClick}>
          {isVisible ? <Cicon icon="TreeIconsShow" /> : <Cicon icon="TreeIconsHide" />}
        </Stack>
      }
    />
  );
};

export const ModelList = () => {
  const { t } = useTranslation();

  const { renderWindows, renderingDataLists } = useVTKContext();
  const [isExpand, setIsExpand] = useState(false);
  const handleModelListButtonClick = () => {
    setIsExpand((prev) => !prev);
  };

  const handleOpacityChange = (renderingObject: MeshRenderingObject, opacity: number) => {
    renderingObject.setOpacity(opacity);
    renderingObject?.update();
    if (!renderWindows.main) return;
    renderWindows.main.getRenderWindow().render();
  };
  return (
    <Stack sx={{ position: 'absolute', top: '76px', left: '20px' }}>
      <ModelListButton isExpand={isExpand} onClick={handleModelListButtonClick} />
      <Stack
        sx={{
          visibility: isExpand ? 'visible' : 'hidden',
          width: '245px',
          backgroundColor: theme.palette.common.white,
          boxShadow: theme.shadows['1'],
          border: '1px solid',
          borderColor: theme.palette.grey[500],
          borderRadius: '10px',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            padding: '12px 16px 12px 16px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="H14">{t('model_list.body')}</Typography>
          <IconButton size="18" color="grey900" variant="transparent2" onClick={handleModelListButtonClick}>
            <Ricon icon="ri-close-fill" />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            padding: '0px 16px 12px 16px',
            maxHeight: '300px',
            gap: '2px',
          }}
        >
          {renderingDataLists['main']?.map((item) => {
            const { id } = item || {};
            return <Slider onOpacityChange={handleOpacityChange} {...item} key={id} />;
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
