import { useLocation } from 'react-router-dom';
import { theme } from '@imago-cloud/design-system';
import { Box } from '@mui/material';
import { BRIDGE_STYLE, BRIDGE_STYLE_KEY } from 'pages/NewOrder/constants/BridgeSelectedStyle';
import { BRIDGE_DATA_SET } from 'pages/NewOrder/constants/BridgeSelecter.constant';

import { useProsthesisByOrderType } from '../../../../api/productQuery';
import { ColorPair } from '../../../../client';
import { PALMER_DATA_SET } from '../../constants/ToothSelectorPalmer.constant';
import useNewOrderDataContext from '../../context/useNewOrderDataContext';

export type SelectedToothListType = Array<{
  prosthesis: string;
  material: string;
  toothNumber: number;
  groupId?: string | null;
}>;

type PalmerPropType = {
  selectedToothList: SelectedToothListType; // toothNumber[]
  onToothClick: (clickedToothNumber: number) => void;
  onBridgeClick?: (clickedBridgeNumberPair: string) => void;
  selectedBridgeNumberPairList: Array<string>;
  style?: Record<string, string>;
};

export default function Palmer({
  selectedToothList,
  onToothClick,
  onBridgeClick,
  selectedBridgeNumberPairList,
}: Readonly<PalmerPropType>) {
  const { pathname } = useLocation();
  const isViewOnly = pathname.includes('remake');

  const {
    orderData: { designManufactureCombination },
  } = useNewOrderDataContext();
  const {
    data: { data: prosthesisColorList },
  } = useProsthesisByOrderType(designManufactureCombination!);

  const availableBridgeList = selectedToothList
    .toSorted((a, b) => a.toothNumber - b.toothNumber)
    .reduce<SelectedToothListType[]>((acc, item) => {
      const lastGroup = acc[acc.length - 1];
      if (
        lastGroup &&
        lastGroup[lastGroup.length - 1].toothNumber === item.toothNumber - 1 &&
        lastGroup[0].material === item.material
      ) {
        lastGroup.push(item);
      } else {
        acc.push([item]);
      }
      return acc;
    }, [])
    .filter((group) => {
      // console.log(group);
      return group.length > 1;
    }) // [[{... toothNumber: 11}, {..., toothNumber: 12} ]]
    .flatMap((group) => {
      // console.log('group: ', group);
      const pairs: Array<string> = []; // TODO: Set() 으로..?
      // console.log('PAIRS: ', pairs);

      for (let i = 0; i < group.length; i++) {
        if (group[i] === group[group.length - 1])
          continue; // 마지막 요소 진행 x
        else if (
          group[i].toothNumber + 1 === group[i + 1].toothNumber &&
          (group[i].groupId === undefined ||
            group[i + 1].groupId === undefined || //
            group[i].groupId !== group[i + 1].groupId) // 이미 선택되어 있는 브릿지 두개에서 가운데를 이으려고 할 때, groupId 있더라도 같은 groupId 가 아니면 available 상태가 되어야 한다.
        ) {
          pairs.push(`${group[i].toothNumber}-${group[i + 1].toothNumber}`);
        }
      }
      return pairs;
    });

  // id = svg ui.id
  const getToothStyle = (id: number) => {
    const selectedProsthesis = selectedToothList.find((selected) => selected.toothNumber === id)?.prosthesis;
    const selectedProsthesisObject = prosthesisColorList.find(
      (prosthesisObject) => prosthesisObject.value === selectedProsthesis,
    );
    if (!selectedProsthesisObject) return DEFAULT;
    return getProsthesisColorStyle(selectedProsthesisObject.colorCode);
  };

  const getBridgeStyle = (id: string): BRIDGE_STYLE_KEY => {
    if (selectedBridgeNumberPairList.includes(id)) return 'SELECTED';
    if (availableBridgeList.includes(id)) return 'AVAILABLE';
    return 'DEFAULT';
  };

  return (
    <Box component={'svg'} sx={{ width: '100%', height: '100%' }}>
      {/* Permanent 경우 */}
      {BRIDGE_DATA_SET.map(({ id, cx, cy, r }) => {
        return (
          <Box
            key={id}
            component="circle"
            onClick={() => {
              if (isViewOnly) return;
              onBridgeClick && onBridgeClick(id);
            }}
            sx={{ ...BRIDGE_STYLE[getBridgeStyle(id as BRIDGE_STYLE_KEY)] }}
            cx={cx}
            cy={cy}
            r={r}
          />
        );
      })}
      {PALMER_DATA_SET.map((el) => (
        <Box
          key={el.id}
          component={'g'}
          onClick={() => {
            if (isViewOnly) return;
            onToothClick && onToothClick(el.id);
          }}
          sx={{
            ...getToothStyle(el.id),
            // Remake
            ...(isViewOnly ? VIEW_ONLY : {}),
          }}
        >
          <path className="tooth" d={el.tooth.d} stroke={theme.palette.grey[500]} fill={theme.palette.common.white} />
          <path className="text" d={el.text.d} fill={theme.palette.text.secondary} />
        </Box>
      ))}
    </Box>
  );
}

const DEFAULT = {
  '&:hover': {
    '& > .tooth': {
      fill: theme.palette.blue[10],
      stroke: theme.palette.primary.main,
    },
    cursor: 'pointer',
    '& > .text': {
      fill: theme.palette.primary.main,
    },
  },
};

const VIEW_ONLY = {
  '&:hover': {
    cursor: 'default',
  },
};

const getProsthesisColorStyle = (color: ColorPair) => ({
  '& > .tooth': {
    fill: color.fill,
    stroke: color.stroke,
  },
  cursor: 'pointer',
  '& > .text': {
    fill: color.stroke,
  },
  '& > .square': {
    // fill: theme.palette.grey['700'],
    fill: color.fill,
  },
});
