import { Collapse, List } from '@mui/material';
import { IMenuItem, IMenuItemChildren } from 'constants/menuItem';
import { useNavList } from 'shared/hooks';
import { usePermission } from 'shared/hooks/usePermission';

import NavItem from './NavItem';
import NavListSub from './NavListSub';

interface NavListRootProps {
  item: IMenuItem;
  isCollapse: boolean;
}

export default function NavListRoot({ item, isCollapse }: Readonly<NavListRootProps>) {
  const { active, open, setOpen, hasChildren } = useNavList(item);
  const { accessibleClientPaths, userRoleConfig } = usePermission();
  const { role, permissions } = userRoleConfig;
  const isNotConnectUser = !role && permissions.length === 0;

  const accessibleItem: IMenuItem = {
    ...item,
    children: item.children?.filter((child) => accessibleClientPaths.includes(child.path)),
  };
  if (hasChildren) {
    return (
      <>
        <NavItem
          item={accessibleItem}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen((prev) => !prev)}
        />
        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(accessibleItem.children ?? []).map((child: IMenuItemChildren) => {
                if (isNotConnectUser) {
                  return <NavListSub key={child.title} item={child} />;
                } else {
                  return <NavListSub key={child.title} item={child} />;
                }
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItem item={item} active={active} isCollapse={isCollapse} />;
}
