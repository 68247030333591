import { useState } from 'react';
export default function useDrawer(isRailMode: boolean) {
  const [collapse, setCollapse] = useState(true);

  const handleHoverEnter = () => {
    setCollapse(false);
  };

  const handleHoverLeave = () => {
    setCollapse(true);
  };

  return {
    isCollapse: collapse && isRailMode,
    onHoverEnter: handleHoverEnter,
    onHoverLeave: handleHoverLeave,
  };
}
