import { FieldPath, useForm } from 'react-hook-form';

import { useUserQuery } from '../../api/userQuery';
import { OrganizationCreateRequest } from '../../client';

export type OrganizationFormType = OrganizationCreateRequest;
const useOrganizationHookFormHandling = () => {
  const { data } = useUserQuery();
  const organizationInfo = data?.data?.organization;

  const methods = useForm<OrganizationFormType>({
    mode: 'onSubmit',
    defaultValues: {
      name: organizationInfo?.name ?? '',
      address: {
        country: organizationInfo?.address?.country ?? 'Japan',
        fullAddress: organizationInfo?.address?.fullAddress ?? '',
        detailAddress: organizationInfo?.address?.detailAddress ?? '',
        zipCode: organizationInfo?.address?.zipCode ?? '',
      },
      corporateNumberType: organizationInfo?.corporateNumberType ?? 'DENTAL_CLINIC',
      corporateNumber: organizationInfo?.corporateNumber ?? '',
      businessPhone: organizationInfo?.businessPhone?.number ?? '',
      certificateOfIncorporationFileId: organizationInfo?.certificateOfIncorporation?.id ?? '',
    },
  });

  const {
    watch,
    setValue,
    trigger,
    formState: { dirtyFields },
  } = methods;

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.target.name as FieldPath<OrganizationFormType>;
    const value = watch(name);
    if (typeof value === 'string') {
      setValue(name, value.trim());
    }
    trigger(name);
  };

  const commonTextFieldValidateProps = {
    onBlur,
    inputProps: {
      maxLength: 128,
    },
  };

  // const changedObject = Object.keys(dirtyFields).reduce<Partial<OrganizationFormType>>((acc, cur) => {
  //   const key = cur as keyof typeof ProfileDefaultValue;
  //   return { ...acc, [key]: watch(key) };
  // }, {});

  // useEffect(() => {
  //   refetch().then((_res) => {
  //     if (hasBillingInfo && billingInfo) {
  //       removeUnnecessaryField(billingInfo);
  //       reset(billingInfo);
  //     }
  //   });
  // }, [isLoading]);

  return { methods, onBlur, commonTextFieldValidateProps };
};

export default useOrganizationHookFormHandling;
