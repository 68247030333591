import React from 'react';
import { Loading } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

import { ErrorPage } from '../Error/ErrorPage';
import { useAuthHoc } from './useAuthHoc';

const AuthHoc = ({ children }: { children: React.ReactNode }) => {
  const { isErrorPage, isAuthLoading } = useAuthHoc();

  if (isAuthLoading) {
    return <FallbackLoading />;
  }
  if (isErrorPage) {
    return <ErrorPage />;
  }
  return <>{children}</>;
};
export default AuthHoc;

export const FallbackLoading = () => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: '100vw', height: '100vh', zIndex: 999999999 }}>
      <Loading size="44" type="basic" />
    </Stack>
  );
};
