import React, { ComponentType, lazy } from 'react';
import withAsyncBoundary from 'hoc/withAsyncBoundary';
import { LoadingCenter } from 'shared/ui/loading/LoadingCenter';

import { PaymentHistory, RefundHistory } from '../../pages';
import { ErrorPage } from '../../pages/Error/ErrorPage';

const OrderDetail = lazy(() => import('pages/OrderDetail/OrderDetail'));
const CostInfo = lazy(() => import('pages/CostInfo/CostInfo'));
const Coupon = lazy(() => import('pages/Coupon/Coupon'));
const Operation = lazy(() => import('pages/Operation/Operation'));
const Organization = lazy(() => import('pages/Organization/Organization'));
const Sales = lazy(() => import('pages/Sales/Sales'));

const pageAsyncRoute = (Component: ComponentType) =>
  withAsyncBoundary(Component, {
    pendingFallback: <LoadingCenter />,
    rejectedFallback: <ErrorPage />,
  });

const AsyncOrderDetail = pageAsyncRoute(OrderDetail);
const AsyncCostInfo = pageAsyncRoute(CostInfo);
const AsyncCoupon = pageAsyncRoute(Coupon);
const AsyncOperation = pageAsyncRoute(Operation);
const AsyncOrganization = pageAsyncRoute(Organization);
const AsyncSales = pageAsyncRoute(Sales);

const AsyncRefundHistory = pageAsyncRoute(RefundHistory);
const AsyncPaymentHistory = pageAsyncRoute(PaymentHistory);

export {
  AsyncCostInfo,
  AsyncCoupon,
  AsyncOperation,
  AsyncOrderDetail,
  AsyncOrganization,
  AsyncPaymentHistory,
  AsyncRefundHistory,
  AsyncSales,
};
