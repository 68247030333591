import { Dialogue } from '@imago-cloud/design-system';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { errorState } from './errorState';

const GlobalErrorDialog = () => {
  const { open, component: ErrorComponent } = useRecoilValue(errorState);
  const setErrorState = useSetRecoilState(errorState);

  const handleClose = () => {
    setErrorState({ open: false, component: null });
  };

  return (
    <Dialogue open={open} onClose={handleClose}>
      {ErrorComponent && <ErrorComponent />}
    </Dialogue>
  );
};

export default GlobalErrorDialog;
