import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Loading, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Box, Grid, Stack } from '@mui/material';
import { FileResponse } from 'client/types.gen';
import { PreviewDialog } from 'pages/OrderDetail/components/PreviewDialog';
import { FILE_EXTENSIONS_3D } from 'shared/constants/file';
import { downloadFile } from 'shared/utils/file';

export type ConvertFileType = {
  title: string;
  location: 'DESIGN' | 'PATIENT' | 'OTHER';
  files: { name: string; thumbnailUrl: string; fileUrl: string; id: string; createdAt: string }[];
};
export const FILE_LOCATION_RESPONSE = {
  DESIGN: 'designedFilesResponse',
  PATIENT: 'patientFilesResponse',
  OTHER: 'otherFilesResponse',
};
export type totalFilesResponseType = {
  designedFilesResponse: Array<FileResponse>;
  patientFilesResponse: Array<FileResponse>;
  otherFilesResponse: Array<FileResponse>;
};

export const Databox = ({
  patientFilesResponse,
  designedFilesResponse,
  otherFilesResponse,
}: totalFilesResponseType) => {
  const { t } = useTranslation();
  const sections: ConvertFileType[] = [
    {
      title: 'data_files.title_design_files',
      location: 'DESIGN',
      files: designedFilesResponse.map((file) => ({
        name: file.fileName,
        fileUrl: file.fileUrl || '',
        thumbnailUrl: file.thumbnailUrl || '',
        id: file.id,
        createdAt: file.createdAt,
      })),
    },
    {
      title: 'data_files.title_patient_data',
      location: 'PATIENT',
      files: patientFilesResponse.map((file) => ({
        name: file.fileName,
        fileUrl: file.fileUrl || '',
        thumbnailUrl: file.thumbnailUrl || file.fileUrl || '',
        id: file.id,
        createdAt: file.createdAt,
      })),
    },
    {
      title: 'data_files.title_other_files',
      location: 'OTHER',
      files: otherFilesResponse.map((file) => ({
        name: file.fileName,
        fileUrl: file.fileUrl || '',
        thumbnailUrl: file.thumbnailUrl || file.fileUrl || '',
        id: file.id,
        createdAt: file.createdAt,
      })),
    },
  ];
  const totalFilesResponse = {
    patientFilesResponse,
    designedFilesResponse,
    otherFilesResponse,
  };
  return (
    <>
      {sections.map((section, idx) => (
        <Stack key={idx}>
          <DataboxContent data={section} totalFilesResponse={totalFilesResponse} />
          {idx !== sections.length - 1 && (
            <Stack sx={{ height: '1px', backgroundColor: theme.palette.divider, my: '24px' }} />
          )}
        </Stack>
      ))}
    </>
  );
};

const DataboxContent = ({
  data,
  totalFilesResponse,
}: {
  data: ConvertFileType;
  totalFilesResponse: totalFilesResponseType;
}) => {
  const { t } = useTranslation();
  const { title, location, files } = data;

  return (
    <Stack sx={{ gap: '8px' }}>
      <Typography variant={'H18'}>{t(title)}</Typography>
      {/*image 2열*/}
      <Grid container sx={{ rowGap: '20px', justifyContent: 'space-between', width: '100%' }}>
        {files.length > 0 ? (
          files.map((file, index: number) => (
            <Grid item key={index} sx={{ textAlign: 'center', width: '246px' }}>
              <ImagePreview
                fileId={file.id}
                fileName={file.name}
                fileUrl={file.fileUrl}
                totalFilesResponse={totalFilesResponse}
                thumbnailUrl={file.thumbnailUrl}
                showDeleteButton={location === 'OTHER'}
                location={location}
                createdAt={file.createdAt}
              />
              <Typography
                variant={'H14'}
                sx={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {file.name}
              </Typography>
            </Grid>
          ))
        ) : (
          <Typography
            variant={'Subtitle_Semibold18'}
            color={theme.palette.text.secondary}
            sx={{ textAlign: 'center', width: '100%' }}
          >
            {t('data_files.no_files_uploaded_yet')}
          </Typography>
        )}
      </Grid>
    </Stack>
  );
};

const ImagePreview = ({
  fileId,
  fileName,
  fileUrl,
  thumbnailUrl,
  showDeleteButton,
  createdAt,
  location,
  totalFilesResponse,
}: {
  fileId: string;
  fileName: string;
  totalFilesResponse: totalFilesResponseType;
  fileUrl: string;
  thumbnailUrl: string;
  showDeleteButton: boolean;
  location: ConvertFileType['location'];
  createdAt: string;
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const is3DModel = FILE_EXTENSIONS_3D.includes(fileName.split('.').pop() || '');

  const handleFileDelete = () => {
    //delete는 other file만 가능 ( 현재 chat 기능 미구현으로 인해 사용하지 않음 )
  };
  const handleFileDetailClick = () => {
    if (!fileName && !thumbnailUrl) {
      return;
    }
    setOpen(true);
  };

  const handleFileDetailClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(thumbnailUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setPreviewUrl(url);
    };

    fetchImage();

    return () => {
      // Clean up the object URL
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [thumbnailUrl]);

  return (
    <>
      <Stack
        sx={{
          width: '246px',
          height: '165px',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          position: 'relative',
          justifyContent: 'center',
          alignContent: 'center',
          mb: '6px',
          '&:hover': { backgroundColor: theme.palette.action.hover, cursor: 'pointer' },
        }}
        onClick={handleFileDetailClick}
      >
        {!previewUrl ? (
          <Loading size="24" type="basic" />
        ) : (
          <Box
            component="img"
            src={previewUrl}
            alt="Preview"
            sx={{
              width: '171px',
              height: '106px',
              borderRadius: '8px',
              margin: 'auto',
            }}
            onError={() => {
              setPreviewUrl('/shippingDefaultImg.png');
            }}
          />
        )}
        <IconButton
          size={'18'}
          color={'grey800'}
          variant={'transparent2'}
          sx={{ position: 'absolute', top: '8px', left: '8px' }}
          onClick={(e) => {
            e.stopPropagation();
            downloadFile(fileUrl, fileName);
          }}
        >
          <Ricon icon={'ri-download-2-fill'} />
        </IconButton>
        {showDeleteButton && (
          <IconButton
            size={'18'}
            color={'grey800'}
            variant={'transparent2'}
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
            onClick={handleFileDelete}
          >
            <Ricon icon={'ri-close-fill'} />
          </IconButton>
        )}
      </Stack>
      <PreviewDialog
        open={open}
        handleClose={handleFileDetailClose}
        previewUrl={previewUrl}
        is3DModel={is3DModel}
        fileId={fileId}
        fileName={fileName}
        createdAt={createdAt}
        totalFilesResponse={totalFilesResponse}
        location={location}
      />
    </>
  );
};
