import { useRecoilState } from 'recoil';

import { authState } from './authState';

export const useAuthState = () => {
  const [authInfo, setAuthInfo] = useRecoilState(authState);

  const setIsAuthLoading = (isAuthLoading: boolean) => {
    setAuthInfo((prev) => ({
      ...prev,
      isAuthLoading,
    }));
  };

  const setIsAuthorized = (isAuthorized: boolean) => {
    setAuthInfo((prev) => ({
      ...prev,
      isAuthorized,
    }));
  };

  const setIsErrorPage = (isErrorPage: boolean) => {
    setAuthInfo((prev) => ({
      ...prev,
      isErrorPage,
    }));
  };

  return { authInfo, setAuthInfo, setIsAuthLoading, setIsAuthorized, setIsErrorPage };
};
