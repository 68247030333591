import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { dentitionType } from 'client/types.gen';

import Palmer, { SelectedToothListType } from './Palmer';

type ToothFormSelectorProp = {
  selectedTooth: SelectedToothListType;
  onToothClick: (clickedToothNumber: number) => void;
  onBridgeClick: (clickedBridgeNumberPair: string) => void;
  selectedBridgeNumberPairList: Array<string>;
};
// 이대로 사용해야 재활용 가능.
export default function ToothFormSelector({
  selectedTooth,
  onToothClick,
  onBridgeClick,
  selectedBridgeNumberPairList,
}: Readonly<ToothFormSelectorProp>) {
  const { t } = useTranslation();

  const DENTAL_TYPE_VALUE = [
    { label: t('combination.dropdown_permanent'), value: 'PERMANENT' },
    { label: t('prescription_field.dropdown_deciduous'), value: 'DECIDUOUS' },
    { label: t('prescription_field.dropdown_mixed'), value: 'MIXED' },
  ];

  const [dentalType, setDentalType] = useState<dentitionType>(DENTAL_TYPE_VALUE[0].value as dentitionType);

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: '386px', height: '660px', margin: 'auto' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Palmer
          selectedToothList={selectedTooth}
          onToothClick={onToothClick}
          onBridgeClick={onBridgeClick}
          selectedBridgeNumberPairList={selectedBridgeNumberPairList}
        />
      </Box>

      <Stack justifyContent="center" alignItems="center" gap="55px" sx={{ position: 'absolute' }}>
        <Select
          size="48"
          onChange={(e) => setDentalType(e.target.value as dentitionType)}
          value={dentalType}
          sx={{ width: '150px' }}
        >
          {DENTAL_TYPE_VALUE.map((menu) => (
            <MenuItem key={menu.value} value={menu.value}>
              {menu.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
}
