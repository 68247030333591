export const validateFileLimits = (
  currentFiles: File[],
  newFiles: File[],
): { isFileCountLimit: boolean; isFileSizeLimit: boolean } => {
  const currentFileSize = currentFiles.map((file) => file.size).reduce((acc, cur) => acc + cur, 0);
  const newFileSize = newFiles.map((file) => file.size).reduce((acc, cur) => acc + cur, 0);

  const isFileCountLimit = newFiles.length + currentFiles.length > 40;
  const isFileSizeLimit = currentFileSize + newFileSize > 2 * 1024 * 1024 * 1024; // 2GB

  return { isFileCountLimit, isFileSizeLimit };
};
