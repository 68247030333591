import { ChangeEvent, DragEvent, useState } from 'react';

export const useFileUploadWithDnD = ({
  multiple = true,
  callback,
}: {
  multiple?: boolean;
  callback?: (files: File[] | null) => void;
}) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
    const newFiles = Array.from(e.dataTransfer.files);
    callback && callback(newFiles);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      callback && callback(newFiles);
      e.target.value = '';
    }
  };

  return { isDragOver, handleDragOver, handleDragLeave, handleDrop, handleFileChange };
};
