import { ROUTES } from 'constants/routes';
import {
  NO_FILTER_ORDER_DESCRIPTION,
  NO_PLACED_ORDER_DESCRIPTION,
  NO_RECEIVED_ORDER_DESCRIPTION,
} from 'pages/MyOrderList/components/NoOrderData';
import { RECEIVED_ORDER_LIST_HEADER_CONFIG } from 'pages/MyOrderList/constants/labConfig';
import { PLACE_ORDERED_LIST_HEADER_CONFIG } from 'pages/MyOrderList/constants/userConfig';
import { ORDER_PAGE_INFO } from 'shared/constants/subHeaderConfig';
import { OrderRole } from 'shared/types/role';

export const getCurrentOrderStatus = (): OrderRole => {
  const path = window.location.pathname;

  if (path.includes('order-detail')) {
    return path.includes('placed-order-detail') ? 'orderer' : 'receiver';
  }

  if (path.includes('placed-orders')) {
    return 'orderer';
  }

  if (path.includes('received-orders')) {
    return 'receiver';
  }

  return 'orderer'; // default
};

export const getOrderStatusConfig = () => {
  const currentOrderStatus = getCurrentOrderStatus();

  const config = {
    orderer: {
      apiPath: 'connect/orders',
      orderPageConfig: ORDER_PAGE_INFO.orderer,
      orderListHeaderConfig: PLACE_ORDERED_LIST_HEADER_CONFIG,
      highlightStatuses: ['WAITING_DESIGN_CONFIRMATION', 'REJECTED'],
      getOrderDetailNavigatePath: (orderId: string) => `${ROUTES.ORDER_ROUTER.root}/placed-order-detail/${orderId}`,
      getOrderDataDescription: (isDefaultFilter: boolean) =>
        isDefaultFilter ? NO_PLACED_ORDER_DESCRIPTION : NO_FILTER_ORDER_DESCRIPTION,
    },
    receiver: {
      apiPath: 'connect/orders/partner-lab',
      orderPageConfig: ORDER_PAGE_INFO.receiver,
      orderListHeaderConfig: RECEIVED_ORDER_LIST_HEADER_CONFIG,
      highlightStatuses: ['ORDERED', 'DESIGNING', 'MANUFACTURING'],
      getOrderDetailNavigatePath: (orderId: string) => `${ROUTES.ORDER_ROUTER.root}/received-order-detail/${orderId}`,
      getOrderDataDescription: (isDefaultFilter: boolean) =>
        isDefaultFilter ? NO_RECEIVED_ORDER_DESCRIPTION : NO_FILTER_ORDER_DESCRIPTION,
    },
  };

  return config[currentOrderStatus];
};
