import '@sendbird/uikit-react/dist/index.css';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import { GroupChannelList } from '@sendbird/uikit-react/GroupChannelList';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Badge, Button } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { GroupChannelListOrder } from '@sendbird/chat/groupChannel';
import { ChatMessage } from 'assets';

import { useUploadOtherFile } from '../../../api/fileQuery';
import { useUnreadMessageCountAll } from '../../hooks/Sendbird/useCreateChannel';
import CustomChannelListHeader from './CustomChannelListHeader';
import CustomGroupChannelHeader from './CustomGroupChannelHeader';

import './customStyle.css';

export default function Chat() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { unreadMessageCount, getUnreadMessageCount } = useUnreadMessageCountAll();

  const handleChatToggleButtonClick = () => {
    setIsOpen((prev) => !prev);
    getUnreadMessageCount();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          position: 'absolute',
          width: 'fit-content',
          height: '740px',

          bottom: '20px',
          right: '20px',
          visibility: isOpen ? 'visible' : 'hidden',
          zIndex: 2,
        }}
      >
        <ChatWindow onChatToggleButtonClick={handleChatToggleButtonClick} />
      </Box>
      <Box sx={{ position: 'absolute', bottom: '40px', right: '30px' }}>
        <Badge
          badgeContent={unreadMessageCount}
          color="primary"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Button onClick={handleChatToggleButtonClick} size="48" color="grey" startIcon={<ChatMessage />}>
            {t('chat.messages')}
          </Button>
        </Badge>
      </Box>
    </>
  );
}

interface ChatWindowProps {
  onChatToggleButtonClick: () => void;
}

export function ChatWindow({ onChatToggleButtonClick }: ChatWindowProps) {
  const [currentChannelUrl, setCurrentChannelUrl] = useState('');
  const [channelName, setChannelName] = useState('');
  const { pathname } = useLocation();

  const handleBeforeSendFileMessage = useUploadOtherFile(currentChannelUrl);

  const orderType = pathname.split('/')[2];

  const handleBackButtonClick = () => {
    setCurrentChannelUrl('');
    setChannelName('');
  };

  return (
    <div className="sendbird-app__wrap" style={{ width: '540px' }}>
      <div
        className="sendbird-app__channellist-wrap"
        style={{
          display: currentChannelUrl === '' ? 'block' : 'none',
          width: '100%',
          borderTop: '0',
        }}
      >
        <GroupChannelList
          disableAutoSelect={true}
          allowProfileEdit={false}
          channelListQueryParams={{
            includeEmpty: true,
            limit: 20,
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
          }}
          onChannelCreated={() => {}}
          onChannelSelect={(channel) => {
            if (!channel) {
              return handleBackButtonClick();
              // throw new Error('No channel selected');
            }

            setChannelName(channel.name);
            setCurrentChannelUrl(channel.url);
          }}
          renderHeader={() => (
            <CustomChannelListHeader orderType={orderType} onChatToggleButtonClick={onChatToggleButtonClick} />
          )}
        />
      </div>
      {currentChannelUrl && (
        <div className={'sendbird-app__conversation-wrap'} style={{ width: '100%' }}>
          <GroupChannel
            isReactionEnabled={false}
            onBeforeSendFileMessage={handleBeforeSendFileMessage}
            channelUrl={currentChannelUrl}
            // renderFileUploadIcon={() => <CustomFileInput />}
            // renderMessageInput={() => {
            //   return (
            //     <>
            //       <MessageInput channel={currentChannelUrl} />
            //       <input type={'file'} accept={'*/*'} onChange={(e) => console.log(e)} />
            //     </>
            //   );
            // }}
            renderChannelHeader={() => (
              <CustomGroupChannelHeader
                channelName={channelName}
                onChatToggleButtonClick={() => {
                  onChatToggleButtonClick();
                  handleBackButtonClick();
                }}
                onBackButtonClick={handleBackButtonClick}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

function CustomFileInput() {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 1) {
      const file = e.target.files[0];
      // console.log(file);
    }
  };

  const handleImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <button
        onClick={handleImageClick}
        className={'sendbird-message-input--attach sendbird-iconbutton custom-attach-icon'}
        style={{ top: '8px', width: '40px', height: '40px', borderRadius: '12px' }}
      >
        <Stack className={'sendbird-iconbutton__inner'} justifyContent="center" alignItems="center">
          <Stack
            className={'sendbird-icon sendbird-icon-attach sendbird-icon-color--content-inverse'}
            // style={{ width: '20px', minWidth: '20px', height: '20px', minHeight: '20px' }}
          >
            <img width={48} height={48} src={'/ChatAttach.png'} alt="file-upload" />
          </Stack>
        </Stack>
      </button>
      <input ref={fileInputRef} type={'file'} accept={'*/*'} onChange={handleFileChange} style={{ display: 'none' }} />
    </>
  );
}
