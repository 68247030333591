import { ListHeaderDetailType } from 'shared/types/list';

export const PLACE_ORDERED_LIST_HEADER_CONFIG: ListHeaderDetailType = {
  patientName: {
    type: 'text',
    label: 'list_header_my_orders.patient_name',
    minWidth: '300px',
    widthPercent: '30%',
  },
  orderItems: {
    type: 'text',
    label: 'list_header_my_orders.info',
    minWidth: '330px',
    widthPercent: '30%',
  },
  status: {
    type: 'filter',
    label: 'list_header_my_orders.status',
    minWidth: '180px',
    notice: 'status_tooltip.body',
    widthPercent: '15%',
  },
  isRemake: {
    type: 'text',
    label: 'list_header_my_orders.remake',
    minWidth: '90px',
    widthPercent: '15%',
  },
  statusUpdatedAt: {
    type: 'sort',
    label: 'list_header_my_orders.modified',
    minWidth: '220px',
    widthPercent: '15%',
  },
};
