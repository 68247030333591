import React, { useMemo, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Cicon,
  Dialogue,
  DialogueContent,
  DialogueTitle,
  IconButton,
  Label,
  Ricon,
  TextField,
  theme,
  Typography,
} from '@imago-cloud/design-system';
import { alpha, Box, Stack } from '@mui/material';
import { FileResponse, OrderItem, TrackingResponse } from 'client/types.gen';
import { CommonWrapper } from 'pages/OrderDetail/components/ActionComponents/CommonWrapper';
import { ConfirmRequired } from 'pages/OrderDetail/components/ActionComponents/ConfirmRequired';
import { DesignUpload } from 'pages/OrderDetail/components/ActionComponents/DesignUpload';
import { ManufacturedFileUpload } from 'pages/OrderDetail/components/ActionComponents/ManufacturedFileUpload';
import { ToothProsthesisLabel, useUserToothNumbering } from 'shared/hooks/useUserToothNumbering';
import { FormProvider } from 'shared/ui';
import { downloadFile, downloadFilesAsZip } from 'shared/utils/file';

import { useOrderRateMutation } from '../../../api/orderQuery';

const NoActionText = () => {
  const { t } = useTranslation();
  return (
    <CommonWrapper>
      <Typography variant={'Body18'}>{t('patient_page_status.no_action_required')}</Typography>
    </CommonWrapper>
  );
};

const ShipInfo = ({ orderId, tracking }: { orderId: string; tracking?: TrackingResponse }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const handleImageLargeClick = () => {
    setOpen(true);
  };
  const handleImageLargeClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack sx={{ gap: '16px' }}>
        <Stack sx={{ gap: '4px' }}>
          <Stack sx={{ flexDirection: 'row', gap: '4px' }}>
            <Typography variant={'Subtitle_Semibold16'}>{t('patient_page_status.subtitle_carrier')}</Typography>
            <Typography variant={'H16'}>{tracking?.shippingCarrier}</Typography>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: '4px' }}>
            <Typography variant={'Subtitle_Semibold16'}>{t('patient_page_status.subtitle_tracking_number')}</Typography>
            <Typography variant={'H16'}>{tracking?.trackingNumber}</Typography>
          </Stack>
        </Stack>
        {imageLoadFailed ? (
          <Stack
            sx={{
              width: '100%',
              height: '154px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              border: `1px solid ${theme.palette.grey['200']}`,
            }}
          >
            <Ricon icon="ri-alert-fill" svgProps={{ width: 20, height: 20, fill: theme.palette.error.main }} />
            <Typography variant={'Subtitle_Semibold14'}>{t('patient_page_status.failed_to_load_image')}</Typography>
          </Stack>
        ) : (
          <Box
            sx={{
              width: '565px',
              height: '154px',
              borderRadius: '8px',
              overflow: 'hidden',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer',

                '& span': {
                  color: alpha(theme.palette.common.white, 0.48),
                },
              },
            }}
            onClick={handleImageLargeClick}
          >
            <img
              src={tracking?.shippingLabelFile?.fileUrl || '/shippingDefaultImg.png'}
              alt="Shipping label"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
              onError={(e) => {
                e.currentTarget.src = '/shippingDefaultImg.png';
                setImageLoadFailed(true);
              }}
            />
            {!tracking?.shippingLabelFile?.fileUrl && (
              <Typography
                variant={'Subtitle_Semibold16'}
                color={theme.palette.common.white}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%)',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('patient_page_status.shipping_label_image_not_uploaded')}
              </Typography>
            )}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.48)',
                },
              }}
            />
          </Box>
        )}
      </Stack>
      <LargerImageDialogue
        open={open}
        handleClose={handleImageLargeClose}
        imageUrl={tracking?.shippingLabelFile?.fileUrl || '/shippingDefaultImg.png'}
      />
    </>
  );
};

export const LargerImageDialogue = ({
  open,
  handleClose,
  imageUrl,
}: {
  open: boolean;
  imageUrl: string;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  return (
    <Dialogue open={open} sx={{ '& .MuiDialog-paper': { minWidth: '753px', borderRadius: '12px' } }}>
      <DialogueTitle
        sx={{
          '& .MuiBox-root': {
            width: '100%',
            margin: 'auto',
          },
        }}
      >
        <Stack sx={{ flexDirection: 'row', position: 'relative', width: '100%', height: '36px', alignItems: 'center' }}>
          <Typography variant={'H18'}>{t('dialog_shipping_label.title')}</Typography>
          <Stack sx={{ flexDirection: 'row', gap: '16px', position: 'absolute', right: 0 }}>
            <IconButton
              size={'36'}
              color={'grey900'}
              variant={'transparent'}
              onClick={() => downloadFile(imageUrl, 'shipping-label')}
            >
              <Ricon icon={'ri-download-2-line'} />
            </IconButton>
            <IconButton size={'36'} color={'grey900'} variant={'transparent'} onClick={handleClose}>
              <Ricon icon={'ri-close-line'} />
            </IconButton>
          </Stack>
        </Stack>
      </DialogueTitle>
      <DialogueContent sx={{ p: '16px 34px 36px 34px', mt: '16px' }}>
        <Box
          component="img"
          src={imageLoadFailed ? '/shippingDefaultImg.png' : imageUrl}
          onError={() => setImageLoadFailed(true)}
          sx={{
            width: '100%',
            height: '473px',
            objectFit: 'cover',
          }}
        />
        {imageLoadFailed ? (
          <Typography
            variant={'Subtitle_Semibold16'}
            color={theme.palette.common.white}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%)',
            }}
          >
            Failed to load shipping label
          </Typography>
        ) : (
          !imageUrl && (
            <Typography
              variant={'Subtitle_Semibold16'}
              color={theme.palette.common.white}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%)',
              }}
            >
              {t('patient_page_status.shipping_label_image_not_uploaded')}
            </Typography>
          )
        )}
      </DialogueContent>
    </Dialogue>
  );
};

const DesignedFileDownload = ({ designFiles, patientName }: { designFiles: FileResponse[]; patientName: string }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Button
      size={60}
      variant={'outlined'}
      sx={{ width: '100%' }}
      startIcon={<Ricon icon={'ri-download-2-fill'} svgProps={{ fill: theme.palette.primary.main }} />}
      onClick={async () => {
        setIsLoading(true);
        await downloadFilesAsZip(designFiles, patientName);
        setIsLoading(false);
      }}
      loading={isLoading}
    >
      {t('patient_page_status.btn_download_designed_file')}
    </Button>
  );
};

type RatingFormValues = {
  ratings: { [orderItemId: string]: number };
  comment: string;
};

export const Rating = ({ orderId, orderItems }: { orderId: string; orderItems: OrderItem[] }) => {
  const { t } = useTranslation();
  const { mutateAsync: rateMutate } = useOrderRateMutation(orderId);
  const { getAllToothLabels } = useUserToothNumbering();
  const teethLabelsByProthesis = useMemo(() => getAllToothLabels(orderItems), [orderItems]);

  const isSubmittedRating = orderItems.some(({ rate }) => rate > 0);
  const methods = useForm<RatingFormValues>({
    defaultValues: {
      ratings: teethLabelsByProthesis.reduce(
        (acc, item) => {
          acc[item.orderItemId] = 0;
          return acc;
        },
        {} as { [orderItemId: string]: number },
      ),
      comment: '',
    },
    mode: 'onChange',
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = methods;
  const ratings = watch('ratings'); // This will re-render when ratings change
  const isRatingValid = Object.values(ratings).every((rating) => rating > 0);
  const onSubmit = async ({ ratings, comment }: RatingFormValues) => {
    const data = {
      rateOrderItems: Object.entries(ratings).map(([orderItemId, rate]) => ({ orderItemId, rate })),
      comment,
    };
    await rateMutate(data);
  };
  if (isSubmittedRating) return <></>;
  //{t('patient_page_status.')}
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ borderRadius: '8px', p: '20px 16px', backgroundColor: theme.palette.grey['200'], gap: '20px' }}>
        {/*  raiting*/}
        <Stack sx={{ gap: '12px' }}>
          <Typography variant={'Subtitle_Semibold14'}>{t('patient_page_status.opinion')}</Typography>
          <RatingRows teethLabelsByProthesis={teethLabelsByProthesis} control={control} />
        </Stack>
        {/*  feedback*/}
        <Stack sx={{ gap: '12px' }}>
          <Typography variant={'Subtitle_Semibold14'}>{t('patient_page_status.feedback')}</Typography>
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                minRows={1}
                size={'36'}
                placeholder={t('patient_page_status.textfield_placeholder_feedback') ?? ''}
                sx={{
                  width: '100%',
                  backgroundColor: 'white !important',
                  '& .MuiOutlinedInput-input': {
                    padding: '0',
                  },
                  borderRadius: '8px',
                }}
                onChange={(e) => {
                  let inputText = e.target.value;
                  if (inputText.length > 500) {
                    inputText = inputText.slice(0, 500);
                  }

                  field.onChange(inputText);
                }}
                onBlur={() => field.onChange(field.value.trim())}
              />
            )}
          />
        </Stack>
        <Button type="submit" size={'48'} disabled={!isRatingValid} sx={{ alignSelf: 'flex-end' }}>
          {t('patient_page_status.btn_submit')}
        </Button>
      </Stack>
    </FormProvider>
  );
};

const RatingRows = ({
  teethLabelsByProthesis,
  control,
}: {
  teethLabelsByProthesis: ToothProsthesisLabel[];
  control: Control<RatingFormValues, any>;
}) => {
  return (
    <Stack sx={{ gap: '10px' }}>
      {teethLabelsByProthesis.map(({ label, orderItemId }, idx) => (
        <Stack key={orderItemId} sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Label variant={'outlined'}>{label}</Label>
          <Controller
            name={`ratings.${orderItemId}`} // 각 치식에 대한 별점 값
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Stack sx={{ flexDirection: 'row' }}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <Stack
                    key={star}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (star === field.value) {
                        field.onChange(star - 1);
                      } else {
                        field.onChange(star);
                      }
                    }}
                  >
                    <Cicon key={star} icon={star <= field.value ? 'RatingStarSelected' : 'RatingStar'} />
                  </Stack>
                ))}
              </Stack>
            )}
          />
        </Stack>
      ))}
    </Stack>
  );
};

const ActionComponent = {
  ConfirmRequired, //o
  NoActionText, // o
  ShipInfo,
  DesignedFileDownload,
  ManufacturedFileUpload, // o
  DesignUpload, // o
  Rating,
};

export default ActionComponent;
