import { DialogType } from 'store/globalDialog/atoms';

export const ORDER_DETAIL_CANCEL = (onActionClick: () => void): DialogType => ({
  id: 'cancel_order',
  priority: 3,
  type: 'common',
  //
  dialogContent: {
    title: 'dialog_cancel_order.title',
    isTitleAlert: true,
    content: 'dialog_cancel_order.body',
    color: 'error',

    trigger: {
      cancel: {
        text: 'dialog_cancel_order.btn_cancel',
      },
      action: {
        text: 'dialog_cancel_order.btn_confirm',
        onClick: onActionClick,
      },
    },
  },
});

export const ORDER_COMPLETED = (onActionClick: () => void): DialogType => ({
  id: 'order_completed',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialog_shipment_completed.title',
    isTitleAlert: false,
    content: 'dialog_shipment_completed.body',
    color: 'primary',
    trigger: {
      cancel: {
        text: 'dialog_shipment_completed.btn_cancel',
      },
      action: {
        text: 'dialog_shipment_completed.btn_confirm',
        onClick: onActionClick,
      },
    },
  },
});

export const ORDER_DESIGN_APPROVE = (onActionClick: () => void): DialogType => ({
  id: 'order_design_approve',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialog_approve_order_confirm.title',
    isTitleAlert: false,
    content: 'dialog_approve_order_confirm.body',
    color: 'primary',
    trigger: {
      cancel: {
        text: 'dialog_approve_order_confirm.btn_cancel',
      },
      action: {
        text: 'dialog_approve_order_confirm.btn_confirm',
        onClick: onActionClick,
      },
    },
  },
});

export const ORDER_DESIGN_REJECT = (onActionClick: () => void): DialogType => ({
  id: 'order_design_reject',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialogue_reject_order_confirm.title',
    isTitleAlert: true,
    content: 'dialogue_reject_order_confirm.body',
    color: 'error',
    trigger: {
      cancel: {
        text: 'dialogue_reject_order_confirm.btn_cancel',
      },
      action: {
        text: 'dialogue_reject_order_confirm.btn_reject',
        onClick: onActionClick,
      },
    },
  },
});

export const LAB_ORDER_RECEIVE = (onActionClick: () => void): DialogType => ({
  id: 'lab_order_approve',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialoge_receive_this_order.title',
    isTitleAlert: false,
    content: 'dialoge_receive_this_order.body',
    color: 'primary',
    trigger: {
      cancel: {
        text: 'dialoge_receive_this_order.btn_cancel',
      },
      action: {
        text: 'dialoge_receive_this_order.btn_confirm',
        onClick: onActionClick,
      },
    },
  },
});

export const LAB_ORDER_REJECT = (onActionClick: () => void): DialogType => ({
  id: 'lab_order_reject',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialog_reject_this_order.title',
    isTitleAlert: true,
    content: 'dialog_reject_this_order.body',
    color: 'error',
    trigger: {
      cancel: {
        text: 'dialog_reject_this_order.btn_cancel',
      },
      action: {
        text: 'dialog_reject_this_order.btn_reject',
        onClick: onActionClick,
      },
    },
  },
});

//TODO : i18n 작업
export const FILE_UPLOAD_LIMITATIONS = (onActionClick: () => void): DialogType => ({
  id: 'file_upload_limitations',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialog_file_upload_limitations.title',
    content: 'dialog_file_upload_limitations.body',
    color: 'error',
    trigger: {
      action: {
        text: 'dialog_file_upload_limitations.btn_confirm',
        onClick: onActionClick,
      },
    },
  },
});

export const REMAKE_LIMITATIONS = {
  id: 'remake_limitations',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialogue_unable_to_order_remake.title',
    content: 'dialogue_unable_to_order_remake.body',
    color: 'error',
    trigger: {
      action: {
        text: 'dialogue_unable_to_order_remake.btn_confirm',
      },
    },
  },
};
