import { useState } from 'react';

export default function usePopover() {
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | HTMLButtonElement | null>(null);

  const handleAnchorRef = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    setAnchorRef(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorRef(null);
  };

  const open = Boolean(anchorRef);
  const id = open ? 'popover' : undefined;
  return { anchorRef, handleAnchorRef, handleClose, open, id };
}
