import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Cicon, Dialogue, DialogueActions, DialogueContent, DialogueTitle } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { ROUTES } from 'constants/routes';
import { ICloseButtonOnlyDialogueProps } from 'shared/hooks/useDialogue';

export type IDialogueInfo = {
  title: string;
  content: string;
  action: string;
  type?: string;
};

type OrderSubmitDialogueType = ICloseButtonOnlyDialogueProps & {
  dialogueInfo: IDialogueInfo;
};

export const OrderSubmitDialogue = ({ open, onClose, dialogueInfo }: OrderSubmitDialogueType) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleButtonClick = () => {
    if (dialogueInfo.type === 'failed') {
      onClose();
    } else {
      onClose();
      navigate(`${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.placedOrderList}`);
    }
  };

  return (
    <Dialogue open={open}>
      <DialogueTitle>
        <Stack gap="8px" direction="row" alignItems="center">
          {dialogueInfo.type === 'failed' && (
            <Cicon
              icon="Warning"
              svgProps={{
                width: 22,
                height: 22,
              }}
            />
          )}
          {t(dialogueInfo.title)}
        </Stack>
      </DialogueTitle>
      <DialogueContent>{t(dialogueInfo.content)}</DialogueContent>
      <DialogueActions>
        <Button
          size="36"
          color={dialogueInfo.type === 'failed' ? 'error' : 'primary'}
          variant="contained"
          onClick={handleButtonClick}
          sx={{ minWidth: '100px' }}
        >
          {t(dialogueInfo.action)}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
