import { HTTPError } from 'ky';

import { ResponseDtoUserGetResponse } from '../client';
import { kyInstance } from './kyInstance';

export const getUser = async (): Promise<ResponseDtoUserGetResponse | null> => {
  try {
    const response = await kyInstance.get('connect/users/me', {});
    return await response.json();
  } catch (error) {
    if (error instanceof HTTPError && error.response?.status === 400) {
      return null;
    }
    throw error;
  }
};
