import { ComponentProps, ComponentType, Suspense } from 'react';

import ErrorBoundary from './AsyncErrorBoundary';

interface AsyncBoundaryProps {
  rejectedFallback: ComponentProps<typeof ErrorBoundary>['fallback'];
  pendingFallback: ComponentProps<typeof Suspense>['fallback'];
}

export default function withAsyncBoundary<Props extends Record<string, unknown>>(
  WrappedComponent: ComponentType<Props>,
  { pendingFallback, rejectedFallback }: AsyncBoundaryProps,
) {
  return (props: Props) => {
    return (
      <ErrorBoundary fallback={rejectedFallback}>
        <Suspense fallback={pendingFallback}>
          <WrappedComponent {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
}
