import React, { ErrorInfo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Cicon, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

interface State {
  error?: PromiseRejectionEvent | Error | null;
  errorInfo?: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = { error: null, errorInfo: null };

  promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState({
      error: event,
    });
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  render() {
    if (this.state.errorInfo || this.state.error) {
      return <DefaultErrorScreen error={this.state.error} errorInfo={this.state.errorInfo} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

export const DefaultErrorScreen = ({ error, errorInfo }: State) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: '100vw', height: '100vh' }}>
      <Cicon
        icon="Warning"
        svgProps={{
          width: 80,
          height: 80,
        }}
      />
      <Typography variant="H36" sx={{ mt: '12px', mb: '40px' }}>
        {t('unknown_error.title')}
      </Typography>
      <Button variant="outlined" color="grey" onClick={() => window.location.reload()} size="48">
        {t('unknown_page_error.btn_refresh')}
      </Button>
    </Stack>
  );
};
