import { Typography } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';

import { useProsthesisByOrderType } from '../../../../api/productQuery';
import { ColorPair } from '../../../../client';
import useNewOrderDataContext from '../../context/useNewOrderDataContext';

export default function ProsthesisLegend() {
  const {
    orderData: { designManufactureCombination, orderItems },
  } = useNewOrderDataContext();
  const {
    data: { data: prosthesisList },
  } = useProsthesisByOrderType(designManufactureCombination!);

  const selectedProsthesisNameList = orderItems.map((item) => item.prosthesis);
  const selectedProsthesisList = prosthesisList.filter((item) => selectedProsthesisNameList.includes(item.value));

  return (
    <Stack gap="6px" sx={{ position: 'absolute', left: '20px', bottom: '40px' }}>
      {selectedProsthesisList.map(({ value, colorCode }) => (
        <LegendItem key={value} value={value} colorCode={colorCode} />
      ))}
    </Stack>
  );
}

interface LegendItemProps {
  colorCode: ColorPair;
  value: string;
}
const LegendItem = ({ colorCode, value }: LegendItemProps) => {
  return (
    <Stack direction="row" gap="8px">
      <Box
        sx={{
          width: '24px',
          height: '24px',
          backgroundColor: colorCode.fill,
          border: `1px solid ${colorCode.stroke}`,
          borderRadius: '3px',
        }}
      />
      <Box>
        <Typography variant="Subtitle_Semibold14">{value}</Typography>
      </Box>
    </Stack>
  );
};
