import { atom } from 'recoil';

export type ISnackbar = {
  open: boolean;
  message: string | null;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
};

export const DEFAULT_BOTTOM_SNACKBAR_STATE: ISnackbar = {
  open: false,
  message: null,
  actionButtonText: undefined,
  onActionButtonClick: undefined,
};

export const bottomSnackbarState = atom<ISnackbar>({
  key: 'bottomSnackbar',
  default: DEFAULT_BOTTOM_SNACKBAR_STATE,
});
