import { css } from '@mui/material';

export const scrollBar = css`
  &::-webkit-scrollbar {
    width: 14px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 14px;
    background-color: rgba(0, 0, 0, 0.23);

    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 12px;
  }
`;
