export const PATIENT_INFO_FORM = {
  formName: 'Patient-info',
  patientName: {
    label: 'Patient name',
    name: 'name',
    placeholder: 'Enter patient name',
    rules: { required: 'patient_information.error_no_patient_name', maxLength: 128 },
  },
  birthDate: {
    label: 'Birthdate',
    name: 'birth',
    placeholder: 'YYYY/MM/DD',
    rules: { required: 'patient_information.error_no_birthdate', maxLength: 128 },
  },
  age: {
    label: 'Age',
    key: 'patient_information.age',
    name: 'age',
    placeholder: 'Enter age',
  },
  gender: { label: 'Gender', key: 'patient_information.gender', name: 'gender' },
  tryInDate: {
    label: 'Try in date',
    key: 'patient_information.try_in_date',
    name: 'tryInDate',
    placeholder: 'YYYY/MM/DD',
  },
  visitDate: {
    label: 'Visit date',
    key: 'patient_information.visit_date',
    name: 'visitDate',
    placeholder: 'YYYY/MM/DD',
  },
};

export const GENDER_OPTIONS = [
  { label: 'dialog_gender.male', value: 'MALE' },
  { label: 'dialog_gender.female', value: 'FEMALE' },
  { label: 'dialog_gender.other', value: 'OTHER' },
];

export const ORDER_TYPE_RANGE = [
  { label: 'dialog_order_type.design_&_manufacture', value: 'DESIGN_AND_MANUFACTURE' },
  { label: 'dialog_order_type.design_only', value: 'DESIGN' },
  { label: 'dialog_order_type.manufacture_only', value: 'MANUFACTURE' },
];
