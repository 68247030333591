import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { ToothContentRow } from 'shared/ui/toothProductDetail/ToothContentRow';

import { formattedPrice } from '../../utils/formatString';

export const ToothContent = ({
  title,
  totalPrice,
  rows,
}: {
  title: string | ReactNode;
  totalPrice?: number;
  rows: Array<{ title: string; label: string; additionalPrice?: number }>;
}) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', mb: '4px' }}>
        <Typography variant={'Subtitle_Semibold14'} sx={{ whiteSpace: 'noWrap', display: 'block' }}>
          {title}
        </Typography>
        {totalPrice && <Typography variant={'Subtitle_Semibold14'}>{formattedPrice(totalPrice, t)}</Typography>}
      </Stack>
      <Stack sx={{ gap: '2px' }}>
        {rows.map((row, index) => (
          <ToothContentRow key={index} title={row.title} label={row.label} additionalPrice={row.additionalPrice} />
        ))}
      </Stack>
    </Stack>
  );
};
