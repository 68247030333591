import { useMutation } from '@tanstack/react-query';
import ky from 'ky';

import {
  OrganizationCreateRequest,
  OrganizationPatchRequest,
  OrganizationUpdateCapacityRequest,
  ResponseDtoOrganization,
  ResponseDtoOrganizationResponse,
} from '../client';
import { queryClient } from '../shared/providers';
import { kyInstance } from './kyInstance';

export const getOrganizationUsingOrganizationId = (organizationId: string): Promise<ResponseDtoOrganizationResponse> =>
  kyInstance.get(`connect/organizations/${organizationId}`).json();

export const usePostOrganization = () =>
  useMutation<ResponseDtoOrganization, Error, OrganizationCreateRequest>({
    mutationFn: (data: OrganizationCreateRequest) => kyInstance.post('connect/organizations', { json: data }).json(),
  });

export const usePatchOrganizationCapacity = () =>
  useMutation({
    mutationFn: (capacity: OrganizationUpdateCapacityRequest) =>
      kyInstance.patch('connect/organizations/capacity', { json: capacity }).json(),
  });

export const usePatchOrganization = () =>
  useMutation({
    mutationFn: ({ data, organizationId }: { data: OrganizationPatchRequest; organizationId: string }) =>
      kyInstance.patch(`connect/organizations/${organizationId}`, { json: data }).json(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userData'] });
    },
  });

export const useGetOrganizationUsingPhoneNumber = () =>
  useMutation({
    mutationFn: (phoneNumber: string): Promise<ResponseDtoOrganizationResponse> =>
      kyInstance.get(`connect/organizations`, { searchParams: { phoneNumber } }).json(),
  });

// zipcode open api
interface ZipCodeResponseType {
  code: number;
  data: {
    address: string;
    city: string;
    fullAddress: string;
    pref: string;
    town: string;
  };
  message: string;
}
export const useGetAddressUsingZipCode = () =>
  useMutation({
    mutationFn: (zipCode: string): Promise<ZipCodeResponseType> =>
      ky.get('https://api.zipaddress.net/', { searchParams: { zipcode: zipCode } }).json(),
  });
