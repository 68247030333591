import { MULTIPLE_LOGIN_DETECTED, TOKEN_LOCKED } from '@imago-cloud/auth-client/dist/constants/errorCodes';
import ky, { KyInstance } from 'ky';

import { getAccessToken } from './auth';

const kyInstanceConfig = {
  prefixUrl: process.env.REACT_APP_MAIN_SERVER_URL as string,
  credentials: 'include' as RequestCredentials,
  timeout: 180_000,
  retry: 3,
};

class KyService {
  private accessToken: string = '';
  kyInstance: KyInstance;
  onAuthenticationFail: Function | null = null;
  onResponseSuccess: Function | null = null;

  constructor() {
    this.kyInstance = this.createKyInstance(process.env.REACT_APP_MAIN_SERVER_URL);
  }

  private createKyInstance(prefixUrl: string | undefined): KyInstance {
    return ky.create({
      ...kyInstanceConfig,
      prefixUrl: prefixUrl,
      hooks: {
        beforeRequest: [
          async (request) => {
            request.headers.set('Authorization', this.accessToken);
          },
        ],
        afterResponse: [
          async (request, options, response) => {
            if (response.status === 401) {
              await this.updateAccessToken();
              request.headers.set('Authorization', this.accessToken);
              return this.kyInstance(request);
            }
          },
        ],
      },
    });
  }

  public async updateAccessToken(): Promise<void> {
    try {
      const res = await getAccessToken();
      this.accessToken = `Bearer ${res.data.accessToken}`;
    } catch (e: any) {
      const errorData = await e.response.json();
      if (errorData.errorCode === MULTIPLE_LOGIN_DETECTED || errorData.errorCode === TOKEN_LOCKED) {
        this.onResponseSuccess?.({ data: errorData });
      } else {
        this.onAuthenticationFail && this.onAuthenticationFail();
      }
      throw e;
    }
  }

  public getKyInstance(): KyInstance {
    return this.kyInstance;
  }
}

export const kyServiceInstance = new KyService();
export const kyInstance = kyServiceInstance.getKyInstance();
