import MeshRenderingObject from '@imago/iwtk.js/dist/data/MeshRenderingObject';
import { MeshPhotographer } from '@imago/iwtk.js/dist/modules/MeshPhotographer';
import { makeMeshRenderingObjectFromFile } from '@imago/iwtk.js/dist/utils/rendering';

export const getThumbnailBlob = async (file: File) => {
  try {
    // await initializeIWTK(); // 만약 다른 곳에서 에러나면 실행..!
    const renderingObject = await makeMeshRenderingObjectFromFile(file);
    return await makeMeshThumbnail(renderingObject as MeshRenderingObject);
  } catch (err) {
    // log
    console.error(err);
  }
};

export const makeMeshThumbnail = async (
  renderingObjectList: MeshRenderingObject,
  // cameraInfo?: CameraOrientationOption,
) => {
  const photographer = new MeshPhotographer();
  // if (cameraInfo) photographer.setCameraOrientation(cameraInfo);
  photographer.addRenderingObject([renderingObjectList], { showTexture: true, showVertexColor: true });
  const blob = await photographer.capture();
  photographer.dispose();

  return blob;
};
