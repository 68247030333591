import { useTranslation } from 'react-i18next';
import { Button, Cicon, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ textAlign: 'center', width: '100vw', height: '100vh' }}>
      <Stack gap="40px" alignItems="center" justifyContent="center">
        <Stack gap="12px">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Cicon
              icon="Warning"
              svgProps={{
                width: 24,
                height: 24,
              }}
            />
            <Typography variant="Subtitle_Semibold18" sx={{ color: 'error.main' }}>
              {t('404_error.errror_tag')}
            </Typography>
          </Stack>
          <Typography variant="H36">{t('404_error.title')}</Typography>
          <Typography variant="Body18">
            {t('404_error.contents')
              .split('\n')
              .map((el) => (
                <Typography key={el} variant={'Body18'} sx={{ display: 'block' }}>
                  {el}
                </Typography>
              ))}
          </Typography>
        </Stack>
        <Button href="/" variant="contained" color="primary" sx={{ width: 'fit-content' }} size="48">
          {t('404_error.btn_go_home')}
        </Button>
      </Stack>
    </Stack>
  );
};
