import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { Dashboard, MyOrderList, NewOrder } from 'pages';
import {
  AsyncCostInfo,
  AsyncOperation,
  AsyncOrderDetail,
  AsyncOrganization,
  AsyncPaymentHistory,
  AsyncRefundHistory,
  AsyncSales,
} from 'shared/routes/AsyncRoute';
import PrivateRoute from 'shared/routes/PrivateRoute';
import { GlobalLayout } from 'shared/ui';

import { ErrorPage } from '../../pages/Error/ErrorPage';

export const createAppRouter = (role?: string) => {
  const isPartnerLab = role?.includes('connect:partnerlab');

  return createBrowserRouter([
    {
      path: ROUTES.index,
      element: <GlobalLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={`${ROUTES.ORDER_ROUTER.root}/${isPartnerLab ? ROUTES.ORDER_ROUTER.receivedOrderList : ROUTES.ORDER_ROUTER.placedOrderList}`}
            />
          ),
        },
        // '/Dashboard'
        {
          path: ROUTES.DASHBOARD_ROUTER.root,
          children: [
            {
              path: ROUTES.DASHBOARD_ROUTER.placedDashboard,
              element: (
                <PrivateRoute entryPermission={'connect:placed_order_dashboard'}>
                  <Dashboard />
                </PrivateRoute>
              ),
            },
            {
              path: ROUTES.DASHBOARD_ROUTER.receiveDashboard,
              element: (
                <PrivateRoute entryPermission={'connect:received_order_dashboard'}>
                  <Dashboard />
                </PrivateRoute>
              ),
            },
          ],
        },
        // '/order'
        {
          path: ROUTES.ORDER_ROUTER.root,
          children: [
            {
              path: ROUTES.ORDER_ROUTER.newOrder,
              element: (
                <PrivateRoute entryPermission={'connect:new_order'}>
                  <NewOrder />
                </PrivateRoute>
              ),
            },
            {
              path: ROUTES.ORDER_ROUTER.receivedOrderList,
              element: (
                <PrivateRoute entryPermission={'connect:received_order_list'}>
                  {/*<AsyncOrderList />*/}
                  <MyOrderList />
                </PrivateRoute>
              ),
            },
            {
              path: ROUTES.ORDER_ROUTER.placedOrderList,
              element: (
                <PrivateRoute entryPermission={'connect:placed_order_list'}>
                  {/*<AsyncOrderList />*/}
                  <MyOrderList />
                </PrivateRoute>
              ),
            },
            {
              //
              path: ROUTES.ORDER_ROUTER.placedOrderDetail,
              element: (
                <PrivateRoute entryPermission={'connect:placed_order_detail'}>
                  <AsyncOrderDetail />
                </PrivateRoute>
              ),
            },
            {
              path: ROUTES.ORDER_ROUTER.receiveOrderDetail,
              element: (
                <PrivateRoute entryPermission={'connect:received_order_detail'}>
                  <AsyncOrderDetail />
                </PrivateRoute>
              ),
            },
            {
              path: ROUTES.ORDER_ROUTER.remake,
              element: (
                <PrivateRoute entryPermission={'connect:new_order'}>
                  <NewOrder />
                </PrivateRoute>
              ),
            },
          ],
        },
        // '/my-info'
        {
          path: ROUTES.MY_ORGANIZATION_ROUTER.root,
          children: [
            { path: ROUTES.MY_ORGANIZATION_ROUTER.organization, element: <AsyncOrganization /> },
            {
              path: ROUTES.MY_ORGANIZATION_ROUTER.operation,
              element: (
                <PrivateRoute entryPermission={'connect:operation'}>
                  <AsyncOperation />
                </PrivateRoute>
              ),
            },
            {
              path: ROUTES.MY_ORGANIZATION_ROUTER.sales,
              element: (
                <PrivateRoute entryPermission={'connect:sales'}>
                  <AsyncSales />
                </PrivateRoute>
              ),
            },
            // {
            //   path: ROUTES.MY_ORGANIZATION_ROUTER.coupon,
            //   element: (
            //     <PrivateRoute entryPermission={'connect:coupon:read'}>
            //     <AsyncCoupon />
            //      </PrivateRoute>
            //   ),
            // },
            {
              path: ROUTES.MY_ORGANIZATION_ROUTER.paymentHistory,
              element: (
                // <PrivateRoute entryPermission={'dd'}>
                <AsyncPaymentHistory />
                // </PrivateRoute>
              ),
            },
            {
              path: ROUTES.MY_ORGANIZATION_ROUTER.refundHistory,
              element: (
                // <PrivateRoute entryPermission={''}>
                <AsyncRefundHistory />
                // </PrivateRoute>
              ),
            },
          ],
        },
        // '/cost-info'
        { path: ROUTES.COST_INFO_ROUTER.root, element: <AsyncCostInfo /> },
      ],
    },
  ]);
};
