import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@imago-cloud/design-system';
import { SxProps } from '@mui/material';

type RHFTextFieldPropType = TextFieldProps & {
  name: string;
  inputProps?: React.ComponentProps<typeof TextField>['inputProps'];
  rules?: any;
  isViewOnly?: boolean;
  disabled?: boolean;
  helperText?: string;
  sx?: SxProps;
};

export default function RHFTextField({
  name,
  inputProps,
  rules,
  isViewOnly = false,
  disabled,
  helperText,
  ...props
}: RHFTextFieldPropType) {
  const { control, watch, trigger } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
        <TextField
          name={name}
          value={isViewOnly && !watch(name) ? '-' : watch(name)}
          disabled={disabled || isViewOnly}
          onChange={(e) => {
            onChange(e);
            trigger(name);
          }}
          onBlur={(e) => {
            onChange(e.target.value.trim());
            trigger(name);
          }}
          inputProps={{ maxLength: 128, ...inputProps }}
          error={!!error}
          helperText={error ? error.message : helperText}
          sx={{ width: '100%' }}
          {...props}
        />
      )}
      rules={{ ...rules }}
    />
  );
}
