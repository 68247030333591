import { useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
export const EllipsisLabel = ({ label, delimiter = '.' }: { label: string; delimiter?: string }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [displayedLabel, setDisplayedLabel] = useState(label);

  useEffect(() => {
    const applyEllipsis = () => {
      if (!textRef.current) return;
      textRef.current.textContent = label;
      if (textRef.current.scrollWidth <= textRef.current.offsetWidth) return;

      const extensionIndex = label.lastIndexOf(delimiter);
      const extension = label.slice(extensionIndex);
      const namePart = label.slice(0, extensionIndex);

      const segmenter = new Intl.Segmenter('ko', { granularity: 'grapheme' });
      const graphemes = Array.from(segmenter.segment(namePart), ({ segment }) => segment);

      const lastFiveCharacters = graphemes.slice(-5).join('');
      const trimmedGraphemes = graphemes.slice(0, -5);

      let ellipsisInserted = trimmedGraphemes.join('') + '...' + lastFiveCharacters + extension;
      textRef.current.textContent = ellipsisInserted;
      while (textRef.current.scrollWidth > textRef.current.offsetWidth && trimmedGraphemes.length > 0) {
        trimmedGraphemes.pop();
        ellipsisInserted = trimmedGraphemes.join('') + '...' + lastFiveCharacters + extension;
        textRef.current.textContent = ellipsisInserted;
      }
      setDisplayedLabel(ellipsisInserted);
    };
    applyEllipsis();
    // eslint-disable-next-line
  }, [label]);

  return (
    <Stack direction="row" sx={{ width: '100%', whiteSpace: 'nowrap' }}>
      <Box ref={textRef} sx={{ overflow: 'hidden' }}>
        {displayedLabel}
      </Box>
    </Stack>
  );
};
