import { Alert, Button, IconButton, Ricon } from '@imago-cloud/design-system';
import { Snackbar, Stack } from '@mui/material';
import { useTopAlert } from 'store';

export default function TopAlert() {
  const { topAlert, DEFAULT_TOP_ALERT_DURATION, initTopAlert } = useTopAlert();
  const { open, title, description, severity, actionButtonText, onActionButtonClick, onClose } = topAlert;

  const handleTopAlertClose = (e: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    initTopAlert();
  };

  return (
    <Snackbar
      key={description}
      open={open}
      autoHideDuration={DEFAULT_TOP_ALERT_DURATION}
      onClose={handleTopAlertClose}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      disableWindowBlurListener
    >
      <Alert
        sx={{ width: '960px' }}
        severity={severity ?? 'info'}
        title={title}
        action={
          actionButtonText || onClose ? (
            <Stack direction="row" alignItems="center" gap="12px">
              {actionButtonText && (
                <Button color="white" variant="text" size="36" onClick={onActionButtonClick}>
                  {actionButtonText}
                </Button>
              )}
              {onClose && (
                <IconButton size="28" variant="transparent" color="white" onClick={onClose}>
                  <Ricon icon="ri-close-fill" />
                </IconButton>
              )}
            </Stack>
          ) : undefined
        }
      >
        {description}
      </Alert>
    </Snackbar>
  );
}
