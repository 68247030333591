import { useTranslation } from 'react-i18next';
import { theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const OrderNote = ({ note }: { note?: string }) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        borderRadius: '8px',
        border: `1px solid ${theme.palette.grey['500']}`,
        p: '8px 16px',
        minHeight: '38Px',
        maxHeight: '126px',
        overflowY: 'auto',
        wordBreak: 'break-word',
      }}
    >
      <Typography
        variant={'Body14'}
        sx={{ color: note ? theme.palette.text.secondary : theme.palette.action.disabled }}
      >
        {note ? note : t('patient_page_info.note_not_included')}
      </Typography>
    </Stack>
  );
};
