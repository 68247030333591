import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { usePermission } from 'shared/hooks/usePermission';
import { FormProvider } from 'shared/ui';
import { FileType, FileUploadWithPreview } from 'shared/ui/fileUpload/FileUploadWithPreview';
import { FILE_UPLOAD_GUIDELINES } from 'shared/ui/fileUpload/ImageFileUpload';
import { RHFTextField } from 'shared/ui/RHF';
import { useTopAlert } from 'store';

import { useUploadFile } from '../../api/fileQuery';
import { usePatchOrganization } from '../../api/organization';
import { useUserQuery } from '../../api/userQuery';
import { OrganizationPatchRequest } from '../../client';
import { useDialogue } from '../../shared/hooks/useDialogue';
import useOrganizationHookFormHandling, {
  OrganizationFormType,
} from '../../shared/hooks/useOrganizationHookFormHandling';
import {
  OrganizationAddressField,
  OrganizationNameField,
  OrganizationPhoneNumberField,
} from '../../shared/ui/organization/CommonField';
import { handleNumberFieldInputValidate } from '../../shared/utils/inputHandler';
import { UploadLimitDialogue } from './UploadLimitDialogue';

const fieldNameMap: Record<string, string> = {
  name: 'organization_name.title_organization_name',
  address: 'Organization_address.title',
  businessPhone: 'organization.subtitle_phone_number',
  corporateNumber: 'organization.dental_clinic_or_lab_code',
};

export default function Organization() {
  const { t } = useTranslation();
  const { setTopAlert } = useTopAlert();
  const {
    open,
    handleOpenDialogue: handleUploadLimitOpenDialogue,
    handleCloseDialogue: handleUploadLimitCloseDialogue,
  } = useDialogue();

  const { data: userData } = useUserQuery();
  const organization = userData.data?.organization;

  const { mutateAsync: patchOrganizationMutate, isPending: isPatchOrganizationPending } = usePatchOrganization();
  const { mutateAsync: fileUploadMutate, isPending: isFileUploadPending } = useUploadFile();

  const [file, setFile] = useState<FileType>(organization?.certificateOfIncorporation ?? null);
  const [isFileDirty, setIsFileDirty] = useState(false);

  const { accessiblePermissions } = usePermission();

  const { methods, onBlur, commonTextFieldValidateProps } = useOrganizationHookFormHandling();
  const {
    handleSubmit,
    reset,
    setError,
    watch,
    formState: { isValid, isDirty, dirtyFields },
  } = methods;

  const isOnlyReadPermission = !organization || !accessiblePermissions.includes('connect:organization:write');

  const getSortedChangedFieldNames = (fields: string[]): string[] => {
    const fieldNames = Object.keys(fieldNameMap);
    return fields
      .sort((a, b) => fieldNames.indexOf(a) - fieldNames.indexOf(b))
      .map((field) => t(fieldNameMap[field]) || field);
  };

  const onSubmit = async (formData: OrganizationFormType) => {
    const changedFields = getSortedChangedFieldNames(Object.keys(dirtyFields)).join(', ');
    const updatedData: Partial<OrganizationPatchRequest> = {};
    Object.keys(dirtyFields).forEach((key) => {
      updatedData[key as keyof OrganizationPatchRequest] = watch(key as keyof OrganizationFormType) as any;
    });
    try {
      const newFileId = file
        ? file instanceof File
          ? (await fileUploadMutate({ file })).data?.id ?? null
          : file.id
        : null;
      const requestData = {
        ...updatedData,
        certificateOfIncorporationFileId: newFileId,
      };
      await patchOrganizationMutate(
        { data: requestData, organizationId: organization?.id ?? '' },
        {
          onSuccess: () => {
            setTopAlert({
              open: true,
              variant: 'filled',
              severity: 'success',
              title: t('update_alert.title') as string,
              description: t('update_alert.subtitle', {
                variable1:
                  (changedFields ? `${changedFields}` : '') +
                  (isFileDirty ? `${changedFields ? ', ' : ''}${t('organization.cert_of_incorp')}` : ''),
              }) as string,
            });
            setIsFileDirty(false);
            reset(formData);
          },
        },
      );
    } catch (e: any) {
      const errorData = await e.response.json();
      if (errorData.errorCode === 'CONNECT:ORGANIZATION_PHONE_NUMBER_ALREADY_EXISTS') {
        setError('businessPhone', {
          message: 'Organization already exists',
        });
        return;
      }
    }
  };

  return (
    <>
      <FormProvider id={'organizationInfo'} methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" sx={{ gap: '60px', width: '400px', p: '40px 0 124px 40px' }}>
          {/*Organization name*/}
          <OrganizationNameField onBlur={onBlur} titleVariant={'H18'} disabled={isOnlyReadPermission} />
          {/*Organization address*/}
          <OrganizationAddressField onBlur={onBlur} disabled={isOnlyReadPermission} titleVariant={'H18'} />
          {/*Phone number*/}
          <OrganizationPhoneNumberField onBlur={onBlur} disabled={isOnlyReadPermission} titleVariant={'H18'} />
          {/*Organization code*/}
          <Stack direction="column" gap="20px">
            <Typography variant="H18">{t('organization.dental_clinic_or_lab_code')}</Typography>
            <RHFTextField
              name="corporateNumber"
              label={t('organization.enter_dental_clinic_or_lab_code') as string}
              label_visible={'none'}
              inputProps={{
                maxLength: 10,
                onInput: handleNumberFieldInputValidate,
              }}
              rules={{ required: t('organization.pls_enter_dental_clinic_or_lab_code') }}
              onBlur={onBlur}
              disabled={isOnlyReadPermission}
            />
          </Stack>

          <Stack direction="column" gap="20px">
            <Typography variant="H18">{t('certificate_of_incorporation.title')}</Typography>
            <FileUploadWithPreview
              file={file}
              setFile={(newFile) => {
                if (newFile instanceof File && newFile?.size > 10 * 1024 * 1024) {
                  handleUploadLimitOpenDialogue();
                } else {
                  setFile(newFile);
                  setIsFileDirty(true);
                }
              }}
              description={FILE_UPLOAD_GUIDELINES.ORGANIZATION}
              isDisabled={isOnlyReadPermission}
            />
          </Stack>

          <Button
            size="48"
            color="primary"
            variant="contained"
            type="submit"
            disabled={!(isValid && (isDirty || isFileDirty)) || isOnlyReadPermission}
            sx={{ minWidth: '108px' }}
          >
            {t('organization.btn_update')}
          </Button>
        </Stack>
      </FormProvider>

      <UploadLimitDialogue open={open} onClose={handleUploadLimitCloseDialogue} />
    </>
  );
}
