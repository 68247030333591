import { useTranslation } from 'react-i18next';
import { ColorProp, Label, theme, VariantProp } from '@imago-cloud/design-system';
import { OrderResponse } from 'client/types.gen';
import { STATUS_LABELS } from 'pages/MyOrderList/constants/menuOptions';

export type OrderStatusType = OrderResponse['status'] | 'REMAKE';

type StatusStyle = {
  variant?: VariantProp;
  color?: ColorProp | 'grey';
  sx: { [key: string]: string };
};

const STATUS_STYLE: Partial<Record<OrderStatusType, StatusStyle>> = {
  ORDERED: { variant: 'filled', color: 'primary', sx: { width: 'fit-content' } },
  REJECTED: { variant: 'filled', color: 'warning', sx: { width: 'fit-content' } },
  DESIGNING: { variant: 'ghost', color: 'success', sx: { width: 'fit-content' } },
  WAITING_DESIGN_CONFIRMATION: {
    sx: {
      width: 'fit-content',
      backgroundColor: `${theme.palette.success.dark} !important`,
      color: 'white !important',
    },
  },
  MANUFACTURING: {
    variant: 'ghost',
    color: 'success',
    sx: { width: 'fit-content', color: theme.palette.text.primary },
  },
  SHIPPED: {
    sx: { width: 'fit-content', backgroundColor: `${theme.palette.grey['900']} !important`, color: 'white !important' },
  },
  COMPLETED: { variant: 'filled', color: 'grey', sx: { width: 'fit-content' } },
  REMAKE: {
    variant: 'filled',
    sx: {
      width: 'fit-content',
      backgroundColor: 'white !important',
      color: `${theme.palette.grey['900']} !important`,
      border: `1px solid ${theme.palette.grey['900']}`,
    },
  },
  CANCELED: {
    variant: 'ghost',
    color: 'grey',
    sx: { color: `${theme.palette.grey['700']} !important` },
  },
};

export const OrderStatusLabel = ({ orderStatus }: { orderStatus: OrderStatusType }) => {
  const { t } = useTranslation();
  const statusStyle = STATUS_STYLE[orderStatus] || {
    variant: 'filled',
    color: 'primary',
    // sx: { width: 'fit-content' },
  };

  const label = t(orderStatus === 'REMAKE' ? 'order_list.remake' : (STATUS_LABELS[orderStatus] as string));

  return <Label {...statusStyle}>{label}</Label>;
};
