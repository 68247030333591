import { ComponentType, ReactNode } from 'react';
import { atom } from 'recoil';
import { ButtonColorType, DialogTypeType, TriggerType } from 'shared/types/commonDialog';

export type DialogContentType = {
  title: string;
  isTitleAlert?: boolean;
  content: string;
  color?: ButtonColorType;
  trigger: TriggerType;
};

export type DialogType = {
  id: string;
  type: DialogTypeType;
  priority?: number;
  component?: ComponentType | ReactNode;
  dialogContent?: DialogContentType;
};

export const dialogState = atom<DialogType[]>({
  key: 'dialogManagerAtom',
  default: [],
});
