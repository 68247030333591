export const REFRESH_TOKEN_API = 'account/auth/token/refresh';

export const LOG_OUT_API = 'account/logout';

const USER_ACTION_PATHS = {
  designConfirm: (orderId: string) => `${orderId}/design/approve`,
  designReject: (orderId: string) => `${orderId}/design/reject`,
};
const LAB_ACTION_PATHS = {
  orderReceive: (orderId: string) => `${orderId}/receive`,
  orderReject: (orderId: string) => `${orderId}/reject`,
  tracking: (orderId: string) => `${orderId}/tracking`,
  designUpload: (orderId: string) => `${orderId}/design`,
  confirmation: (orderId: string) => `${orderId}/confirmation`, // user의 design confirm 상태를 변경
  complete: (orderId: string) => `${orderId}/complete`,
};

export const ACTION_PATHS = {
  ...USER_ACTION_PATHS,
  ...LAB_ACTION_PATHS,
  cancel: (orderId: string) => `${orderId}/cancel`,
};

export const ORDER_PATHS = {
  orderer: 'connect/orders',
  receiver: 'connect/orders/partner-lab',
};

export const ORDER_DETAIL_PATHS = {
  orderer: (orderId: string) => `${ORDER_PATHS.orderer}/${orderId}`,
  receiver: (orderId: string) => `${ORDER_PATHS.receiver}/${orderId}`,
};
