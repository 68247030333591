import { ReactNode } from 'react';
import { theme } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const CardContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      sx={{
        p: '16px 20px',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.grey['200']}`,
        gap: '12px',
      }}
    >
      {children}
    </Stack>
  );
};
