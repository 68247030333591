export const LANGUAGE_MENU_ITEM = [
  { label: 'English', value: 'en' },
  { label: '日本語', value: 'ja' },
  { label: 'Español', value: 'es' },
  { label: 'Português', value: 'pt' },
  { label: 'Français', value: 'fr' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
  { label: '한국어', value: 'ko' },
  { label: '中文', value: 'zh_cn' },
];
