import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { ObjectId } from 'client/types.gen';
import { CommonWrapper } from 'pages/OrderDetail/components/ActionComponents/CommonWrapper';
import { FormProvider } from 'shared/ui';
import { FileType, FileUploadWithPreview } from 'shared/ui/fileUpload/FileUploadWithPreview';
import { FILE_UPLOAD_GUIDELINES } from 'shared/ui/fileUpload/ImageFileUpload';

import { useUploadFile } from '../../../../api/fileQuery';
import { useOrderStatusChangeMutation } from '../../../../api/orderQuery';
import RHFTextField from '../../../../shared/ui/RHF/RHFTextField';

type OrderTrackingRequestType = {
  trackingNumber: string;
  shippingCarrier: string;
  shippingLabelFile: {
    fileId: ObjectId;
    createdAt: Date;
  };
};
export const ManufacturedFileUpload = ({ orderId }: { orderId: string }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<FileType>(null);

  const { mutateAsync: statusChangeMutate } = useOrderStatusChangeMutation(orderId);

  const { mutateAsync: fileUploadMutate } = useUploadFile();
  const methods = useForm<OrderTrackingRequestType>({
    defaultValues: {
      trackingNumber: '',
      shippingCarrier: '',
    },
  });
  const { handleSubmit, getValues } = methods;

  const onSubmit = async (submitData: OrderTrackingRequestType) => {
    try {
      setIsLoading(true);
      let shippingLabelFile;

      if (file) {
        const { data } = await fileUploadMutate({ file: file as File });
        shippingLabelFile = { fileId: data?.id, createdAt: data?.createdAt ?? new Date() };
      }
      await statusChangeMutate({ status: 'tracking', body: { ...submitData, shippingLabelFile } });
    } catch (error) {
      console.error('File upload failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider id={'manufacturedFileUpload'} methods={methods}>
      <CommonWrapper
        buttonText={t('patient_page_status.btn_upload') as string}
        buttonDisabled={!(getValues('trackingNumber') && getValues('shippingCarrier'))}
        onButtonClick={handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <Stack sx={{ gap: '20px' }}>
          <FileUploadWithPreview file={file} setFile={setFile} description={FILE_UPLOAD_GUIDELINES.MANUFACTURING} />
          <Stack sx={{ gap: '16px' }}>
            <RHFTextField
              name={'shippingCarrier'}
              label={t('patient_page_status.textfield_shipping_carrier') as string}
              required
              label_visible={'none'}
            />
            <RHFTextField
              name={'trackingNumber'}
              required
              sx={{ width: '100%' }}
              label={t('patient_page_status.textfield_tracking_number') as string}
              label_visible={'none'}
            />
          </Stack>
        </Stack>
      </CommonWrapper>
    </FormProvider>
  );
};
