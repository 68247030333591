import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { Dayjs } from 'dayjs';
import { SubListHeaderDetailType } from 'shared/types/subHeader';
import { NoticeTooltip } from 'shared/ui/NoticeTooltip/NoticeTooltip';

import DateRangePicker from '../../../shared/ui/DateRangePicker/DateRangePicker';
import { getCurrentOrderStatus } from '../../../shared/utils/getOrderStatusConfig';
import { useOrderState } from '../atoms/useOrderState';
import { OrderSearchTargetType, SEARCH_MENU_ITEMS } from '../constants/menuOptions';
import { SearchField } from './filter/SearchField';
import { SearchTarget } from './filter/SearchTarget';

const SubHeader = ({ subHeaderInfo }: { subHeaderInfo: SubListHeaderDetailType }) => {
  const { t } = useTranslation();
  const { orderQueryParams, updateOrderQueryParams } = useOrderState();
  const currentOrderStatus = getCurrentOrderStatus();

  const { title, titleNotice } = subHeaderInfo;

  const handleSearchTargetChange = (event: React.ChangeEvent<{ value: string }>) => {
    const selectedValue = event.target.value as OrderSearchTargetType;
    updateOrderQueryParams({ searchFilterType: selectedValue });
  };

  const onChangeDate = (value: { startDate: Dayjs | null; endDate: Dayjs | null }) => {
    updateOrderQueryParams({ startDate: value.startDate?.toISOString(), endDate: value.endDate?.toISOString() });
  };

  const onChangeSearch = (value: string) => updateOrderQueryParams({ searchKeyword: value });

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        width: '100%',
        height: '140px',
        p: '40px 40px 52px 48px',
        justifyContent: 'space-between',
      }}
    >
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
        <Typography variant={'H36'}>{t(title)}</Typography>
        {titleNotice && <NoticeTooltip description={titleNotice} />}
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: '12px' }}>
        <DateRangePicker handleDateChange={onChangeDate} />
        {currentOrderStatus === 'receiver' && (
          <SearchTarget
            value={(orderQueryParams?.searchFilterType || SEARCH_MENU_ITEMS[0].value) as string}
            options={SEARCH_MENU_ITEMS}
            onChange={handleSearchTargetChange}
          />
        )}
        <SearchField value={orderQueryParams?.searchKeyword || ''} onUpdateQuery={onChangeSearch} />
      </Stack>
    </Stack>
  );
};

export default SubHeader;
