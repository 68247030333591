import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IMenuItem, IMenuItemChildren, INDEX_ROUTERS, ORDER_DETAIL_ROUTES } from 'constants/menuItem';

export type UseNavList = ReturnType<typeof useNavList>;

export default function useNavList(list: IMenuItem | IMenuItemChildren) {
  const { pathname } = useLocation();
  const { children, path, index } = { children: undefined, index: false, ...list };
  const hasChildren = !!children;
  const active = getActive(path, pathname, hasChildren);
  const childActive = getChildActive(path, pathname, index, list as IMenuItemChildren);
  const [open, setOpen] = useState(active);

  useEffect(() => {
    setOpen(active);
  }, [active]);

  return { active, childActive, open, setOpen, hasChildren };
}

const getParent = (url: string) => url.split('/')[1];
export const getActive = (path: string, pathname: string, hasChildren: boolean) => {
  if (getParent(path) === getParent(pathname) && hasChildren) return true;
  if (getParent(path) === getParent(pathname) && INDEX_ROUTERS.includes(pathname)) return true;
  if (path === pathname) return true;
  return false;
};
const getChildActive = (path: string, pathname: string, index: boolean, list: IMenuItemChildren) => {
  const isDetailPageMatch = () => {
    if (!pathname || !list.path) return false;
    const detailPathname = pathname.split('/')[2]?.split('-')[0];
    const parentPathname = list.path.split('/')[2]?.split('-')[0];
    return (
      ORDER_DETAIL_ROUTES.some((route) => pathname.includes(route)) &&
      detailPathname === parentPathname &&
      getParent(list.path).includes('order')
    );
  };

  const isIndexPage = pathname.split('/').length === 2;
  if (path === pathname) return true;
  if (getParent(path) === getParent(pathname) && index && isIndexPage) return true;
  if (isDetailPageMatch()) return true;

  return false;
};
