//유동적으로 변한다 Action area */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { ListItemIcon, ListItemText, Stack } from '@mui/material';
import { OrderDetailResponse } from 'client/types.gen';
import { STATUS_LABELS } from 'pages/MyOrderList/constants/menuOptions';
import { useActionAreaConfig } from 'pages/MyOrderList/hooks/useActionAreaConfig';
import { getCurrentOrderStatus } from 'shared/utils/getOrderStatusConfig';
import useGlobalDialog from 'store/globalDialog/useGlobalDialog';

export const ActionArea = ({ orderDetail }: { orderDetail: OrderDetailResponse }) => {
  const { t } = useTranslation();
  const currentOrderStatus = getCurrentOrderStatus();

  const { status, id, canBeRemade, designManufactureCombination } = orderDetail;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const statusConfig = useActionAreaConfig(currentOrderStatus, orderDetail);
  const { addDialog, removeCurrent, currentDialogInfo } = useGlobalDialog();

  const SubUiComponent = statusConfig?.subUi;

  const isDesignOnly = designManufactureCombination === 'DESIGN';

  return (
    <Stack sx={{ backgroundColor: theme.palette.background.white }}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', p: '24px', pb: '0px' }}>
        <Typography variant={'H18'}>{t(STATUS_LABELS[status] ?? '')}</Typography>

        {!isDesignOnly && statusConfig?.menuOptions && (
          <>
            <IconButton size={'32'} color={'grey800'} variant={'transparent'} onClick={handleMoreButtonClick}>
              <Ricon icon={'ri-more-fill'} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              // PaperProps={{ sx: { width: '240px', mt: '4px', p: '8px', borderRadius: '8px' } }}
            >
              {statusConfig.menuOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    option.action(addDialog);
                    handleMenuClose();
                  }}
                  disabled={option.label === 'Remake' && !canBeRemade}
                >
                  {option.icon && (
                    <ListItemIcon>
                      <Ricon icon={option.icon} svgProps={{ width: 24, height: 24, fill: theme.palette.grey[700] }} />
                    </ListItemIcon>
                  )}
                  <ListItemText>{option.label}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Stack>
      {SubUiComponent && <SubUiComponent orderId={id ?? ''} addDialog={addDialog} />}
    </Stack>
  );
};
