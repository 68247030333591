import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Order } from 'client/types.gen';
import { CardContainer } from 'shared/ui/Global/CardContainer';
import { ToothContent } from 'shared/ui/toothProductDetail/ToothContent';
import { FDIToPalmer } from 'shared/utils/convertToPalmer';

import { groupAndSortOrderItems } from '../../../utils/groupByProsthesis';

export const ToothCards = ({ orderItems }: { orderItems: Order['orderItems'] }) => {
  const groupOrderItems = groupAndSortOrderItems(orderItems);
  return groupOrderItems.map(([groupId, orderItems]) => (
    <CardContainer key={groupId}>
      {orderItems.map((orderItem) => (
        <ToothOrderDetails key={orderItem.toothNumber} order={orderItem} />
      ))}
    </CardContainer>
  ));
};

const ToothOrderDetails = ({ order }: { order: Order['orderItems'][0] }) => {
  const { t } = useTranslation();
  const {
    toothNumber,
    amount,
    shadeType,
    hook,
    premiumFinishingPrice,
    scanBodyDetail,
    implantDetail,
    hookPrice,
    method,
    material,
    shape,
    prosthesis,
  } = order;

  const productInfo = [
    { title: t('patient_page_info.title_product_type'), label: prosthesis },
    {
      title: t('patient_page_info.title_product_option'),
      label: `${method} - ${material} - ${shape}`,
    },
    {
      title: t('patient_page_info.title_shade'),
      label: shadeType === 'ETC' ? t('dialog_select_shade.etc') : shadeType.replace('_', '.'),
    },
    {
      title: t('patient_page_info.subtitle_hook'),
      label: t(`product_list.${hook.toLowerCase()}`),
      additionalPrice: hookPrice,
    },
    {
      title: t('patient_page_info.subtitle_finish'),
      label: premiumFinishingPrice
        ? t('finish_option.table_item_title_premium')
        : t('finish_option.table_item_title_basic'),
      additionalPrice: premiumFinishingPrice,
    },
    scanBodyDetail && { title: t('patient_page_info.title_scanbody'), label: scanBodyDetail },
    implantDetail && { title: t('patient_page_info.title_implant_information'), label: implantDetail },
  ].filter((row): row is { title: string; label: string; additionalPrice?: number } => !!row);

  return (
    <ToothContent
      title={
        <Trans i18nKey={FDIToPalmer[toothNumber]?.short} values={{ variable: FDIToPalmer[toothNumber]?.num ?? '##' }} />
      }
      totalPrice={amount}
      rows={productInfo}
    />
  );
};
