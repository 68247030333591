import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import en from './locales/en/no_filename.json';
import ja from './locales/ja/no_filename.json';

export const resources = {
  en: { lang: en },
  ja: { lang: ja },
} as const;
export const defaultNS = 'lang';
i18next.use(initReactI18next).init({
  resources,
  defaultNS,
  fallbackLng: 'ja',
  interpolation: {
    escapeValue: false,
  },
});
