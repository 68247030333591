import { useTranslation } from 'react-i18next';
import { DentalNotationOptionValue } from '@imago-cloud/front-module/lib/esm/imago-in-cad-nesting/constants/common';
import { FDINumber, toADANumber } from '@imago-deep-cad/tooth-numbering-system';
import { FDIToPalmer } from 'shared/utils/convertToPalmer';
import { capitalizeFirstLetter } from 'shared/utils/formatString';

import { OrderItem, OrderItemResponse } from '../../client';

export type ToothProsthesisLabel = { label: string; orderItemId: string };
type ProsthesisGroupById = { [key: string]: ToothProsthesisLabel };

export const useUserToothNumbering = () => {
  const { t } = useTranslation();
  const setting = {
    dental_notation: 'FDI_SYSTEM',
  };
  const getCustomizedToothNumber = (fdi: FDINumber) =>
    setting?.dental_notation === DentalNotationOptionValue.FDI_SYSTEM ? fdi : toADANumber(fdi);

  const sortOrderItems = (groups: { label: string; orderItemId: string }[]) => {
    const quadrantOrder = ['UR', 'UL', 'LL', 'LR'];

    const extractToothInfo = (label: string) => {
      const match = label.match(/(UR|UL|LL|LR)\s*(\d+)/);
      if (match) {
        const quadrant = match[1];
        const number = parseInt(match[2], 10);
        return { quadrant, number };
      }
      return { quadrant: '', number: 0 };
    };

    return groups.sort((a, b) => {
      const { quadrant: quadrantA, number: numberA } = extractToothInfo(a.label);
      const { quadrant: quadrantB, number: numberB } = extractToothInfo(b.label);

      const quadrantComparison = quadrantOrder.indexOf(quadrantA) - quadrantOrder.indexOf(quadrantB);

      if (quadrantComparison === 0) return numberA - numberB;
      return quadrantComparison;
    });
  };

  const processOrderItems = (orderItems: OrderItem[] | OrderItemResponse[], groupByProsthesis = false) => {
    const bridgeGroup: ProsthesisGroupById = {};
    const prosthesisGroup: ProsthesisGroupById = {};
    const individualGroup: ProsthesisGroupById = {};

    orderItems.forEach((order) => {
      const { prosthesis, toothNumber, groupId, groupType } = order;
      const toothLabel = t(FDIToPalmer[toothNumber as number]?.short, {
        variable: FDIToPalmer[toothNumber as number]?.num ?? '##',
      });
      if (!toothLabel || !prosthesis) return;

      if (groupType === 'BRIDGE' && groupId) {
        if (!bridgeGroup[groupId]) {
          // 새로운 그룹을 생성하고 첫 치아 레이블과 orderItemId를 할당
          bridgeGroup[groupId] = {
            label: `Bridge ${toothLabel}`,
            orderItemId: ('orderItemId' in order && order.orderItemId) || '', // 그룹의 첫 번째 항목의 orderItemId 사용
          };
        } else {
          // 이미 존재하는 그룹에 레이블을 이어붙임
          bridgeGroup[groupId].label += `-${toothLabel}`;
        }
      } else if (groupByProsthesis) {
        const prosthesisLabel = capitalizeFirstLetter(prosthesis);
        if (!prosthesisGroup[prosthesisLabel]) {
          prosthesisGroup[prosthesisLabel] = { label: `${prosthesisLabel} ${toothLabel}`, orderItemId: '' };
        } else prosthesisGroup[prosthesisLabel].label += `,${toothLabel}`;
      } else {
        const prosthesisLabel = capitalizeFirstLetter(prosthesis);
        individualGroup[toothLabel] = {
          label: `${prosthesisLabel} ${toothLabel}`,
          orderItemId: ('orderItemId' in order && order.orderItemId) || '',
        };
      }
    });

    return { bridgeGroup, prosthesisGroup, individualGroup };
  };

  const mergeGroups = (groupA: ProsthesisGroupById, groupB: ProsthesisGroupById) => [
    ...Object.values(groupA),
    ...Object.values(groupB),
  ];

  const getGroupedProsthesisLabels = (orderItems: OrderItem[] | OrderItemResponse[]) => {
    const { bridgeGroup, prosthesisGroup } = processOrderItems(orderItems, true);
    const mergedToothLabels = mergeGroups(prosthesisGroup, bridgeGroup);

    return sortOrderItems(mergedToothLabels).map((item) => item.label);
  };

  const getAllToothLabels = (orderItems: OrderItem[] | OrderItemResponse[]) => {
    const { bridgeGroup, individualGroup } = processOrderItems(orderItems);

    const mergedToothLabels = mergeGroups(individualGroup, bridgeGroup);
    return sortOrderItems(mergedToothLabels);
  };

  return { getCustomizedToothNumber, getGroupedProsthesisLabels, getAllToothLabels };
};
