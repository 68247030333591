import React from 'react';
import { Stack } from '@mui/material';
import { NewProductDisplayItemType } from 'pages/NewOrder/components/Prescription/Prescription';

import useNewOrderDataContext from '../../context/useNewOrderDataContext';
import NewProductItem, { NewProductItemProp } from './NewProductItem';

type NewProductItemListProp = {
  onOpenDialogue: () => void;
  onEditClick: (clickedToothNumber: number) => void;
};

export default function NewProductItemList({ onOpenDialogue, onEditClick }: NewProductItemListProp) {
  const {
    orderData: { orderItems },
  } = useNewOrderDataContext();

  // TODO: UI 관련 이외 Item 내부 함수 이쪽으로 다 이동.

  return (
    <Stack justifyContent="flex-start" gap="12px" sx={{ padding: '28px 18px' }}>
      {orderItems
        .sort((a, b) => a.toothNumber - b.toothNumber)
        // 우선 개별 prop 넘깁니다~
        .map((item) => (
          <NewProductItem key={item.toothNumber} item={item as NewProductDisplayItemType} onEditClick={onEditClick} />
        ))}
    </Stack>
  );
}
