import { IMenuItemChildren } from 'constants/menuItem';
import { useNavList } from 'shared/hooks';

import NavItem from './NavItem';

interface NavListSubProps {
  item: IMenuItemChildren;
}

export default function NavListSub({ item }: Readonly<NavListSubProps>) {
  const { childActive } = useNavList(item);

  return <NavItem item={item} active={childActive} isSub />;
}
