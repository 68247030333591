import { useEffect, useRef, useState } from 'react';

export const useVisibleToothLabels = (teethLabelsByProthesis: string[], maxWidth: number, labelGap: number) => {
  const labelRefs = useRef<(HTMLSpanElement | null)[]>([]);

  const [visibleLabels, setVisibleLabels] = useState<string[]>([]);

  const [isActiveViewMoreButton, setIsActiveViewMoreButton] = useState(false);

  const joinedLabels = teethLabelsByProthesis.join(' • ');

  useEffect(() => {
    const calculateTotalWidth = () => {
      let total = 0;
      const visible: string[] = [];

      for (let index = 0; index < teethLabelsByProthesis.length; index++) {
        const labelRef = labelRefs.current[index];
        if (labelRef) {
          const labelWidth =
            labelRef.getBoundingClientRect().width + (index !== teethLabelsByProthesis.length - 1 ? labelGap : 0);
          if (total + labelWidth <= maxWidth) {
            total += labelWidth;
            visible.push(teethLabelsByProthesis[index]);
          } else {
            setIsActiveViewMoreButton(true);
            if (index === 0) {
              visible.push(teethLabelsByProthesis[index]);
            }
            break;
          }
        }
      }
      setVisibleLabels(visible);
    };
    if (labelRefs.current.length === teethLabelsByProthesis.length) {
      calculateTotalWidth();
    }
  }, [teethLabelsByProthesis]);

  return { labelRefs, joinedLabels, visibleLabels, isActiveViewMoreButton };
};
