//Common -> Rx, Data box Timeline
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderDetailResponse } from 'client/types.gen';
import { Databox } from 'pages/OrderDetail/components/Databox';
import { RxContent } from 'pages/OrderDetail/components/RxContent';
import { Timeline } from 'pages/OrderDetail/components/Timeline';

const TABS = [
  { key: 'rx', label: 'patient_page_info.tab_rx' },
  { key: 'dataBox', label: 'patient_page_info.tab_data_box' },
  { key: 'timeline', label: 'patient_page_info.tab_timeline' },
];

export const InformationArea = ({ orderDetail }: { orderDetail: OrderDetailResponse }) => {
  const { t } = useTranslation();
  const [focussedTab, setFocussedTab] = useState<string>('rx');

  const handleTabChange = (tab: string) => {
    setFocussedTab(tab);
  };

  const renderTabContent = () => {
    switch (focussedTab) {
      case 'rx':
        return <RxContent orderDetail={orderDetail} />;
      case 'dataBox':
        return (
          <Databox
            designedFilesResponse={orderDetail.designedFilesResponse}
            patientFilesResponse={orderDetail.patientFilesResponse}
            otherFilesResponse={orderDetail.otherFilesResponse}
          />
        );
      case 'timeline':
        return <Timeline timeline={orderDetail.timeline} />;
      default:
        return <RxContent orderDetail={orderDetail} />;
    }
  };

  return (
    <Stack>
      <Stack flexDirection={'row'} sx={{ justifyContent: 'center' }}>
        {TABS.map((tab) => (
          <Tab
            key={tab.key}
            label={t(tab.label) as string}
            isActive={focussedTab === tab.key}
            onClick={() => handleTabChange(tab.key)}
          />
        ))}
      </Stack>
      <Stack sx={{ p: '20px 24px 24px 24px' }}>{renderTabContent()}</Stack>
    </Stack>
  );
};

const Tab = ({ label, isActive, onClick }: { label: string; isActive: boolean; onClick: () => void }) => (
  <Stack sx={getTabStyles(isActive)} onClick={onClick}>
    <Typography variant={'Subtitle_Semibold18'} sx={{ alignSelf: 'center' }}>
      {label}
    </Typography>
  </Stack>
);

const getTabStyles = (isActive: boolean) => ({
  width: '100%',
  height: '48px',
  p: '10px 30px',
  color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
  background: isActive ? theme.palette.background.white : theme.palette.grey['200'],
  border: `1px solid #DEE1E5`,
  borderBottom: isActive ? 'none' : `1px solid #DEE1E5`,
  borderLeft: 'none',
  cursor: 'pointer',
});
