import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Box, Stack, styled, TextareaAutosize } from '@mui/material';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';

export default function Note() {
  const { t } = useTranslation();

  const { orderData, setOrderData } = useNewOrderDataContext();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // TODO: throttle
    setOrderData((prev) => ({ ...prev, note: e.target.value }));
  };

  if (!isOpen) {
    return (
      <Button
        onClick={() => setIsOpen(true)}
        startIcon={<Ricon icon="ri-file-list-2-line" />}
        size="36"
        color="grey"
        variant="outlined"
        sx={{ position: 'absolute', top: '20px', right: '20px' }}
      >
        {t('prescription_field.btn_note')}
      </Button>
    );
  }

  return (
    <Stack
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        position: 'absolute',
        top: '20px',
        right: '20px',
        width: '320px',
        minHeight: '176px',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 8px 24px -4px #9199A33D',
      }}
      alignItems="flex-start"
    >
      {/*<Paper></Paper>*/}
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%', padding: '12px 16px' }}
      >
        <Typography variant="Subtitle_Semibold16">{t('prescription_field.btn_note')}</Typography>
        <IconButton onClick={() => setIsOpen(false)} size="18" color="grey900" icon_size="small" variant="transparent2">
          <Ricon icon="ri-close-line" />
        </IconButton>
      </Stack>

      <Box sx={{ width: '100%', height: '100%', padding: '0 16px 20px' }}>
        <Textarea
          value={orderData.note}
          onChange={handleNoteChange}
          minRows={5}
          maxRows={5}
          placeholder={t('dialog_note.add_notes') as string}
          // defaultValue=""
        />
      </Box>
    </Stack>
  );
}

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 8px;
    color: grey[900]};
    background:  ${theme.palette.common.white};
    border: 1px solid ${theme.palette.divider};

    &:hover {
      border-color: ${theme.palette.primary.light};
    }

    &:focus {
      border-color: ${theme.palette.primary.light};
      box-shadow: 0 0 0 2px ${theme.palette.primary.light};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
