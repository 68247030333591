import { IDialogueInfo } from 'pages/NewOrder/components/Submit/SubmitDialogue/OrderSubmitDialogue';

export const ORDER_SUBMIT_SUCCESSFULLY: IDialogueInfo = {
  title: 'dialg_order_submitted_successfully.title',
  content: 'dialg_order_submitted_successfully.body',
  action: 'dialg_order_submitted_successfully.btn_go_to_mainpage',
};

export const ORDER_SUBMIT_FAILED: IDialogueInfo = {
  title: 'dialog_order_submission_failed.title',
  content: 'dialog_order_submission_failed.body',
  action: 'dialog_order_submission_failed.btn_ok',
  type: 'failed',
};
