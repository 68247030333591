import React from 'react';
import { IconButton, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import humanizeString from 'humanize-string';

interface CustomChannelListHeaderProps {
  orderType: string;
  onChatToggleButtonClick: () => void;
}

export default function CustomChannelListHeader({ orderType, onChatToggleButtonClick }: CustomChannelListHeaderProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: '24px 24px',
        height: '64px',
        border: '1px solid rgba(0,0,0,0.12)',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
      }}
    >
      <Stack direction="column">
        <Typography variant="H18">Chat</Typography>
        <Typography variant="Subtitle_Semibold14" color={theme.palette.text.thirdary}>
          {humanizeString(orderType)}
        </Typography>
      </Stack>
      <IconButton
        onClick={() => {
          onChatToggleButtonClick();
        }}
        size="32"
        icon_size="small"
        color="grey900"
        variant="transparent"
      >
        <Ricon icon="ri-close-line" svgProps={{ width: '24px', height: '24px' }} />
      </IconButton>
    </Stack>
  );
}
