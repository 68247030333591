import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Snackbar } from '@imago-cloud/design-system';
import { useBottomSnackbar } from 'store';

export default function BottomSnackbar() {
  const { pathname } = useLocation();

  const { initBottomSnackbar, bottomSnackbar, DEFAULT_BOTTOM_SNACKBAR_DURATION } = useBottomSnackbar();
  const { open, message, actionButtonText, onActionButtonClick } = bottomSnackbar;

  useEffect(() => {
    initBottomSnackbar();
  }, [pathname]);

  return (
    <Snackbar
      open={open}
      message={message}
      onClose={initBottomSnackbar}
      autoHideDuration={DEFAULT_BOTTOM_SNACKBAR_DURATION}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      action={actionButtonText}
      onAction={onActionButtonClick}
      sx={{
        maxWidth: '100%',
        '.MuiPaper-root': {
          maxWidth: '100%',
          justifyContent: 'space-between',
          flexWrap: 'initial',
        },

        '.MuiSnackbarContent-message': {
          whiteSpace: 'pre',
          overflow: 'hidden',
          '> p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      }}
    />
  );
}
