import { LOG_OUT_API, REFRESH_TOKEN_API } from 'shared/constants/apiUrl';

import { kyAuthInstance } from './KyAuthInstance';

export const getAccessToken = async (): Promise<{
  timestamp: string;
  success: boolean;
  message: string;
  data: { accessToken: string };
}> => {
  return await kyAuthInstance.patch(REFRESH_TOKEN_API).json();
};

export const postLogout = async () => {
  const response: { success: string; message: 'string' } = await kyAuthInstance.post(LOG_OUT_API).json();
  if (response.success) {
    window.location.replace(
      `${process.env.REACT_APP_ACCOUNTS_CLIENT_URL!}/user?redirect_url=${process.env.REACT_APP_CONNECT_CLIENT_URL}`,
    );
  }
  return response;
};
