import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrderDetailResponse, OrderResponse, ResponseDtoListOrderRemakeHistory } from 'client/types.gen';
import { ROUTES } from 'constants/routes';
import ActionComponent, { Rating } from 'pages/OrderDetail/components/ActionComponent';
import { ORDER_COMPLETED, ORDER_DETAIL_CANCEL, REMAKE_LIMITATIONS } from 'pages/OrderDetail/constants/dialog';
import { OrderRole } from 'shared/types/role';

import { kyInstance } from '../../../api/kyInstance';
import { useOrderStatusChangeMutation } from '../../../api/orderQuery';
import { CommonWrapper } from '../../OrderDetail/components/ActionComponents/CommonWrapper';

type ManufacturingConfig = (type: string) => StatusConfig;

type StatusConfig = {
  subUi: React.FC<any>;
  menuOptions?: Array<{
    label: string;
    icon?: string;
    action: (addDialog: any) => void;
  }>;
};

type SubUiComponentType = {
  orderId: string;
  orderItems: OrderResponse['orderItems'];
  designManufactureCombination: OrderDetailResponse['designManufactureCombination'];
};

export const useActionAreaConfig = (role: OrderRole, orderDetail: OrderDetailResponse): StatusConfig | undefined => {
  const { t } = useTranslation();

  const {
    id: orderId,
    status,
    designConfirmProcessIncluded,
    designManufactureCombination,
    designedFilesResponse,
    patient: { name },
    tracking,
    orderItems,
    originalOrderId,
  } = orderDetail;
  const { mutateAsync } = useOrderStatusChangeMutation(orderId);
  const navigate = useNavigate();
  const createCancelMenuOption = () => ({
    label: t('patient_page_status.btn_cancel_order'),
    icon: 'ri-close-circle-fill',
    action: (addDialog: any) => {
      const onActionClick = async () => {
        await mutateAsync({ status: 'cancel' });
      };
      const dialogInfo = ORDER_DETAIL_CANCEL(onActionClick);
      addDialog(dialogInfo);
    },
  });

  const noActionTextConfig: StatusConfig = {
    subUi: () => <ActionComponent.NoActionText />,
  };

  const actionAreaConfig: (
    orderId: string,
  ) => Record<'orderer' | 'receiver', Partial<Record<OrderResponse['status'], StatusConfig | ManufacturingConfig>>> = (
    orderId,
  ) => {
    return {
      orderer: {
        ORDERED: {
          ...noActionTextConfig,
          menuOptions: [createCancelMenuOption()],
        },
        DESIGNING: noActionTextConfig,
        WAITING_DESIGN_CONFIRMATION: {
          subUi: () => <ActionComponent.ConfirmRequired orderId={orderId} role={'orderer'} />,
        },
        REJECTED: {
          ...noActionTextConfig,
          menuOptions: [createCancelMenuOption()],
        },
        SHIPPED: {
          subUi: () => (
            <CommonWrapper>
              {designManufactureCombination === 'DESIGN_AND_MANUFACTURE' && (
                <ActionComponent.DesignedFileDownload designFiles={designedFilesResponse} patientName={name} />
              )}
              <ActionComponent.ShipInfo orderId={orderId} tracking={tracking} />
            </CommonWrapper>
          ),
        },
        CANCELED: noActionTextConfig,
        MANUFACTURING: {
          subUi: () => {
            if (designManufactureCombination === 'DESIGN_AND_MANUFACTURE') {
              return (
                <CommonWrapper>
                  <ActionComponent.DesignedFileDownload designFiles={designedFilesResponse} patientName={name} />
                </CommonWrapper>
              );
            } else {
              return <ActionComponent.NoActionText />;
            }
          },
        },
        COMPLETED: {
          subUi: () => (
            <CommonWrapper>
              {designManufactureCombination !== 'MANUFACTURE' && (
                <ActionComponent.DesignedFileDownload patientName={name} designFiles={designedFilesResponse} />
              )}
              {designManufactureCombination !== 'DESIGN' && (
                <ActionComponent.ShipInfo orderId={orderId} tracking={tracking}></ActionComponent.ShipInfo>
              )}
              <Rating orderItems={orderItems} orderId={orderId} />
            </CommonWrapper>
          ),
          menuOptions: [
            {
              label: 'Remake',
              icon: 'ri-registered-fill',
              action: async (addDialog) => {
                const { data: remakeHistory }: ResponseDtoListOrderRemakeHistory = await kyInstance
                  .get(`connect/orders/${orderId}/remake/history`)
                  .json();
                if (remakeHistory && remakeHistory.length >= 6) {
                  addDialog(REMAKE_LIMITATIONS);
                  return;
                }
                navigate(`${ROUTES.ORDER_ROUTER.root}/remake/${orderId}`, {
                  state: { originalOrderId: originalOrderId ?? orderId },
                });
              },
            },
          ],
        },
      },
      receiver: {
        ORDERED: {
          subUi: () => (
            <ActionComponent.ConfirmRequired orderId={orderId} role={'receiver'} orderDetail={orderDetail} />
          ),
        },
        DESIGNING: {
          subUi: () => (
            <ActionComponent.DesignUpload
              designConfirmProcessIncluded={designConfirmProcessIncluded}
              orderId={orderId}
            />
          ),
        },
        WAITING_DESIGN_CONFIRMATION: noActionTextConfig,
        REJECTED: {
          ...noActionTextConfig,
          menuOptions: [
            {
              label: 'Cancel Order',
              icon: 'ri-close-circle-fill',
              action: (addDialog) => {
                const onActionClick = async () => {
                  await mutateAsync({ status: 'cancel' });
                };
                const dialogInfo = ORDER_DETAIL_CANCEL(onActionClick);
                addDialog(dialogInfo);
              },
            },
          ],
        },
        SHIPPED: {
          subUi: ({ addDialog }) => (
            <CommonWrapper
              buttonText={t('patient_page_status.btn_complete_order') as string}
              onButtonClick={async () => {
                if (addDialog) {
                  const dialogInfo = ORDER_COMPLETED(async () => {
                    await mutateAsync({ status: 'complete' });
                  });
                  addDialog(dialogInfo);
                }
              }}
            >
              <ActionComponent.ShipInfo orderId={orderId} tracking={tracking} />
            </CommonWrapper>
          ),
        },
        CANCELED: noActionTextConfig,
        MANUFACTURING: {
          subUi: () => <ActionComponent.ManufacturedFileUpload orderId={orderId} />,
        },
        COMPLETED: {
          ...noActionTextConfig,
        },
      },
    };
  };

  const roleConfig = useMemo(() => actionAreaConfig(orderId)[role], [orderId, role, status, orderItems]);
  let statusConfig = roleConfig[status];

  if (typeof statusConfig === 'function') {
    statusConfig = statusConfig(designManufactureCombination);
  }
  return statusConfig;
};
