import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  theme,
  Typography,
} from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';
import { FILE_EXTENSIONS_3D } from 'shared/constants/file';

type Printing3DModelDialoguePropType = {
  open: boolean;
  onClose: () => void;
};

export default function Printing3DModelDialogue({ open, onClose }: Printing3DModelDialoguePropType) {
  const { t } = useTranslation();
  const {
    orderData: { binaryFiles, printingFilesDisplay },
    setOrderData,
  } = useNewOrderDataContext();

  const modelFiles = binaryFiles.filter(({ file }) => {
    const ext = file.name.split('.').pop();
    if (!ext) return;
    return FILE_EXTENSIONS_3D.includes(ext);
  });

  const [innerPrintingFilesSet, setInnerPrintingFilesSet] = useState<Set<string>>(new Set(printingFilesDisplay)); // copy of printingFilesDisplay
  const isAllChecked = modelFiles.length !== 0 && modelFiles.length === innerPrintingFilesSet.size;
  const isActiveConfirmButton = modelFiles.length !== 0 && innerPrintingFilesSet.size;

  const handleCheckboxChange = (fileName: string) => {
    if (innerPrintingFilesSet.has(fileName)) {
      const updated = new Set(innerPrintingFilesSet);
      updated.delete(fileName);
      return setInnerPrintingFilesSet(updated);
    }
    const updated = new Set(innerPrintingFilesSet).add(fileName);
    setInnerPrintingFilesSet(updated);
  };
  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      return setInnerPrintingFilesSet(new Set());
    }
    const nameList = modelFiles.map(({ file }) => file.name);
    setInnerPrintingFilesSet(new Set(nameList));
  };

  const handleConfirmClick = () => {
    setOrderData((prev) => ({ ...prev, printingFilesDisplay: Array.from(innerPrintingFilesSet) }));
    onClose();
  };

  const handleCancelClick = () => {
    onClose();
    setInnerPrintingFilesSet(new Set(printingFilesDisplay));
  };

  useEffect(() => {
    setInnerPrintingFilesSet(new Set(printingFilesDisplay));
  }, [open]);

  return (
    <Dialogue open={open}>
      <DialogueTitle>{t('dialog_3d_model_order.title')}</DialogueTitle>
      <DialogueContent>
        <Stack>
          <Box>
            <Typography>{t('dialog_3d_model_order.subtitle')}</Typography>
          </Box>
          <Stack>
            <Box>
              <Checkbox
                checked={isAllChecked}
                label={t('dialog_3d_model_order.checkbox_file_name')}
                onChange={handleAllCheckboxChange}
                disabled={modelFiles.length === 0}
              />
            </Box>
            <Stack
              sx={{
                height: '262px',
                borderRadius: '8px',
                backgroundColor: theme.palette.grey[100],
                padding: '12px 0 12px 8px',
                overflowY: 'auto',
              }}
            >
              {modelFiles.length ? (
                modelFiles.map(({ file }) => (
                  <Checkbox
                    key={file.name}
                    checked={innerPrintingFilesSet.has(file.name)}
                    label={file.name}
                    onChange={() => handleCheckboxChange(file.name)}
                  />
                ))
              ) : (
                <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center">
                  <Typography variant={'H14'} color={theme.palette.text.secondary}>
                    {t('dialog_no_3d_files.title')}
                  </Typography>
                  <Typography variant={'Body14'} color={theme.palette.text.secondary}>
                    {t('dialog_no_3d_files.body')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogueContent>
      <DialogueActions>
        <Button onClick={handleCancelClick} size="36" color="grey" variant="outlined">
          {t('dialog_3d_model_order.btn_cancel')}
        </Button>
        <Button onClick={handleConfirmClick} size="36" disabled={!isActiveConfirmButton}>
          {t('dialog_3d_model_order.btn_confirm')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
}
