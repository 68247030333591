// useErrorHandler.ts
import { RefreshTokenInvalidDialogue } from '@imago-cloud/auth-client/dist/components/GlobalDialogue'; // 전역 에러 상태
import { useRecoilState } from 'recoil';

import { RefreshTokenExpiredDialogue } from '../../../../pages/Error/Dialogue/RefreshTokenExpiredDialogue';
import { errorState } from './errorState';

export const ERROR_CODE_MAPPING = {
  TOKEN_INVALIDATED: RefreshTokenInvalidDialogue,
  TOKEN_EXPIRED: RefreshTokenExpiredDialogue,
};

export const getErrorComponent = (code: keyof typeof ERROR_CODE_MAPPING) => {
  return ERROR_CODE_MAPPING[code] || null;
};

export const useErrorHandler = () => {
  const [error, setError] = useRecoilState(errorState);

  const handleError = (code: keyof typeof ERROR_CODE_MAPPING) => {
    const ErrorComponent = getErrorComponent(code);
    if (ErrorComponent) {
      setError({ open: true, component: ErrorComponent });
    }
  };

  const clearError = () => {
    setError({ open: false, component: null });
  };

  return { handleError, clearError, error };
};
