import { A1, A2, A3, A3_5, A4, B1, B2, B3, B4, C1, C2, C3, C4, D2, D3, D4, Etc, none_vector } from 'assets/shade/index';

export const RADIO_CHIP_BUTTON_TYPE = [
  { label: 'All options', key: 'dialog_product_option.tab_all_options' },
  // , { label: 'Recent' }, { label: 'Favorite' }
];

export const FINISH_OPTIONS_BUTTONS = [
  { value: false, label: 'product_list.btn_basic' },
  { value: true, label: 'product_list.btn_premium_stain' },
];

export const SHADE_OPTION = [
  { label: 'A1', icon: A1 },
  { label: 'A2', icon: A2 },
  { label: 'A3', icon: A3 },
  { label: 'A3_5', icon: A3_5 },
  { label: 'A4', icon: A4 },
  { label: 'B1', icon: B1 },
  { label: 'B2', icon: B2 },
  { label: 'B3', icon: B3 },
  { label: 'B4', icon: B4 },
  { label: 'C1', icon: C1 },
  { label: 'C2', icon: C2 },
  { label: 'C3', icon: C3 },
  { label: 'C4', icon: C4 },
  { label: 'D2', icon: D2 },
  { label: 'D3', icon: D3 },
  { label: 'D4', icon: D4 },
  { label: 'ETC', icon: Etc },
  { label: 'NONE', icon: none_vector },
];
