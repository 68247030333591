import { iwtkInteractorStyle } from '@imago/iwtk.js/dist/vtkExtends/vtkInteractorStyleExtendsBase';

/**
 * vtk multi-touch 관련 버그를 해결하기 위한 클래스
 * - 참고: vtkRenderWindowInteractor의 interactionRegistration() 함수에서 activeListenerCount를 증감하며 event listener를 추가/삭제함
 * - touchend 이벤트에서 count 감소가 제대로 이루어지지 않아, touchend 이벤트 리스너를 제때 제거 하지 않음
 * - event.preventDefault() 영향으로 다른 DOM의 이벤트 핸들링 막음
 * - endPinch / endPan 이벤트 시 가상의 touchend 이벤트를 한번 더 생성하여 count 감소시킴
 */
const isMacOs = true;
const PARALLEL_SCALE_MIN = 1.85;
const PARALLEL_SCALE_MAX = 525;
class interactorStyleTouch extends iwtkInteractorStyle {
  constructor(refInteractorStyleConstructor) {
    super(refInteractorStyleConstructor);
  }

  pressedKey = undefined;

  //////////////////////////////////////////////////////////
  // ------  functions overrides ------
  getClassName() {
    // override function of interactor Style
    return 'interactorStyleTouch';
  }

  dispatchTouchendEvent() {
    if ('TouchEvent' in window && TouchEvent.length > 0) {
      const touch = new Touch({
        identifier: 0,
        target: document,
        clientX: 0,
        clientY: 0,
        screenX: 0,
        screenY: 0,
        pageX: 0,
        pageY: 0,
        radiusX: 5,
        radiusY: 5,
      });

      const event = new TouchEvent('touchend', {
        cancelable: true,
        bubbles: true,
        touches: [],
        targetTouches: [],
        changedTouches: [touch],
      });

      document.dispatchEvent(event);
    }
  }

  EndPinch(callData) {
    super.EndPinch(callData);

    this.dispatchTouchendEvent();
  }

  EndPan(callData) {
    super.EndPan(callData);

    this.dispatchTouchendEvent();
  }

  EndRotate(callData) {
    super.EndRotate(callData);

    this.dispatchTouchendEvent();
  }

  //////////////////////////////////////////////////
  // mouse & key mapping
  KeyDown(callData) {
    this.pressedKey = callData.key;
    super.KeyDown(callData);
  }
  KeyUp(callData) {
    this.pressedKey = undefined;
    super.KeyUp(callData);
  }
  LeftButtonPress(callData) {
    if (this.pressedKey !== undefined) {
      const pos = callData.position;
      this.previousPosition = pos;

      if (isMacOs) {
        if (this.pressedKey === 'Meta') {
          // panning
          this.StartPan(callData);
        }
      } else {
        if (this.pressedKey === 'Control') {
          //panning
          this.StartPan(callData);
        }
      }
    } else {
      super.LeftButtonPress(callData);
    }
  }
  LeftButtonRelease(callData) {
    super.LeftButtonRelease(callData);
  }
  MiddleButtonPress(callData) {
    super.MiddleButtonPress(callData);
    this.StartPan(callData);
  }
  MiddleButtonRelease(callData) {
    this.EndPan(callData);
    super.MiddleButtonRelease(callData);
  }
  RightButtonPress(callData) {
    super.RightButtonPress(callData);
    this.StartRotate(callData);
  }
  RightButtonRelease(callData) {
    this.EndRotate(callData);
    super.RightButtonRelease(callData);
  }
  Animation(callData) {
    const cam = this.getInteractor()?.getCurrentRenderer()?.getActiveCamera();
    const parallelScale = cam?.getParallelScale();
    if (parallelScale < PARALLEL_SCALE_MIN) cam?.setParallelScale(PARALLEL_SCALE_MIN);
    if (parallelScale > PARALLEL_SCALE_MAX) cam?.setParallelScale(PARALLEL_SCALE_MAX);
    super.Animation(callData);
  }
}

export default interactorStyleTouch;
