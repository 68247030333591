import { useTranslation } from 'react-i18next';
import { theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderDetailResponse } from 'client/types.gen';
import { formattedAddress } from 'shared/utils/formatString';

export const ShippingInformation = ({
  receiver,
  shippingAddress,
  deliveryInstruction,
}: {
  receiver: OrderDetailResponse['receiver'];
  shippingAddress: OrderDetailResponse['shippingAddress'];
  deliveryInstruction: OrderDetailResponse['deliveryInstruction'];
}) => {
  const { t } = useTranslation();
  if (!receiver || !shippingAddress) {
    return <></>;
  }

  const {
    name: { firstName, lastName },
    number: { number, countryCode },
  } = receiver;
  return (
    <Stack
      sx={{
        p: '16px 24px ',
        border: `1px solid ${theme.palette.grey['200']}`,
        borderRadius: '8px',
        gap: '8px',
        color: theme.palette.text.secondary,
      }}
    >
      <Typography variant={'Subtitle_Semibold14'} sx={{ color: theme.palette.text.primary }}>
        {t('shipping_information.title_shipping_information')}
      </Typography>
      <Stack sx={{ gap: '12px' }}>
        <Stack>
          <Typography variant={'Subtitle_Semibold14'}>{`${firstName} ${lastName}`}</Typography>

          <Typography variant={'Body14'}>
            {formattedAddress({
              ...shippingAddress,
            })}
          </Typography>
          <Typography variant={'Body14'}>{number}</Typography>
        </Stack>
        {deliveryInstruction && (
          <Stack>
            <Typography variant={'Subtitle_Semibold14'}>
              {t('shipping_information.title_delivery_instruction')}
            </Typography>
            <Typography variant={'Body14'}>{deliveryInstruction}</Typography>
          </Stack>
        )}
        {/*payment method*/}
        {/*<Stack>*/}
        {/*  <Typography variant={'Subtitle_Semibold14'}>{t('shipping_information.title_payment_method')}</Typography>*/}
        {/*  <Typography variant={'Body14'}>Pay later</Typography>*/}
        {/*</Stack>*/}
      </Stack>
    </Stack>
  );
};
