import { Trans, useTranslation } from 'react-i18next';
import { Chip, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { priceMeasuredOrderDataState } from 'pages/NewOrder/store/atoms';
import { useRecoilState } from 'recoil';
import { ToothCards } from 'shared/ui/toothProductDetail/ToothCards';

import { formattedPrice } from '../../../../shared/utils/formatString';

export const OrderInfo = () => {
  const { t } = useTranslation();

  const [priceMeasuredOrderData, setPriceMeasuredOrderData] = useRecoilState(priceMeasuredOrderDataState);
  const { orderItems, patientPrintingFiles, printingFilesAmount } = priceMeasuredOrderData || {};

  return (
    <Stack sx={{ width: '100%' }}>
      <Stack sx={{ width: '100%', gap: '12px', mt: '12px' }}>
        <ToothCards orderItems={orderItems ?? []} />
      </Stack>
      {patientPrintingFiles && patientPrintingFiles.length > 0 && (
        <>
          <Stack sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', margin: '16px 0' }}>
            <Typography variant="Subtitle_Semibold14" color="primary.main">
              {`+ ${t('patient_page_info.title_additional_options')}`}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            sx={{
              width: '100%',
              padding: '16px 20px',
              border: '1px solid',
              borderColor: 'grey.200',
              borderRadius: '8px',
              gap: '4px',
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="H14">{t('order_overview.3d_model')}</Typography>
              <Typography variant="Subtitle_Semibold14">{formattedPrice(printingFilesAmount ?? 0, t)}</Typography>
            </Stack>
            <Stack direction="row" gap="4px" alignItems="center">
              <Typography variant="Body14">{t('order_overview.order_quantity')}</Typography>
              <Chip
                label={<Trans i18nKey={'order_overview.order'} values={{ count: patientPrintingFiles.length }} />}
                variant="outlined"
                color="default"
                size="small"
              />
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
