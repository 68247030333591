import { useTranslation } from 'react-i18next';
import { theme, Typography } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { OrderTimeline } from 'client/types.gen';
import dayjs from 'dayjs';
import { STATUS_LABELS } from 'pages/MyOrderList/constants/menuOptions';

export const Timeline = ({ timeline }: { timeline: OrderTimeline[] }) => {
  return (
    <Stack sx={{ position: 'relative' }}>
      {timeline?.map((item, index) => (
        <TimelineRow
          key={`${item.afterStatus}_${index}`}
          detail={item}
          index={index}
          isLast={index === timeline.length - 1}
        />
      ))}
    </Stack>
  );
};

const TimelineRow = ({ detail, index, isLast }: { detail: OrderTimeline; index: number; isLast: boolean }) => {
  const { t } = useTranslation();
  const { afterStatus, updatedAt, updatedBy } = detail;
  const isRecent = index === 0;

  return (
    <Stack key={`${afterStatus}_${index}`} direction="row" sx={{ gap: '7px', p: '20px 12px' }}>
      <Box sx={{ position: 'relative', width: '24px' }}>
        <Box
          sx={{
            display: isRecent ? 'block' : 'none',
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: isRecent ? theme.palette.blue['10'] : 'none',
            position: 'relative',
            top: 0,
          }}
        />
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: isRecent ? theme.palette.primary.main : theme.palette.text.secondary,
            position: 'absolute',
            top: '8px',
            left: '8px',
          }}
        />

        {!isLast && (
          <Box
            sx={{
              position: 'absolute',
              top: '16px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '1px',
              height: '74px',
              backgroundColor: theme.palette.grey[200],
            }}
          />
        )}
      </Box>
      <Stack>
        <Typography
          variant="Subtitle_Semibold16"
          sx={{
            color: isRecent ? theme.palette.primary.main : theme.palette.text.primary,
          }}
        >
          {t(STATUS_LABELS[afterStatus] as string)}
        </Typography>
        <Typography variant="Caption12" sx={{ color: theme.palette.text.secondary }}>
          {dayjs(updatedAt).format('YYYY-MM-DD')} by {updatedBy}
        </Typography>
      </Stack>
    </Stack>
  );
};
