import React from 'react';
import { theme, transparentHexColor } from '@imago-cloud/design-system';
import { FormControl, FormControlLabel, Radio, RadioGroup, SvgIcon, SvgIconProps } from '@mui/material';
import { ProductType, Shape } from 'client/types.gen';
import humanizeString from 'humanize-string';

interface RadioButtonListProps {
  selectedValue: string;
  optionList: Array<ProductType | Shape> | undefined;
  outerOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  innerOnChange?: (item: Shape) => void;
}

const RadioButtonList = ({ selectedValue, optionList, outerOnChange, innerOnChange }: RadioButtonListProps) => {
  return (
    <FormControl>
      <RadioGroup value={selectedValue} onChange={(e) => outerOnChange(e)}>
        {optionList?.map((item) => (
          <FormControlLabel
            key={item.value as string}
            value={item.value}
            control={
              <Radio
                sx={{
                  width: '36px',
                  height: '36px',
                  mr: '4px',
                  '&:hover': {
                    backgroundColor: transparentHexColor(theme.palette.common.black, 8),
                  },
                }}
                icon={<RadioDefault />}
                checkedIcon={<RadioChecked />}
                onClick={(_e) => innerOnChange?.(item as Shape)}
              />
            }
            label={humanizeString(item.value as string)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export function RadioDefault(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" fontSize="inherit" style={{ width: 18, height: 18 }}>
      <circle cx="9" cy="9" r="8.5" fill="white" stroke="#CDD1D5" />
    </SvgIcon>
  );
}
export function RadioChecked(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" fontSize="inherit" style={{ width: 18, height: 18 }}>
      <circle cx="9" cy="9" r="9" fill="#0083CA" />
      <circle cx="9" cy="9" r="4.5" fill="white" />
    </SvgIcon>
  );
}
export default RadioButtonList;
