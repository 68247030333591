import { useTranslation } from 'react-i18next';
import { Divider, theme, Typography } from '@imago-cloud/design-system';
import { alpha, Stack } from '@mui/material';
import { OrderDetailResponse } from 'client/types.gen';

import { formattedPrice } from '../../../../shared/utils/formatString';

export const OrderPrice = ({
  printing3DModelPrice,
  shippingFee,
  billingAmount,
  orderAmount,
}: Pick<OrderDetailResponse, 'shippingFee' | 'printing3DModelPrice' | 'billingAmount' | 'orderAmount'>) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ borderRadius: '8px', backgroundColor: alpha(theme.palette.primary.main, 0.08), p: '16px 20px' }}>
      <Stack sx={{ gap: '8px' }}>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant={'Subtitle_Semibold14'}>{t('price.subtitle_subtotal')}</Typography>
          <Typography variant={'Subtitle_Semibold14'}>
            {formattedPrice(printing3DModelPrice + orderAmount, t)}
          </Typography>
        </Stack>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant={'Subtitle_Semibold14'}>{t('price.subtitle_shipping_fee')}</Typography>
          <Typography variant={'Subtitle_Semibold14'}>{formattedPrice(shippingFee, t)}</Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', color: theme.palette.primary.main }}>
        <Typography variant={'Subtitle_Semibold14'}>{t('price.subtitle_total')}</Typography>
        <Typography variant={'Subtitle_Semibold14'}>{formattedPrice(billingAmount, t)}</Typography>
      </Stack>
    </Stack>
  );
};
