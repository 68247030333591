import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { IconButton, Ricon, theme, Tooltip, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { ChartOptions } from 'chart.js';

import { CardLayout } from './Dashboard';

const chartData = {
  labels: Array.from({ length: 12 }, (_, i) => i + 1), // X축 라벨
  datasets: [
    {
      label: 'Implant',
      data: [], // 1월에는 80, 2월에는 0
      backgroundColor: '#FF5733',
      barThickness: 8,
    },
    {
      label: 'Denture',
      data: [], //
      backgroundColor: '#33FF57',
      barThickness: 8,
    },
    {
      label: 'Crown',
      data: [], //
      backgroundColor: '#3357FF', // 막대 색상
      barThickness: 8,
    },
    {
      label: 'Bridge',
      data: [], //
      backgroundColor: '#FF33A1',
      barThickness: 8,
    },
  ],
};
const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom', // 범례 위치
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        display: true, // X축 라벨 표시
        color: theme.palette.grey['500'],
        font: {
          size: 14,
          weight: 'bold',
        },
      },
    },
    y: {
      stacked: true, // Y축 스택 활성화
      grid: {
        drawTicks: false,
      },
      ticks: {
        display: true,
        color: theme.palette.grey['500'],
        font: {
          size: 14,
          weight: 'bold',
        },
        padding: 16,
      },
    },
  },
};

export const YearlyStatistics = () => {
  const { t } = useTranslation();
  const description = t('dashboard_tooltip.body').split('\n');
  return (
    <CardLayout sx={{ height: '752px', p: '24px 40px 40px 24px', position: 'relative' }}>
      <Stack sx={{ flexDirection: 'row', height: '100%' }}>
        <Stack sx={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
          <Typography variant={'H18'}>{t('dashboard.statistics')}</Typography>
          <Tooltip
            title={
              <ul
                style={{
                  margin: 0,
                  listStyleType: 'none',
                  padding: '0',
                }}
              >
                {description.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            }
            placement={'right'}
            arrow
          >
            <Stack>
              <Ricon icon={'ri-question-mark'} svgProps={{ width: 18, height: 18, fill: theme.palette.grey['700'] }} />
            </Stack>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%', height: '480px', mt: '150px' }}>
        <Bar data={chartData} options={options} />
      </Stack>

      <Stack
        sx={{
          flexDirection: 'row',
          position: 'absolute',
          alignItems: 'center',
          right: '20px',
          top: '20px',
          gap: '12px',
        }}
      >
        <Typography variant={'Subtitle_Semibold16'} sx={{ color: theme.palette.grey['800'] }}>
          2022.02
        </Typography>
        <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
          <IconButton size={'36'} color={'grey800'} icon_size={'small'} variant={'transparent'}>
            <Ricon icon={'ri-arrow-left-s-line'} />
          </IconButton>
          <IconButton size={'36'} color={'grey800'} icon_size={'small'} variant={'transparent'}>
            <Ricon icon={'ri-arrow-right-s-line'} />
          </IconButton>
        </Stack>
      </Stack>
    </CardLayout>
  );
};
