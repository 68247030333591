import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Cicon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { ROUTES } from 'constants/routes';
import { getCurrentOrderStatus, getOrderStatusConfig } from 'shared/utils/getOrderStatusConfig';

import { useOrderState } from '../atoms/useOrderState';

export const NO_PLACED_ORDER_DESCRIPTION = {
  title: 'page_my_orders_empty.title',
  subtitle: 'page_my_orders_empty.subtitle',
};

export const NO_RECEIVED_ORDER_DESCRIPTION = {
  title: 'no_received_orders_yet.title',
  subtitle: 'no_received_orders_yet.subtitle',
};

export const NO_FILTER_ORDER_DESCRIPTION = {
  title: 'page_my_orders_no_match.title',
  subtitle: 'page_my_orders_no_match.subtitle',
};

export const NoOrderData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isUnfiltered } = useOrderState();

  const handleNewOrderClick = () => navigate(`${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.newOrder}`);

  const currnetOrderStatus = getCurrentOrderStatus();
  const { getOrderDataDescription } = getOrderStatusConfig();

  const { title, subtitle } = getOrderDataDescription(isUnfiltered);

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!isUnfiltered && (
        <Stack sx={{ mb: '12px' }}>
          <Cicon icon={'NoResult'} />
        </Stack>
      )}
      <Stack sx={{ color: theme.palette.text.secondary, gap: '8px', alignItems: 'center' }}>
        <Typography variant={'H24'}>{t(title)}</Typography>
        <Typography variant={'Subtitle_Semibold16'}>{t(subtitle)}</Typography>
      </Stack>
      {currnetOrderStatus === 'orderer' && isUnfiltered && (
        <Button size={'48'} variant={'outlined'} color={'grey'} onClick={handleNewOrderClick} sx={{ mt: '32px' }}>
          {t('page_my_orders_empty.btn_start_new_order')}
        </Button>
      )}
    </Stack>
  );
};
