import { Drawer, Stack, styled } from '@mui/material';
import { useDrawer, useMediaResponsive } from 'shared/hooks';

import NavSection from './NavSection';

type SNBDrawerPropType = {
  isRailRoute: boolean;
};

export default function SNBDrawer({ isRailRoute }: Readonly<SNBDrawerPropType>) {
  const isDownLg = useMediaResponsive('down', 'lg');
  const isRailMode = Boolean(isRailRoute || isDownLg);
  const { isCollapse, onHoverEnter, onHoverLeave } = useDrawer(isRailMode);

  return (
    <RootStyle
      sx={{
        minWidth: isRailMode ? 80 : 280,
        width: isRailMode ? 80 : 280,
      }}
    >
      <Drawer
        open
        variant="persistent"
        onMouseLeave={onHoverLeave}
        PaperProps={{
          sx: {
            mt: '44px',
            width: 280,
            bgcolor: 'background.default',
            justifyContent: 'space-between',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
            ...(isCollapse && {
              width: 80,
            }),
          },
        }}
      >
        <Stack
          spacing={3}
          sx={{
            height: '100%',
            p: '28px 16px',
            flexShrink: 0,
            transition: (theme) =>
              theme.transitions.create('all', {
                duration: theme.transitions.duration.standard,
              }),
            ...(isCollapse && {
              px: '10px',
            }),
          }}
        >
          <NavSection isCollapse={isCollapse} onMouseEnter={onHoverEnter} />
        </Stack>
      </Drawer>
    </RootStyle>
  );
}

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));
