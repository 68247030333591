import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { ResponseDtoSessionTokenResponse } from 'client/types.gen';

import { kyInstance } from './kyInstance';

export const getSendbirdSessionToken = async (
  userId: string,
  expires_at?: string,
): Promise<ResponseDtoSessionTokenResponse> => {
  return await kyInstance
    .get(`connect/sendbird/${userId}/session-token${expires_at ? `?expires_at=${expires_at}` : ''}`)
    .json();
};

export const useSendbirdSessionToken = (userId: string, expires_at?: string) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['session-token', userId],
    queryFn: () => getSendbirdSessionToken(userId, expires_at),
    enabled: !!userId,
  });
