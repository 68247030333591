import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Cicon, Loading, theme } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { useVTKContext } from 'shared/hooks/useVTKContext';
import {
  getFileNameExcludeExtension,
  getOrderFileToRenderingData,
} from 'shared/utils/Viewer/convertOrderFileToRenderingData';

import { ConvertFileType, FILE_LOCATION_RESPONSE, totalFilesResponseType } from '../Databox';

const SubRenderingArea = ({ isLoading }: { isLoading: boolean }) => {
  const viewerRef = useRef<HTMLElement>();
  const { t } = useTranslation();

  const { handleResetCamera, setRenderWindowContainer } = useVTKContext();

  useEffect(() => {
    if (viewerRef.current) {
      setRenderWindowContainer(viewerRef.current, 'sub');
    }
  }, [setRenderWindowContainer, viewerRef]);

  const handleZoomToFitClick = () => {
    handleResetCamera('sub');
  };

  return (
    <Stack
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.canvas,
      }}
    >
      {isLoading && (
        <Stack
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.background.canvas,
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '10',
            }}
          >
            <Loading size="44" type="basic" />
          </Stack>
        </Stack>
      )}
      <Stack ref={viewerRef as any} sx={{ width: '100%', height: '100%' }} />
      {!isLoading && (
        <Button
          size="36"
          color="grey"
          shadow={true}
          hover_color="black"
          variant="outlined"
          startIcon={<Cicon icon="ZoomToFit" />}
          sx={{
            position: 'absolute',
            bottom: '40px',
            left: '20px',
            ':hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
          onClick={handleZoomToFitClick}
        >
          {t('btn_zoom_to_fit.body')}
        </Button>
      )}
    </Stack>
  );
};

export const SubViewer = ({
  fileId,
  totalFilesResponse,
  location,
}: {
  fileId: string;
  totalFilesResponse: totalFilesResponseType;
  location: ConvertFileType['location'];
}) => {
  const isMountedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const { renderingDataLists, renderingToRenderer } = useVTKContext();

  useEffect(() => {
    const loadData = async () => {
      const locationResponseType = FILE_LOCATION_RESPONSE[location as keyof typeof FILE_LOCATION_RESPONSE];
      const targetFiles = totalFilesResponse[locationResponseType as keyof typeof totalFilesResponse];
      const targetFileName = targetFiles.find(({ id }) => id === fileId)?.fileName || '';
      const relatedFiles = targetFiles.filter(
        ({ fileName }) => getFileNameExcludeExtension(fileName) === getFileNameExcludeExtension(targetFileName),
      );
      const newRenderingDataList = await getOrderFileToRenderingData(relatedFiles);
      renderingToRenderer(newRenderingDataList, 'sub');
      setIsLoading(false);
    };
    if (!isMountedRef.current) {
      loadData();
      isMountedRef.current = true;
    } else return;
  }, [fileId, location, renderingDataLists, renderingToRenderer, totalFilesResponse]);

  return <SubRenderingArea isLoading={isLoading} />;
};
