import ViewInfo from '@imago/iwtk.js/dist/data/ViewInfo';
import vtkInteractorStyleTrackballCamera from '@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera';
import vtkGenericRenderWindow from '@kitware/vtk.js/Rendering/Misc/GenericRenderWindow';

import interactorStyleTouch from './vtkInteractorStyleTouch';

export const createGenericRenderWindow = () => {
  const genericRenderWindow = vtkGenericRenderWindow.newInstance();
  const viewInfo = new ViewInfo('main', '3D', false, genericRenderWindow);
  viewInfo.update();
  genericRenderWindow.getRenderer().setBackground(0, 0, 0, 0);

  // Left Mouse: Rotate
  // Left Mouse + Shift: Pan
  // Left Mouse + Ctrl/Alt: Spin
  // Left Mouse + Shift + Ctrl/Alt: Dolly
  // Mouse Wheel: Dolly
  // Multi-Touch Rotate: Rotate
  // Multi-Touch Pinch: Dolly
  // Multi-Touch Pan: Pan
  // 3D Events: Camera Pose

  const interactorStyle = new interactorStyleTouch(vtkInteractorStyleTrackballCamera);

  // set custom interactor style
  const interactor = genericRenderWindow.getInteractor();
  interactor.setInteractorStyle(interactorStyle);

  return genericRenderWindow;
};
