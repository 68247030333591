import { Box, Stack, StackProps, useTheme } from '@mui/material';
import { MENU_ITEMS } from 'constants/menuItem';
import { ROUTES } from 'constants/routes';
import { usePermission } from 'shared/hooks/usePermission';

import CostInfoButton from './CostInfoButton';
import NavListRoot from './NavListRoot';
import NewCaseButton from './NewCaseButton';

interface NavSectionPropType extends StackProps {
  isCollapse: boolean;
  onMouseEnter: () => void;
}
export default function NavSection({ isCollapse = false, ...other }: Readonly<NavSectionPropType>) {
  const theme = useTheme();
  const { accessibleClientPaths } = usePermission();

  const isAccessCostInfo = accessibleClientPaths.includes(`${ROUTES.COST_INFO_ROUTER.root}`);

  return (
    <Stack
      sx={{
        height: `calc(100% - 45px)`,
        width: 'fit-content',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',

        '.MuiButton-containedSizeLarge': {
          backgroundColor: `${theme.palette.primary.main} !important`,
        },
        '.MuiButton-containedSizeLarge:disabled': {
          backgroundColor: `${theme.palette.grey[300]} !important`,
        },
      }}
    >
      <Box sx={{ width: '100%' }} {...other}>
        <NewCaseButton
          isCollapse={isCollapse}
          sx={{
            mb: '28px',
            transition: (theme) =>
              theme.transitions.create('all', {
                duration: theme.transitions.duration.standard,
              }),
          }}
        />
        <Stack
          gap="8px"
          sx={{
            mx: '4px',
          }}
        >
          {MENU_ITEMS.map((item) => (
            <NavListRoot key={item.title} item={item} isCollapse={isCollapse} />
          ))}
        </Stack>
      </Box>

      {isAccessCostInfo && (
        <Box sx={{ width: '100%' }} {...other}>
          <CostInfoButton isCollapse={isCollapse} />
        </Box>
      )}
    </Stack>
  );
}
