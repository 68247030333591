import { useGlobalDialog } from 'store';

import { CommonDialog } from './CommonDialog';

export default function GlobalDialog() {
  const { currentDialogInfo } = useGlobalDialog();

  if (currentDialogInfo?.component) {
    return <>{currentDialogInfo.component}</>;
  }
  if (!currentDialogInfo?.dialogContent) return null;

  return <CommonDialog {...currentDialogInfo?.dialogContent} />;
}
