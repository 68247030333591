import { FileResponse } from 'client/types.gen';
import { ImageFileUpload } from 'shared/ui/fileUpload/ImageFileUpload';
import { PreviewImage } from 'shared/ui/fileUpload/PreviewImage';

export const FILE_UPLOAD_GUIDELINES = {
  ORGANIZATION: 'JPG, JPEG, BMP, PNG format (max 10MB)',
  MANUFACTURING: 'Upload your shipping invoice image here',
};

export type FileType = File | FileResponse | null;

export const FileUploadWithPreview = ({
  file,
  setFile,
  description,
  isDisabled,
}: {
  file: FileType;
  setFile: React.Dispatch<React.SetStateAction<FileType>>;
  description: string;
  isDisabled?: boolean;
}) => {
  const previewUrl = file ? (file instanceof File ? URL.createObjectURL(file) : file.fileUrl) : null;

  return (
    <>
      {previewUrl ? (
        <PreviewImage previewUrl={previewUrl} onDelete={() => setFile(null)} isDisabled={isDisabled} />
      ) : (
        <ImageFileUpload setFile={setFile} description={description} isDisabled={isDisabled} />
      )}
    </>
  );
};
