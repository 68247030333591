import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, TextField } from '@imago-cloud/design-system';
import { IDatePicker } from '@imago-cloud/design-system/lib/esm/Components/Inputs/DatePicker/DatePicker';

type RHFDatePickerPropType = IDatePicker & {
  name: string;
  rules?: Record<string, unknown>;
  sx?: Record<string, unknown>;
  placeholder: string;
  inputFormat?: string;
  disableFuture?: boolean;
  inputSx?: Record<string, unknown>;
};
export default function RHFDatePicker({
  name,
  rules,
  placeholder,
  value,
  inputFormat,
  disableFuture = false,
  inputSx,
  disabled,
  ...rest
}: RHFDatePickerPropType) {
  const { control, trigger } = useFormContext();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => {
        return (
          <>
            {
              <DatePicker
                open={isOpen}
                onClose={() => setIsOpen(false)}
                disableFuture={disableFuture}
                onChange={onChange}
                value={value ?? ''}
                inputFormat={inputFormat}
                placeholder={placeholder}
                error={!!error}
                disabled={disabled}
                {...rest}
                renderInput={({ inputRef, inputProps }) => {
                  return (
                    <TextField
                      ref={inputRef}
                      size="36"
                      name={name}
                      value={inputProps?.value}
                      placeholder={placeholder}
                      disabled={inputProps?.disabled}
                      onClick={() => setIsOpen(true)}
                      onChange={onChange}
                      onBlur={(_e) => {
                        trigger(name);
                      }}
                      error={!!error}
                      sx={{ width: '100%' }}
                      helperText={error?.message}
                      {...inputRef}
                    />
                  );
                }}
              />
            }
          </>
        );
      }}
    />
  );
}
