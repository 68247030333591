import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export default function NoSelectedText() {
  const { t } = useTranslation();

  return (
    <Stack sx={{ height: '100%' }} justifyContent="center">
      <Stack alignItems="center" gap="6px" sx={{ color: theme.palette.text.thirdary }}>
        <Typography variant="H24">{t('teeth_form.no_selected_tooth')}</Typography>
        <Typography variant="Body16" sx={{ width: '196px', textAlign: 'center' }}>
          {t('teeth_form.please_select_tooth_to_start')}
        </Typography>
      </Stack>
    </Stack>
  );
}
