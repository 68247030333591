import MeshRenderingObject from '@imago/iwtk.js/dist/data/MeshRenderingObject';
import MeshDataIO from '@imago/iwtk.js/dist/loader/MeshDataIO';
import { deepCopyPolyData } from '@imago/iwtk.js/dist/utils/misc';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkDracoReader from '@kitware/vtk.js/IO/Geometry/DracoReader';
import vtkTexture from '@kitware/vtk.js/Rendering/Core/Texture';

import vtkResourceLoader from './vtkResourceLoader';

const readImageFile = async (file: File) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const image = new Image();
      image.src = String(reader.result);
      image.onload = () => {
        resolve(image);
      };
    };
    reader.readAsDataURL(file);
  });
};

const addTextureToRenderingObject = async (renderingObject: MeshRenderingObject, textureFiles: File[]) => {
  for (const file of textureFiles) {
    if (file.name?.split('.').pop()?.toLowerCase() === 'mtl') continue;
    const textureImage = await readImageFile(file);
    const texture = vtkTexture.newInstance();
    texture.setImage(textureImage);
    texture.update();
    renderingObject.setTexture(texture);
    renderingObject.isShowTexture = true;
    renderingObject.update();

    return;
  }
};

// Ensure Draco scripts are loaded only once
let dracoScriptsLoaded = false;
const loadDracoScripts = async () => {
  if (!dracoScriptsLoaded) {
    const cacheBuster = `?cacheBuster=${new Date().getTime()}`;

    await vtkResourceLoader.loadScript(`https://www.gstatic.com/draco/v1/decoders/draco_wasm_wrapper.js${cacheBuster}`);
    await vtkDracoReader.setWasmBinary(
      `https://www.gstatic.com/draco/v1/decoders/draco_decoder.wasm${cacheBuster}`,
      'draco_decoder.wasm',
    );
    dracoScriptsLoaded = true;
  }
};

export const convertFileToMeshRenderingObject = async (file: File, textureFiles: File[] = []) => {
  try {
    const fileExt = file.name.split('.').pop();
    const renderingObject = new MeshRenderingObject();
    if (fileExt === 'drc') {
      await loadDracoScripts();
      const reader = vtkDracoReader.newInstance();
      reader.parseAsArrayBuffer(await file.arrayBuffer());
      renderingObject.setPolyData(reader.getOutputData());
    } else {
      const meshLoader = new MeshDataIO(); // ignore
      await meshLoader.openLocalFile(file);
      renderingObject.setCppMeshData(meshLoader.cppMeshData);
    }
    renderingObject.setVertexColorOnOff(true);
    renderingObject.update();
    await addTextureToRenderingObject(renderingObject, textureFiles);
    return { renderingObject, isError: false };
  } catch (err) {
    console.error(err);
    return { renderingObject: undefined, isError: true };
  }
};

export const copyRenderingObejct = (renderingObject: MeshRenderingObject) => {
  const newRenderingObject = new MeshRenderingObject();
  const oldPolyData = renderingObject.getPolyData();
  if (oldPolyData !== null) {
    const polyData = vtkPolyData.newInstance();
    deepCopyPolyData(oldPolyData, polyData);
    newRenderingObject.setPolyData(polyData);

    // const oldTexture = renderingObject.getTexture();
    // if (oldTexture) {
    //   newRenderingObject.setTexture(renderingObject.getTexture());
    // }
    newRenderingObject.setVertexColorOnOff(true);
    newRenderingObject.update();
  }

  return newRenderingObject;
};
