import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderItem, OrderItemRequest, PersonName } from 'client/types.gen';
import BackStepTitle from 'pages/NewOrder/components/BackStepTitle';
import { SingleProductDefaultValue } from 'pages/NewOrder/components/Prescription/Prescription';
import { OrderRequestDefaultValues } from 'pages/NewOrder/context/NewOrderDataProvider';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';
import { useRemakeSelectTooth } from 'shared/hooks/useRemakeSelectTooth';
import { LoadingCenter } from 'shared/ui/loading/LoadingCenter';
import { ToothContentRow } from 'shared/ui/toothProductDetail/ToothContentRow';
import { FDIToCustomSelectorNumber, FDIToPalmer } from 'shared/utils/convertToPalmer';
import { capitalizeFirstLetter } from 'shared/utils/formatString';

import { useRemakeOrderDetailQuery } from '../../../../api/orderQuery';
import { ExtendedOrderItem, groupAndSortOrderItems } from '../../../../utils/groupByProsthesis';

export default function Remake({ onNext }: { onNext: () => void }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setOrderData } = useNewOrderDataContext();
  const { data: remakeData, isLoading } = useRemakeOrderDetailQuery(state.originalOrderId);
  const orderDetail = remakeData?.data;

  const data = groupAndSortOrderItems(orderDetail?.orderItems ?? []);
  const { resetSelectOrderItem, selectedGroupId, selectedOrderItems } = useRemakeSelectTooth();
  useEffect(() => {
    return () => resetSelectOrderItem();
  }, []);

  const handleNextClick = () => {
    if (!selectedOrderItems) return;
    const orderItemRequest = convertToOrderItemRequest(selectedOrderItems as OrderItem[]);
    setOrderData((prev) => ({
      ...prev,
      patient: {
        ...OrderRequestDefaultValues.patient,
        ...orderDetail?.patient,
      },
      ordererName: orderDetail?.orderer?.name as PersonName,
      designManufactureCombination: orderDetail?.designManufactureCombination,
      designConfirmProcessIncluded: orderDetail?.initialDesignConfirmProcessIncluded,
      remakeOrderInfo: state.originalOrderId ? { originalId: state.originalOrderId } : null,
      orderItems: orderItemRequest,
    }));
    onNext();
  };
  if (isLoading) {
    return <LoadingCenter />;
  }
  return (
    <Stack sx={{ position: 'relative', height: '100%' }}>
      <BackStepTitle
        stepName={t('remake.button_remake_order')}
        onPrevious={() => {
          navigate(-1);
        }}
        isDirty={false}
      />
      <Stack sx={{ width: '500px', gap: '24px', ml: '68px', mt: '108px', pb: '68px' }}>
        <Stack sx={{ gap: '4px' }}>
          <Typography variant={'H18'}>{t('remake.title')}</Typography>
          <Typography variant={'Body12'} color={theme.palette.text.secondary}>
            {t('remake.subtitle')}
          </Typography>
        </Stack>

        <Stack sx={{ gap: '9px', mb: '14px' }}>
          {data.map(([orderKey, orderItems], index) => (
            <ToothInfo key={orderKey} orderKey={orderKey} orderItems={orderItems} />
          ))}
        </Stack>
        <Button
          size={'48'}
          sx={{ width: '120px', alignSelf: 'flex-end' }}
          onClick={handleNextClick}
          disabled={!selectedGroupId.length}
        >
          {t('remake.btn_next')}
        </Button>
      </Stack>
    </Stack>
  );
}

const ToothInfo = ({ orderKey, orderItems }: { orderKey: string; orderItems: ExtendedOrderItem[] }) => {
  const { selectedGroupId, handleSelectOrderItem } = useRemakeSelectTooth();

  const isSelected = selectedGroupId.includes(orderKey);
  const isDisabled = orderItems.some((item, idx) => !item.isAvailable);

  const style = getStyle(isSelected, isDisabled);

  const onSelect = () => {
    !isDisabled && handleSelectOrderItem(orderItems);
  };

  return (
    <Stack
      sx={{
        p: '12px 20px 16px 20px',
        border: `1px solid ${style.border}`,
        borderRadius: '8px',
        gap: '8px',
        cursor: isDisabled ? 'default' : 'pointer',
        position: 'relative',
        backgroundColor: style.background,
        '&:hover': {
          // cursor: 'pointer',
          backgroundColor: style.hoverBackground,
        },
      }}
      onClick={onSelect}
    >
      <Stack sx={{ pl: '8px', gap: '8px' }}>
        {orderItems.map((orderItem, index) => (
          <ToothRow key={index} isSelected={isSelected} isDisabled={isDisabled} orderItem={orderItem} />
        ))}
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          right: 20,
          width: '24px',
          height: '24px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!isDisabled && isSelected ? (
          <Ricon
            icon={'ri-checkbox-circle-fill'}
            svgProps={{
              fill: style.iconFill,
            }}
          />
        ) : (
          <Stack
            sx={{
              border: `1px solid ${style.iconBorder}`,
              backgroundColor: style.iconFill,
              borderRadius: '50%',
              width: '20px',
              height: '20px',
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

const ToothRow = ({
  isSelected,
  isDisabled,
  orderItem,
}: {
  isSelected: boolean;
  isDisabled: boolean;
  orderItem: OrderItem;
}) => {
  const { t } = useTranslation();
  const { prosthesis, method, material, shape, toothNumber } = orderItem;
  const style = getStyle(isSelected, isDisabled);
  return (
    <Stack sx={{ gap: '4px' }}>
      <Typography variant={'H16'} color={style.text}>
        <Trans i18nKey={FDIToPalmer[toothNumber]?.short} values={{ variable: FDIToPalmer[toothNumber]?.num ?? '##' }} />
      </Typography>
      <Stack sx={{ gap: '2px' }}>
        <ToothContentRow
          title={t('remake.product_type')}
          label={capitalizeFirstLetter(prosthesis)}
          color={style.text}
          isSelected={isSelected}
        />
        <ToothContentRow
          title={t('remake.product_option')}
          label={`${capitalizeFirstLetter(method)} - ${capitalizeFirstLetter(material)} - ${capitalizeFirstLetter(shape)}`}
          color={
            isSelected
              ? theme.palette.primary.main
              : isDisabled
                ? theme.palette.text.thirdary
                : theme.palette.text.secondary
          }
          isSelected={isSelected}
        />
      </Stack>
    </Stack>
  );
};

const getStyle = (isSelected: boolean, isDisabled: boolean) => {
  const styles = {
    disabled: {
      border: theme.palette.grey['300'],
      text: theme.palette.text.thirdary,
      background: theme.palette.grey['200'],
      hoverBackground: theme.palette.grey['200'],
      iconBorder: theme.palette.grey['500'],
      iconFill: theme.palette.grey['300'],
    },
    selected: {
      border: theme.palette.blue['500'],
      text: theme.palette.primary.main,
      background: 'inherit',
      hoverBackground: theme.palette.grey['100'],
      iconBorder: 'inherit',
      iconFill: theme.palette.primary.main,
    },
    default: {
      border: theme.palette.grey['300'],
      text: theme.palette.text.secondary,
      background: 'inherit',
      hoverBackground: theme.palette.grey['100'],
      iconBorder: theme.palette.grey['500'],
      iconFill: theme.palette.background.white,
    },
  };

  if (isDisabled) return styles.disabled;
  if (isSelected) return styles.selected;
  return styles.default;
};

const convertToOrderItemRequest = (items: OrderItem[]): OrderItemRequest[] => {
  return items.map((item) => ({
    ...SingleProductDefaultValue,
    productId: item.productId, // Optional 필드
    prosthesis: item.prosthesis,
    toothNumber: FDIToCustomSelectorNumber[item.toothNumber],
    groupId: item.groupId || null,
    groupType: item.groupType || null,
    method: item.method,
    material: item.material,
    shape: item.shape,
    // premiumFinishing: item.premiumFinishing,
  }));
};
