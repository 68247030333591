import { Button, Dialogue, DialogueActions, DialogueContent, DialogueTitle } from '@imago-cloud/design-system';

export const RefreshTokenExpiredDialogue = () => {
  const onButtonClick = () => {
    window.location.replace(`${process.env.REACT_APP_ACCOUNTS_CLIENT_URL!}`);
  };
  return (
    <Dialogue open={true}>
      <DialogueTitle>Session expired</DialogueTitle>
      <DialogueContent>Your session has expired.</DialogueContent>
      <DialogueActions>
        <Button size="36" color="primary" variant="contained" onClick={onButtonClick}>
          OK
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
