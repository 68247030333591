import { useRecoilState } from 'recoil';

import { dialogState, DialogType } from './atoms';

const useGlobalDialog = () => {
  const [dialogList, setDialogList] = useRecoilState(dialogState);

  const currentDialogInfo = dialogList[0];
  const DIALOG_PRIORITY = ['global', 'alert', 'common'];
  const sortDialogListByPriority = (dialogList: DialogType[]) => {
    return dialogList.sort((a, b) => {
      const indexA = DIALOG_PRIORITY.indexOf(a.type);
      const indexB = DIALOG_PRIORITY.indexOf(b.type);

      // 1. type에 따라 정렬
      if (indexA !== indexB) {
        return indexA - indexB;
      }

      // 2. type이 같다면 priority에 따라 정렬
      const priorityA = a.priority ?? Infinity;
      const priorityB = b.priority ?? Infinity;

      return priorityA - priorityB;
    });
  };

  const addDialog = (newDialog: DialogType) => {
    setDialogList((prevList) => sortDialogListByPriority([...prevList, { ...newDialog }]));
  };

  const removeDialog = (id: string) => {
    setDialogList((prevList) => sortDialogListByPriority(prevList.filter((dialog) => dialog.id !== id)));
  };

  const removeCurrent = () => {
    setDialogList((prevList) => {
      const newArr = prevList.slice(1);
      return sortDialogListByPriority(newArr);
    });
  };

  return { currentDialogInfo, dialogList, addDialog, removeDialog, removeCurrent };
};

export default useGlobalDialog;
