import { Order } from 'client/types.gen';

export type ExtendedOrderItem = Order['orderItems'][number] & { isAvailable?: boolean };
type GroupedOrderItems = Record<string, ExtendedOrderItem[]>;

const groupOrderItems = (orderItems: Order['orderItems']): GroupedOrderItems => {
  return orderItems.reduce((acc, item) => {
    const { groupId, groupType, toothNumber } = item;
    if (groupType === 'BRIDGE' && groupId) {
      if (!acc[groupId]) acc[groupId] = [];
      acc[groupId].push(item);
    } else {
      acc[toothNumber] = [item];
    }

    return acc;
  }, {} as GroupedOrderItems);
};

const sortGroupedOrderItemsByToothNumber = (groupedOrderItems: GroupedOrderItems) =>
  Object.entries(groupedOrderItems).sort(([, itemsA], [, itemsB]) => itemsA[0].toothNumber - itemsB[0].toothNumber);

export const groupAndSortOrderItems = (orderItems: Order['orderItems']) => {
  const groupedOrderItems = groupOrderItems(orderItems);
  return sortGroupedOrderItemsByToothNumber(groupedOrderItems);
};
