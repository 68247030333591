import { FileResponse } from 'client/types.gen';
import ky from 'ky';
import { IParsedOrderFile } from 'pages/OrderDetail/components/Viewer/Viewer';

import { convertFileToMeshRenderingObject } from './convertFileToMeshRenderingObject';

export const getFileExtension = (fileName: string) => fileName?.split('.').reverse()[0].toLowerCase();
export const getFileNameExcludeExtension = (fileName: string) => fileName?.split('.')[0].toLowerCase();

const getFileFromFileUrl = async (fileUrl: string, fileName: string) => {
  const blob = await ky.get(fileUrl).blob();
  return new File([blob], fileName ?? '');
};

const getRenderingObject = async (objectFile: FileResponse, textureFiles: FileResponse[]) => {
  const file = await getFileFromFileUrl(objectFile.fileUrl || '', objectFile.fileName);
  const textureFileList = await Promise.all(
    textureFiles.map(async ({ fileName, fileUrl }) => await getFileFromFileUrl(fileUrl || '', fileName)),
  );
  return await convertFileToMeshRenderingObject(file, textureFileList);
};

const getRenderingDataList = async (parsedOrderFile: IParsedOrderFile[]) =>
  await Promise.all(
    parsedOrderFile.map(async ({ textureFiles, ...objectFile }) => {
      const renderingData = await getRenderingObject(objectFile, textureFiles);
      return {
        id: objectFile.id,
        name: objectFile.fileName,
        renderingData,
      };
    }),
  );

const getParsedOrderFile = (files: FileResponse[]) => {
  const objectFiles = files.filter(({ fileName }) =>
    ['STL', 'PLY', 'OBJ'].includes(getFileExtension(fileName).toUpperCase()),
  );

  return objectFiles.map((objectFile) => {
    const { fileName: objectFileName } = objectFile || {};

    const textureFiles = files.filter(
      ({ fileName }) =>
        getFileNameExcludeExtension(objectFileName) === getFileNameExcludeExtension(fileName) &&
        getFileExtension(objectFileName) !== getFileExtension(fileName),
    );
    return {
      ...objectFile,
      textureFiles,
    };
  });
};

export const getOrderFileToRenderingData = (orderFile: FileResponse[]) =>
  getRenderingDataList(getParsedOrderFile(orderFile));
