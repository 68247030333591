import { handleNumberFieldInputValidate } from '../../utils/inputHandler';
import { AddressFormType } from './formType';

export const ADDRESS_FORM: AddressFormType = {
  title: 'dialog_complete_profile.subtitle_office_address',
  fullAddress: {
    label: 'organization.full_address',
    name: 'fullAddress',
    inputProps: { maxLength: 128 },
    rules: { required: 'organization.pls_enter_full_address' },
    placeholder: 'organization.enter_full_address',
  },
  detailAddress: {
    label: 'organization.detail_address',
    name: 'detailAddress',
    inputProps: { maxLength: 128 },
    rules: { required: 'organization.pls_enter_detail_address' },
    placeholder: 'organization.enter_detail_address',
  },
  zipCode: {
    label: 'organization.zip_code',
    name: 'zipCode',
    inputProps: { maxLength: 7, onInput: handleNumberFieldInputValidate },
    rules: { required: 'organization.please_enter_zip_code' },
    placeholder: 'organization.enter_zip_code',
  },
};

export const ORGANIZATION_NAME_FORM = {
  title: 'dialog_complete_profile.subtitle_organization_name',
  name: {
    label: 'organization.enter_organization_name',
    name: 'name',
    inputProps: { maxLength: 128 },
    rules: { required: 'dialog_complete_profile.error_helper_text_organization_name' },
  },
};

export const PHONE_NUMBER_FORM = {
  title: 'dialog_complete_profile.text_field_phone_number',
  number: {
    name: 'businessPhone',
    label: 'search_organization_dialog.enter_organization_phone_number',
    inputProps: { maxLength: 28, onInput: handleNumberFieldInputValidate },
    rules: { required: 'dialog_complete_profile.error_helper_text_phone_number' },
    helperText: 'search_organization_dialog.helper_text',
  },
};

export const ORGANIZATION_CODE_FORM = {
  DENTAL_CLINIC: {
    title: 'search_organization_dialog.medical_institution_code',
    name: 'corporateNumber',
    label: 'search_organization_dialog.enter_medical_institution_code',
    inputProps: { maxLength: 10, onInput: handleNumberFieldInputValidate },
    rules: { required: 'organization_name.please_enter_medical_institution_code' },
  },
  DENTAL_LAB: {
    title: 'search_organization_dialog.dental_lab_code',
    name: 'corporateNumber',
    label: 'search_organization_dialog.enter_dental_lab_code',
    inputProps: { maxLength: 28 },
    rules: { required: 'organization_name.Please_enter_dental_lab_code' },
  },
};

export const ORGANIZATION_DIALOGUE_STATE = {
  completeProfile: {
    title: 'search_organization_dialog.complete_profile',
    list: [
      { value: 'search_organization_dialog.this_is_an_unsigned' },
      { value: 'search_organization_dialog.this_is_an_unsigned2' },
    ],
  },
  registerOrganization: {
    title: 'search_organization_dialog.register_organization',
    list: [
      { value: 'dialog_search_organization.body1' },
      {
        value: 'dialog_search_organization.body2',
      },
    ],
  },
};
