import { useState } from 'react';

export interface ICloseButtonOnlyDialogueProps {
  open: boolean;
  onClose: () => void;
}

export interface IDialogueControl {
  open: boolean;
  handleOpenDialogue: () => void;
  handleCloseDialogue: () => void;
}

export const useDialogue = () => {
  const [open, setOpen] = useState(false);

  const handleOpenDialogue = () => {
    setOpen(true);
  };

  const handleCloseDialogue = () => {
    setOpen(false);
  };

  return { open, handleOpenDialogue, handleCloseDialogue };
};
