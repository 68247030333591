import { FileResponse } from 'client/types.gen';
import JSZip from 'jszip';

export const downloadFile = async (url: string, filename: string) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('File download failed:', error);
  }
};

export const downloadFilesAsZip = async (files: FileResponse[], zipFilename: string) => {
  const zip = new JSZip();

  const downloadPromises = files.map(async (file) => {
    if (!file.fileUrl) throw new Error('File URL is missing');

    const blob = await fetch(file.fileUrl).then((res) => {
      if (!res.ok) throw new Error(`Failed to fetch ${file.fileName}`);
      return res.blob();
    });
    return { blob, fileName: file.fileName };
  });

  const results = await Promise.allSettled(downloadPromises);

  results
    .filter((result) => result.status === 'fulfilled')
    .forEach(({ value: { fileName, blob } }) => {
      zip.file(fileName, blob);
    });

  const zipBlob = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: { level: 9 } });

  // 생성된 ZIP 파일 다운로드
  const blobUrl = URL.createObjectURL(zipBlob);
  downloadFile(blobUrl, `${zipFilename}.zip`);
  URL.revokeObjectURL(blobUrl);
};

export const validateFile = (file: File, maxSize: number, allowedTypes: string[]): boolean => {
  if (file.size > maxSize) return false;
  if (!allowedTypes.includes(file.type)) return false;
  return true;
};
