import { useEffect } from 'react';

import { useAddChattingUrlOnOrder } from '../../../api/orderQuery';
import { RetryChatInfo } from '../../../pages/OrderDetail/OrderDetail';
import { useCreateChannel, useSendbirdSdkInstance } from './useCreateChannel';

interface GroupChannelRetryProps {
  // children: React.ReactNode;
  retryChatInfo: RetryChatInfo;
}

export default function GroupChannelRetry({ retryChatInfo }: GroupChannelRetryProps) {
  const sdk = useSendbirdSdkInstance();
  const { mutateAsync: createChannelAsync } = useCreateChannel();
  const { mutateAsync: patchChannelUrl } = useAddChattingUrlOnOrder();

  useEffect(() => {
    if (!sdk) return;
    const createChannel = async () => {
      try {
        const channel = await createChannelAsync({
          orderId: retryChatInfo.orderId,
          ordererId: retryChatInfo.ordererId,
          patientName: retryChatInfo.patientName,
        });

        if (!channel) return;
        return channel;
      } catch (err) {
        console.log(err);
      }
    };

    if (!retryChatInfo.chattingChannelUrl) {
      createChannel().then((channel) => {
        if (!channel) {
          return;
        }
        patchChannelUrl({ orderId: retryChatInfo.orderId, chattingChannelUrl: channel.url })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => console.log(err));
      });
    }
  }, [retryChatInfo, sdk]);

  return null;
}
