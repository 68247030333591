import { useSuspenseQuery } from '@tanstack/react-query';
import {
  designManufactureCombination,
  GetDistinctProsthesisByVariantResponse,
  GetMaterialsByProsthesisAndMethodResponse,
  GetMethodsByProsthesisResponse,
  GetShapesByProsthesisMethodAndMaterialResponse,
} from 'client/types.gen';

import { kyInstance } from './kyInstance';

// TODO: use Async Boundary
export const useProsthesisByOrderType = (designManufactureCombination: designManufactureCombination) => {
  return useSuspenseQuery<GetDistinctProsthesisByVariantResponse>({
    queryKey: useProsthesisByOrderType.getKey(designManufactureCombination),
    queryFn: () =>
      kyInstance
        .get(`connect/products/prosthesis-by-variant?designManufactureCombination=${designManufactureCombination}`)
        .json(),
  });
};
useProsthesisByOrderType.getKey = (designManufactureCombination: designManufactureCombination) => [
  'prothesis',
  designManufactureCombination,
];

export const useMethodsByProsthesis = ({
  designManufactureCombination,
  prosthesis,
}: {
  designManufactureCombination: designManufactureCombination;
  prosthesis: string;
}) => {
  return useSuspenseQuery<GetMethodsByProsthesisResponse>({
    queryKey: useMethodsByProsthesis.getKey(designManufactureCombination, prosthesis),
    queryFn: () =>
      kyInstance
        .get(
          `connect/products/methods?designManufactureCombination=${designManufactureCombination}&prosthesis=${prosthesis}`,
        )
        .json(),
  });
};
useMethodsByProsthesis.getKey = (designManufactureCombination: designManufactureCombination, prosthesis: string) => [
  'methods',
  designManufactureCombination,
  prosthesis,
];

export const useMaterialsByMethods = ({
  designManufactureCombination,
  prosthesis,
  method,
}: {
  designManufactureCombination: designManufactureCombination;
  prosthesis: string;
  method: string;
}) => {
  return useSuspenseQuery<GetMaterialsByProsthesisAndMethodResponse>({
    queryKey: useMaterialsByMethods.getKey({
      designManufactureCombination,
      prosthesis,
      method,
    }),
    queryFn: () =>
      kyInstance
        .get(
          `connect/products/materials?designManufactureCombination=${designManufactureCombination}&prosthesis=${prosthesis}&method=${method}`,
        )
        .json(),
  });
};
useMaterialsByMethods.getKey = ({
  designManufactureCombination,
  prosthesis,
  method,
}: {
  designManufactureCombination: designManufactureCombination;
  prosthesis: string;
  method: string;
}) => ['material', designManufactureCombination, prosthesis, method];

export const useShapesByPrevious = ({
  designManufactureCombination,
  prosthesis,
  method,
  material,
}: {
  designManufactureCombination: designManufactureCombination;
  prosthesis: string;
  method: string;
  material: string;
}) => {
  return useSuspenseQuery<GetShapesByProsthesisMethodAndMaterialResponse>({
    queryKey: useShapesByPrevious.getKey({
      designManufactureCombination,
      prosthesis,
      method,
      material,
    }),
    queryFn: () =>
      kyInstance
        .get(
          `connect/products/shapes?designManufactureCombination=${designManufactureCombination}&prosthesis=${prosthesis}&method=${method}&material=${material}`,
        )
        .json(),
  });
};
useShapesByPrevious.getKey = ({
  designManufactureCombination,
  prosthesis,
  method,
  material,
}: {
  designManufactureCombination: designManufactureCombination;
  prosthesis: string;
  method: string;
  material: string;
}) => ['shape', designManufactureCombination, prosthesis, method, material];
