import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';

export const CommonWrapper = ({
  children,
  buttonText,
  onButtonClick,
  buttonDisabled,
  isLoading,
}: {
  children: ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Stack sx={{ p: '20px 24px 32px 24px', gap: '20px' }}>{children}</Stack>
      {/*button optional 인자로 받은거 있으면 보여주기. */}
      {buttonText && onButtonClick && (
        <Stack sx={{ p: '20px 24px', borderTop: '1px solid #DEE1E5' }}>
          <Button
            size={60}
            disabled={buttonDisabled}
            sx={{ width: '100%' }}
            onClick={onButtonClick}
            loading={isLoading}
          >
            {t(buttonText)}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
