interface PalmerInfo {
  short: string;
  full: string;
  num: string;
}

interface FDIConverterToPalmer {
  [key: number]: PalmerInfo;
}

const upperRight = {
  short: 'remake.ur',
  full: 'dialog_product_option.upper_right',
};

const upperLeft = {
  short: 'remake.ul',
  full: 'product_list.upper_left',
};

const lowerLeft = {
  short: 'remake.ll',
  full: 'dialog_product_option.lower_left',
};

const lowerRight = {
  short: 'remake.lr',
  full: 'dialog_product_option.lower_right',
};

export const FDIToPalmer: FDIConverterToPalmer = {
  11: { ...upperRight, num: '1' },
  12: { ...upperRight, num: '2' },
  13: { ...upperRight, num: '3' },
  14: { ...upperRight, num: '4' },
  15: { ...upperRight, num: '5' },
  16: { ...upperRight, num: '6' },
  17: { ...upperRight, num: '7' },
  18: { ...upperRight, num: '8' },

  21: { ...upperLeft, num: '1' },
  22: { ...upperLeft, num: '2' },
  23: { ...upperLeft, num: '3' },
  24: { ...upperLeft, num: '4' },
  25: { ...upperLeft, num: '5' },
  26: { ...upperLeft, num: '6' },
  27: { ...upperLeft, num: '7' },
  28: { ...upperLeft, num: '8' },

  31: { ...lowerLeft, num: '1' },
  32: { ...lowerLeft, num: '2' },
  33: { ...lowerLeft, num: '3' },
  34: { ...lowerLeft, num: '4' },
  35: { ...lowerLeft, num: '5' },
  36: { ...lowerLeft, num: '6' },
  37: { ...lowerLeft, num: '7' },
  38: { ...lowerLeft, num: '8' },

  41: { ...lowerRight, num: '1' },
  42: { ...lowerRight, num: '2' },
  43: { ...lowerRight, num: '3' },
  44: { ...lowerRight, num: '4' },
  45: { ...lowerRight, num: '5' },
  46: { ...lowerRight, num: '6' },
  47: { ...lowerRight, num: '7' },
  48: { ...lowerRight, num: '8' },
};

interface ICustomSelectorToFDI {
  [key: number]: number;
}

export const CustomSelectorToFDI: ICustomSelectorToFDI = {
  1: 18,
  2: 17,
  3: 16,
  4: 15,
  5: 14,
  6: 13,
  7: 12,
  8: 11,
  //
  9: 21,
  10: 22,
  11: 23,
  12: 24,
  13: 25,
  14: 26,
  15: 27,
  16: 28,
  //
  21: 48,
  22: 47,
  23: 46,
  24: 45,
  25: 44,
  26: 43,
  27: 42,
  28: 41,
  //
  29: 31,
  30: 32,
  31: 33,
  32: 34,
  33: 35,
  34: 36,
  35: 37,
  36: 38,
};

export const FDIToCustomSelectorNumber: ICustomSelectorToFDI = {
  18: 1,
  17: 2,
  16: 3,
  15: 4,
  14: 5,
  13: 6,
  12: 7,
  11: 8,
  //
  21: 9,
  22: 10,
  23: 11,
  24: 12,
  25: 13,
  26: 14,
  27: 15,
  28: 16,
  //
  48: 21,
  47: 22,
  46: 23,
  45: 24,
  44: 25,
  43: 26,
  42: 27,
  41: 28,
  //
  31: 29,
  32: 30,
  33: 31,
  34: 32,
  35: 33,
  36: 34,
  37: 35,
  38: 36,
};

// warn
// export function ConvertToFDI(item: unknown) {
//   if (!(typeof item === 'object' && item !== null && 'toothNumber' in item)) return;
//
//   const obj = item as { toothNumber: number };
//   const converted = (obj.toothNumber = CustomSelectorToFDI[obj.toothNumber]);
//   console.log(converted);
//   return converted;
// }
