import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Cicon, IconButton, Ricon, Switch, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { CommonWrapper } from 'pages/OrderDetail/components/ActionComponents/CommonWrapper';
import { FILE_EXTENSIONS_3D } from 'shared/constants/file';
import { getThumbnailBlob } from 'shared/hooks/useModelThumbnail';

import { useUploadFile } from '../../../../api/fileQuery';
import { useOrderStatusChangeMutation } from '../../../../api/orderQuery';

export const DesignUpload = ({
  designConfirmProcessIncluded,
  orderId,
}: {
  designConfirmProcessIncluded: boolean;
  orderId: string;
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [designUploading, setDesignUploading] = useState(false);
  const [requireConfirm, setRequireConfirm] = useState(designConfirmProcessIncluded);

  const { mutateAsync: fileUploadMutate } = useUploadFile();
  const { mutateAsync: statusChangeMutate } = useOrderStatusChangeMutation(orderId);

  const isDirtyCofirm = designConfirmProcessIncluded !== requireConfirm;

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files: selectedFiles } = event.target;

    if (selectedFiles) {
      const uniqueFiles = Array.from(selectedFiles).filter(
        (file) => !files.some((existingFile) => existingFile.name === file.name),
      );
      setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
      event.target.value = '';
    }
  };
  const handleFileDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  interface FileInfo {
    fileId: string;
    createdAt: string;
  }
  const handleDesignFileUpload = async () => {
    if (!files || files.length === 0) return;
    setDesignUploading(true);

    try {
      const fileInfo = await uploadFiles(files);

      if (fileInfo.length > 0) {
        await updateStatus(fileInfo);
      } else {
        console.warn('No files were successfully uploaded.');
      }
    } catch (error) {
      console.error('File upload process failed:', error);
    } finally {
      setDesignUploading(false);
    }
  };

  const uploadFiles = async (files: File[]): Promise<FileInfo[]> => {
    const fileInfo: FileInfo[] = [];
    const uploadPromises = files.map(async (file) => {
      const ext = file.name.split('.').pop();
      if (!ext || !FILE_EXTENSIONS_3D.includes(ext)) return null;

      const blob = await getThumbnailBlob(file);
      if (!blob) return null;

      try {
        const response = await fileUploadMutate({ file, thumbnail: blob });
        const { data } = response;
        if (data?.id && data?.createdAt) {
          return { fileId: data.id, createdAt: data.createdAt };
        } else {
          throw new Error('Invalid file data');
        }
      } catch (error) {
        console.error(`File upload failed for ${file.name}:`, error);
        return null;
      }
    });

    const results = await Promise.allSettled(uploadPromises);
    results.forEach((result) => {
      if (result.status === 'fulfilled' && result.value) {
        fileInfo.push(result.value as FileInfo);
      }
    });

    return fileInfo;
  };

  const updateStatus = async (fileInfo: FileInfo[]) => {
    const statusChangeMutations = [];

    if (isDirtyCofirm) {
      statusChangeMutations.push(() => statusChangeMutate({ status: 'confirmation' }));
    }

    statusChangeMutations.push(() => statusChangeMutate({ status: 'designUpload', body: { fileInfo } }));

    for (const mutate of statusChangeMutations) {
      try {
        await mutate();
      } catch (error) {
        throw new Error('Failed to update status');
      }
    }
  };

  return (
    <CommonWrapper
      buttonText={t('patient_page_status.btn_upload') as string}
      onButtonClick={handleDesignFileUpload}
      buttonDisabled={files.length === 0}
      isLoading={designUploading}
    >
      {files.length > 0 && (
        <Stack sx={{ gap: '12px', mb: '16px' }}>
          {files.map((file, index) => (
            <DesignFileRow key={index} fileName={file.name} onDelete={() => handleFileDelete(index)} />
          ))}
        </Stack>
      )}
      <Button
        component="label"
        size={'48'}
        variant={'outlined'}
        endIcon={<Cicon icon={'CtaAdd'} svgProps={{ fill: theme.palette.primary.main }} />}
        sx={{ width: '100%' }}
      >
        {t('patient_page_status.btn_add_files')}
        {/*<VisuallyHiddenInput type="file" />*/}
        <input type="file" multiple hidden onChange={handleFileSelect} accept=".stl, .obj, .ply, .dcm" />
      </Button>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '12px',
          backgroundColor: theme.palette.grey['100'],
          borderRadius: '8px',
        }}
      >
        <Typography variant={'Subtitle_Semibold16'}>
          {t('patient_page_status.toggle_require_design_confirm_process')}
        </Typography>
        <Switch
          disabled={designConfirmProcessIncluded}
          onChange={() => setRequireConfirm(!requireConfirm)}
          checked={requireConfirm}
        />
      </Stack>
    </CommonWrapper>
  );
};

const DesignFileRow = ({ fileName, onDelete }: { fileName: string; onDelete: () => void }) => {
  return (
    <Stack
      sx={{
        p: '12px',
        pl: '16px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.grey['500']} `,
      }}
    >
      <Typography
        variant={'Subtitle_Semibold16'}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {fileName}
      </Typography>
      <IconButton size={'24'} variant={'transparent2'} onClick={onDelete}>
        <Ricon icon={'ri-close-fill'} svgProps={{ fill: theme.palette.grey['800'] }} />
      </IconButton>
    </Stack>
  );
};
