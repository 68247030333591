import { useOrderState } from '../../pages/MyOrderList/atoms/useOrderState';
import { STATUS_FILTER_OPTIONS, StatusType } from '../../pages/MyOrderList/constants/menuOptions';

export const useStatusFilter = () => {
  const totalStatusLength = STATUS_FILTER_OPTIONS.length;
  const { orderQueryParams, updateOrderQueryParams } = useOrderState();

  const isAllSelected = orderQueryParams.filteredStatus?.length === totalStatusLength;

  const handleStatusChange = (option: StatusType) => {
    console.log(option);
    const currentStatusArray = orderQueryParams.filteredStatus || [];
    let updatedStatusArray;

    if (currentStatusArray?.includes(option)) {
      // Remove the option if it's already selected
      updatedStatusArray = currentStatusArray.filter((status) => status !== option);
    } else {
      // Add the option if it's not selected
      updatedStatusArray = [...currentStatusArray, option];
    }

    // Immediately update the query with the new status list
    updateOrderQueryParams({ filteredStatus: updatedStatusArray });
  };

  const handleSelectAllChange = () => {
    const updatedStatusArray = isAllSelected ? [] : STATUS_FILTER_OPTIONS.map((option) => option.value);

    // Immediately update the query with the selected all or cleared status list
    updateOrderQueryParams({ filteredStatus: updatedStatusArray });
  };

  return {
    filteredStatus: orderQueryParams.filteredStatus,
    isAllSelected,
    handleStatusChange,
    handleSelectAllChange,
  };
};
