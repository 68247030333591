import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Submit } from 'pages/NewOrder/components/Submit/Submit';
import NewOrderDataProvider from 'pages/NewOrder/context/NewOrderDataProvider';

import { ImportData, Prescription, Remake } from './components/index';

type OrderStepType = 'RemakeToothSelect' | 'ImportData' | 'Prescription' | 'Submit';

export default function NewOrder() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isRemakePage = pathname.includes('remake');
  const [step, setStep] = useState<OrderStepType>();
  const [isRetain, setIsRetain] = useState(true);

  useEffect(() => {
    if (isRemakePage) {
      setStep('RemakeToothSelect');
    } else {
      setIsRetain(false);
      setStep('ImportData');
    }
  }, [isRemakePage]);

  return (
    <NewOrderDataProvider>
      {isRemakePage && step === 'RemakeToothSelect' && (
        <Remake
          onNext={() => {
            setStep('ImportData');
          }}
        />
      )}
      {step === 'ImportData' && (
        <ImportData
          onNext={() => {
            setIsRetain(true);
            setStep('Prescription');
          }}
          onPrevious={() => {
            if (isRemakePage) setStep('RemakeToothSelect');
            else navigate(-1);
          }}
          isRetainForm={isRetain}
        />
      )}
      {step === 'Prescription' && (
        <Prescription
          onPrevious={() => {
            setIsRetain(true);
            setStep('ImportData');
          }}
          onNext={() => setStep('Submit')}
        />
      )}
      {step === 'Submit' && <Submit onPrevious={() => setStep('Prescription')} />}
    </NewOrderDataProvider>
  );
}
