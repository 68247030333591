import React from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Typography,
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';
import { ICloseButtonOnlyDialogueProps } from 'shared/hooks/useDialogue';
import { FormProvider } from 'shared/ui';
import { RHFTextField } from 'shared/ui/RHF';

import { OrderShippingCreateRequest } from '../../../../client';
import { OrganizationAddressField, OrganizationPhoneNumberField } from '../../../../shared/ui/organization/CommonField';

export const EditShippingInformationDialogue = ({ open, onClose }: ICloseButtonOnlyDialogueProps) => {
  const { t } = useTranslation();
  const { shippingData, setShippingData } = useNewOrderDataContext();

  const methods = useForm<OrderShippingCreateRequest>({
    mode: 'onSubmit',
    defaultValues: {
      shippingAddress: {
        country: shippingData.shippingAddress.country ?? 'Japan',
        fullAddress: shippingData.shippingAddress.fullAddress ?? '',
        detailAddress: shippingData.shippingAddress.detailAddress ?? '',
        zipCode: shippingData.shippingAddress.zipCode ?? '',
      },
      receiver: {
        name: shippingData.receiver.name ?? '',
        number: shippingData.receiver.number ?? '',
      },
    },
  });
  const {
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { isValid, isDirty },
  } = methods;

  const onSubmit = async (data: OrderShippingCreateRequest) => {
    setShippingData((prev) => ({
      ...prev,
      shippingAddress: data.shippingAddress,
      receiver: data.receiver,
    }));
    onClose();
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.target.name as FieldPath<OrderShippingCreateRequest>;
    const value = watch(name);
    if (typeof value === 'string') {
      setValue(name, value.trim());
    }
    trigger(name);
  };

  return (
    <Dialogue
      open={open}
      PaperProps={{
        sx: {
          width: '540px !important',
          minWidth: '540px',
        },
      }}
    >
      <DialogueTitle>{t('dialog_edit_shipping_info.title')}</DialogueTitle>
      <DialogueContent sx={{ paddingTop: '16px !important', maxHeight: '784px' }}>
        <FormProvider id={'shippingInformationEditDialogue'} methods={methods}>
          <Stack direction="column" gap="60px">
            {/*Name*/}
            <Stack direction="column" gap="20px">
              <Typography variant="H16">{t('dialog_edit_shipping_info.shipping_information')}</Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap="12px">
                <RHFTextField
                  label={t('dialog_edit_shipping_info.first_name') as string}
                  name="receiver.name.firstName"
                  rules={{
                    required: t('edit_shipping_information.please_enter_first_name'),
                  }}
                  inputProps={{ maxLength: 128 }}
                  onBlur={onBlur}
                />
                <RHFTextField
                  label={t('dialog_edit_shipping_info.last_name') as string}
                  name="receiver.name.lastName"
                  rules={{
                    required: t('edit_shipping_information.please_enter_last_name'),
                  }}
                  inputProps={{ maxLength: 128 }}
                  onBlur={onBlur}
                />
              </Stack>
            </Stack>
            {/*Organization address*/}
            <OrganizationAddressField onBlur={onBlur} name="shippingAddress" />
            {/*Phone number*/}
            <OrganizationPhoneNumberField onBlur={onBlur} name={'receiver.number'} />
          </Stack>
        </FormProvider>
      </DialogueContent>
      <DialogueActions>
        <Button size="36" color="grey" variant="outlined" sx={{ minWidth: '100px' }} onClick={onClose}>
          {t('dialog_edit_shipping_info.btn_cancel.93')}
        </Button>
        <Button
          type="submit"
          disabled={!isValid || !isDirty}
          size="36"
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
          sx={{ minWidth: '100px' }}
        >
          {t('dialog_edit_shipping_info.btn_update.22')}
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
