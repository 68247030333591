import { useEffect, useState } from 'react';
import _ from 'lodash';
import { searchFilterDefault, searchFiltersState } from 'pages/MyOrderList/atoms/atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const useOrderState = () => {
  const orderQueryParams = useRecoilValue(searchFiltersState);
  const setOrderQueryParams = useSetRecoilState(searchFiltersState);
  const [isUnfiltered, setIsUnfiltered] = useState<boolean>(false);
  useEffect(() => {
    // orderQueryParams가 변경될 때마다 기본값과 동일한지 확인
    const isSame = isObjectsSameIgnoreArrayOrder(orderQueryParams, searchFilterDefault);
    setIsUnfiltered(isSame);
  }, [orderQueryParams]);

  const updateOrderQueryParams = (newParams: Partial<typeof orderQueryParams>) => {
    setOrderQueryParams((prev) => ({ ...prev, ...newParams }));
  };
  const resetOrderQueryParams = () => {
    setOrderQueryParams(searchFilterDefault);
  };
  return { orderQueryParams, updateOrderQueryParams, resetOrderQueryParams, isUnfiltered };
};
function deepSortArrays(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(deepSortArrays).sort(); // 배열을 재귀적으로 정렬
  } else if (_.isPlainObject(obj)) {
    return _.mapValues(obj, deepSortArrays); // 객체의 값들도 재귀적으로 정렬
  }
  return obj; // 배열이나 객체가 아니면 그대로 반환
}

// Compare objects while ignoring array order
function isObjectsSameIgnoreArrayOrder(obj1: any, obj2: any): boolean {
  const sortedObj1 = deepSortArrays(obj1);
  const sortedObj2 = deepSortArrays(obj2);
  return _.isEqual(sortedObj1, sortedObj2);
}
