import { atom } from 'recoil';

import { GetOrders1Data, GetOrdersData } from '../../../client';

export const searchFilterDefault: GetOrdersData | GetOrders1Data = {
  startDate: undefined,
  endDate: undefined,
  filteredStatus: [
    'ORDERED',
    'DESIGNING',
    'WAITING_DESIGN_CONFIRMATION',
    'MANUFACTURING',
    'SHIPPED',
    'COMPLETED',
    'CANCELED',
    'REJECTED',
  ],
  sort: ['statusUpdatedAt', 'DESC'],
  searchFilterType: 'PATIENT_NAME',
  searchKeyword: '',
};

export const searchFiltersState = atom<GetOrdersData | GetOrders1Data>({
  key: 'searchFiltersState',
  default: searchFilterDefault,
});
