import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { Cicon, CiconProps, IconButton, Link, MenuItem, Ricon, theme } from '@imago-cloud/design-system';
import { alpha, ListItemIcon, ListItemText, styled, useTheme } from '@mui/material';
import { IMenuItem, IMenuItemChildren } from 'constants/menuItem';

interface NavItemProps {
  item: IMenuItem | IMenuItemChildren;
  isCollapse?: boolean;
  open?: boolean;
  active?: boolean;

  onOpen?: () => void;
  isSub?: boolean;
}

export default function NavItem({
  item,
  isCollapse = false,
  open = false,
  active = false,

  onOpen = () => {},
  isSub = false,
}: Readonly<NavItemProps>) {
  const { t } = useTranslation();
  const { title, children, path, icon, selectedIcon } = {
    icon: '',
    selectedIcon: '',
    children: undefined,
    ...item,
  };
  const navigate = useNavigate();
  const theme = useTheme();

  const renderContentRoot = (
    <>
      {icon && (
        <ListItemIcon
          sx={{
            minWidth: isCollapse ? '24px !important' : '36px',
            transition: (theme) =>
              theme.transitions.create('all', {
                duration: theme.transitions.duration.standard,
              }),
          }}
        >
          <Cicon
            icon={active ? (selectedIcon as CiconProps['icon']) : (icon as CiconProps['icon'])}
            svgProps={{
              width: 24,
              height: 24,
            }}
          />
        </ListItemIcon>
      )}
      <ListItemTextStyle disableTypography primary={t(title)} isCollapse={isCollapse} />
      {!isCollapse && (
        <>
          {children && (
            <IconButton
              size="28"
              variant="transparent"
              color="black"
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
              sx={{ mr: '2px' }}
            >
              <Ricon
                icon={open ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'}
                svgProps={{
                  fill: active ? theme.palette.primary.main : theme.palette.grey[800],
                }}
              />
            </IconButton>
          )}
        </>
      )}
    </>
  );

  const renderContentSub = (
    <>
      <ListItemIcon />
      <ListItemText disableTypography primary={t(title)} />
    </>
  );

  const renderContent = isSub ? renderContentSub : renderContentRoot;
  if (children) {
    return (
      <StyledMenuItem
        onClick={() => {
          navigate(children[0]?.path);
        }}
        isactive={active?.toString()}
        issub={Boolean(isSub)?.toString()}
      >
        {renderContent}
      </StyledMenuItem>
    );
  }

  return isExternalLink(path) ? (
    <Link color="primary" underline="none" href={path} target="_blank" rel="noopener" data-cy={`link-${item.title}`}>
      <StyledMenuItem>{renderContent}</StyledMenuItem>
    </Link>
  ) : (
    <RouterLink to={path} style={{ all: 'unset' }} data-cy={`link-${item.title}`}>
      <StyledMenuItem isactive={active?.toString()} issub={isSub?.toString()}>
        {renderContent}
      </StyledMenuItem>
    </RouterLink>
  );
}

const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<{ isCollapse: boolean }>(({ isCollapse, theme }) => ({
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
    flex: 0,
  }),
}));

const isExternalLink = (path: string) => path.includes('http');

const StyledMenuItem = styled(MenuItem)<{
  issub?: string;
  isactive?: string;
  activeroot?: string;
}>`
  padding: 14px;
  height: auto;
  background-color: ${(props) =>
    props.isactive === 'true' && props.issub === 'false' ? alpha(theme.palette.primary.main, 0.08) : 'white'};
  color: ${(props) => (props.isactive === 'true' ? theme.palette.primary.main : theme.palette.grey[800])};
  ${(props) => (props.isactive === 'true' ? theme.typography.H16 : theme.typography.Subtitle_Semibold16)}
`;
