import React from 'react';
import { Button, Loading } from '@imago-cloud/design-system';
import { ErrorPage } from '@imago-cloud/design-system/lib/esm/Page/Error';
import { Stack } from '@mui/material';
import withAsyncBoundary from 'hoc/withAsyncBoundary';
import humanizeString from 'humanize-string';
import { ProthesisSelectionPropType } from 'pages/NewOrder/components/Prescription/ProductOptionDialog/ProsthesisSelection';
import useNewOrderDataContext from 'pages/NewOrder/context/useNewOrderDataContext';

import { useProsthesisByOrderType } from '../../../../../api/productQuery';

const ProsthesisButtonList = ({ onProsthesisClick }: ProthesisSelectionPropType) => {
  const {
    orderData: { designManufactureCombination },
  } = useNewOrderDataContext();
  const {
    data: { data: prosthesisList },
  } = useProsthesisByOrderType(designManufactureCombination!);

  return (
    <>
      {prosthesisList?.map(({ value }) => (
        <Button
          onClick={() => onProsthesisClick(value)}
          key={value}
          size="60"
          color="grey"
          variant="outlined"
          sx={{ width: '100%' }}
        >
          {humanizeString(value)}
        </Button>
      ))}
    </>
  );
};

export default withAsyncBoundary(ProsthesisButtonList, {
  pendingFallback: (
    <Stack sx={{ height: '200px' }} justifyContent="center">
      <Loading type="basic" size="24" />
    </Stack>
  ),
  rejectedFallback: <ErrorPage />,
});
