import { AlertProps } from '@imago-cloud/design-system';
import { AlertColor } from '@mui/material';
import { atom } from 'recoil';

export interface IAlert extends AlertProps {
  open: boolean;
  severity?: AlertColor;
  title?: string;
  description?: string | null;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  onClose?: () => void;
}

export const DEFAULT_TOP_ALERT_STATE: IAlert = {
  open: false,
  severity: undefined,
  title: undefined,
  description: null,
  actionButtonText: undefined,
  onActionButtonClick: undefined,
  onClose: undefined,
};

export const topAlertState = atom({
  key: 'topAlert',
  default: DEFAULT_TOP_ALERT_STATE,
});
