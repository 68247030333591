import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { theme } from '@imago-cloud/design-system';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { TFunction } from 'i18next';

import Logo from '../../../../assets/DentbirdLogo.svg';
import { OrderDetailResponse, OrderItemRequest } from '../../../../client';
import { FDIToCustomSelectorNumber, FDIToPalmer } from '../../../../shared/utils/convertToPalmer';
import { getFormatDate, getFormatDateWithTime } from '../../../../shared/utils/formatDate';
import { capitalizeFirstLetter, formattedAddress, formattedPrice } from '../../../../shared/utils/formatString';
import { SelectedToothListType } from '../../../NewOrder/components/Prescription/Palmer';
import { BRIDGE_STYLE, BRIDGE_STYLE_KEY } from '../../../NewOrder/constants/BridgeSelectedStyle';
import {
  RX_BRIDGE_DATA_SET,
  RX_PALMER_DATA_SET,
  RX_SQUARE_BRIDGE_SET,
  RX_SQUARE_DATA_SET,
} from '../../../NewOrder/constants/RxSelector.constant';
import { TOOTH_STYLE } from '../../../NewOrder/constants/ToothSelectedStyle';
import { DESIGN_MANUFACTURE_COMBINATION } from '../../constants/manufactureDesignMap';

const PATIENT_INFO = ({ patient, orderedAt, orderer }: OrderDetailResponse, t: TFunction) => [
  { key: t('print_rx.created'), value: `${getFormatDateWithTime(orderedAt ?? '')}` },
  { key: t('print_rx.patient_name_(o/n)'), value: `${patient.name}` },
  {
    key: t('print_rx.birthdate_gender'),
    value: t(`patient_page_info.patient_info_${patient.gender?.toLowerCase()}`, {
      variable1: getFormatDate(patient.birth),
      variable2: patient.age || '-',
    }),
  },
  { key: t('print_rx.organization'), value: `${orderer.organization.name}` },
  { key: t('print_rx.user'), value: `${orderer.name.firstName} ${orderer.name.lastName}` },
];

const ORDER_INFO = (
  { designManufactureCombination, patient, designConfirmProcessIncluded }: OrderDetailResponse,
  t: TFunction,
) => [
  { key: t('print_rx.order_range'), value: t(DESIGN_MANUFACTURE_COMBINATION[designManufactureCombination]) },
  { key: t('print_rx.try-in_date'), value: `${patient.tryInDate ? getFormatDate(patient.tryInDate) : '-'}` },
  { key: t('print_rx.visit_date'), value: `${patient.visitDate ? getFormatDate(patient.visitDate) : '-'}` },
  {
    key: t('print_rx.design_confirm_process'),
    value: t(`patient_page_info.${designConfirmProcessIncluded ? 'include' : 'exclude'}`),
  },
];

const PAYMENT_INFO = (
  {
    orderItems,
    printingFilesAmount,
    shippingFee,
    billingAmount,
    orderAmount,
    patientPrintingFilesResponse,
  }: OrderDetailResponse,
  t: TFunction,
) => {
  const orderIemList = orderItems.map((item) => ({
    key: t(FDIToPalmer[item.toothNumber]?.full, {
      variable: FDIToPalmer[item.toothNumber]?.num ?? '##',
    }),
    value: formattedPrice(item.amount, t),
  }));
  return [
    { key: t('print_rx.item', { count: orderItems.length }), value: formattedPrice(orderAmount, t) },
    ...orderIemList,
    ...(patientPrintingFilesResponse.length > 0
      ? [{ key: t('print_rx.additional_options'), value: formattedPrice(printingFilesAmount, t) }]
      : []),
    { key: t('print_rx.shipping_fee'), value: formattedPrice(shippingFee, t) },
    { key: `Divider` },
    { key: t('print_rx.total'), value: formattedPrice(billingAmount, t) },
  ];
};

interface Iprops {
  userOrder: OrderDetailResponse;
  rxPrintRef: any;
}

export const OrderPrintRx = ({ rxPrintRef, userOrder }: Iprops) => {
  const { t } = useTranslation();
  const { orderItems } = userOrder;

  const selectedToothList = orderItems.map(({ groupId, material, prosthesis, toothNumber }) => {
    return { groupId, material, prosthesis, toothNumber: FDIToCustomSelectorNumber[toothNumber] };
  });

  const immutableOrderItems = orderItems.map((item) => {
    const updated = { ...item };
    updated.toothNumber = FDIToCustomSelectorNumber[updated.toothNumber];
    return updated;
  });
  const selectedBridgeNumberPairList = immutableOrderItems
    .sort((a, b) => a.toothNumber - b.toothNumber)
    .reduce<Array<OrderItemRequest>[]>((acc, item) => {
      const lastGroup = acc[acc.length - 1];
      if (
        lastGroup &&
        lastGroup[lastGroup.length - 1].toothNumber === item.toothNumber - 1 &&
        lastGroup[0].material === item.material &&
        item.groupType === 'BRIDGE' &&
        lastGroup[0].groupId === item.groupId
      ) {
        lastGroup.push(item);
      } else {
        acc.push([item]);
      }
      return acc;
    }, [])
    .filter((group) => {
      return group.length > 1;
    })
    .flatMap((group) => {
      const pairs: Array<string> = [];
      for (let i = 0; i < group.length; i++) {
        if (group[i] === group[group.length - 1])
          continue; // 마지막 요소 진행 x
        else if (group[i].groupId && group[i + 1].groupType) {
          pairs.push(`${group[i].toothNumber}-${group[i + 1].toothNumber}`);
        }
      }
      return pairs;
    });

  return (
    <Box
      ref={rxPrintRef}
      sx={{
        fontFamily: 'Inter',
        position: 'absolute',
        top: '100%',
        right: '100%',
        padding: '30px',
        width: '210mm',
        minHeight: 'fit-content',
        boxSizing: 'border-box',
        // backgroundColor: '#fff',
      }}
    >
      <PatientInfoSection userOrder={userOrder} />

      <Divider style={{ margin: '12px 0' }} />

      <Stack>
        <Box>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>
            {t('print_rx.information')}
          </Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap="20px"
          sx={{ width: '100%', height: '256px' }}
        >
          <Stack sx={{ width: '132px', height: '224px', margin: '16px 30px' }}>
            <RxPalmer
              selectedToothList={selectedToothList}
              selectedBridgeNumberPairList={selectedBridgeNumberPairList}
            />
          </Stack>
          <Box sx={{ width: '300px', height: '120px' }}>
            {/* 270 x 90*/}
            <RxSquare
              selectedToothList={selectedToothList}
              selectedBridgeNumberPairList={selectedBridgeNumberPairList}
            />
          </Box>
        </Stack>
      </Stack>

      <Divider style={{ margin: '12px 0' }} />

      <OrderInfoSection userOrder={userOrder} />
      <Divider style={{ margin: '12px 0' }} />
    </Box>
  );
};

export const LowerPrintRx = ({ rxPrintRef, userOrder }: Iprops) => {
  return (
    <Box
      ref={rxPrintRef}
      sx={{
        fontFamily: 'Inter',
        position: 'absolute',
        top: '100%',
        right: '100%',
        padding: '30px',
        width: '210mm',
        minHeight: 'fit-content',
        boxSizing: 'border-box',
        // backgroundColor: '#fff',
      }}
    >
      <PaymentInfoSection userOrder={userOrder} />
      <Divider style={{ margin: '6px 0' }} />

      <ShippingAddressInfoSection userOrder={userOrder} />
      <Divider style={{ margin: '12px 0' }} />
    </Box>
  );
};

interface Props {
  userOrder: OrderDetailResponse;
}
const PatientInfoSection = ({ userOrder }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction="row">
        {/* Logo */}
        <Box sx={{ marginRight: '24px' }}>
          <img width={'46px'} src={Logo} alt="Dentbird logo" />
        </Box>

        {/* Patient Info */}
        <Stack style={{ width: '100%' }}>
          {PATIENT_INFO(userOrder, t).map((info) => (
            <Stack key={info.key} direction="row" gap="20px">
              <Box width="120px">
                <Typography fontWeight={'600'} fontSize={'8px'} lineHeight={'12px'}>
                  {info.key}
                </Typography>
              </Box>
              <Box width="100%">
                <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
                  {info.value}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>

        {/* Lab Info. */}
        <Stack direction="row" sx={{ width: '100%' }}>
          <Box sx={{ width: '110px' }}>
            <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
              {t('print_rx.laboratory_info.')}
            </Typography>
          </Box>
          {userOrder.lab ? (
            <Box sx={{ width: '100%' }}>
              <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
                {userOrder.lab.organization.name}
              </Typography>
              <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
                {formattedAddress({
                  ...userOrder.lab.organization.address!,
                })}
              </Typography>
            </Box>
          ) : (
            <Box width="100%">
              <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
                -
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
      <Divider style={{ margin: '12px 0' }} />

      <Stack sx={{ marginBottom: '12px' }}>
        {ORDER_INFO(userOrder, t).map(({ key, value }) => (
          <Stack key={key} direction="row" gap="20px">
            <Box width="156px">
              <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>
                {key}
              </Typography>
            </Box>
            <Box width="100%">
              <Typography fontWeight={'400'} fontSize={'8px'} lineHeight={'12px'}>
                {value}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

const OrderInfoSection = ({ userOrder }: Props) => {
  const { t } = useTranslation();
  const imageFiles2D = userOrder.patientFilesResponse.filter((fileInfo) => {
    const extension = fileInfo.fileName.split('.').pop()?.toLowerCase() as string;
    return (
      extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'bmp' || extension === 'webp'
    );
  });

  return (
    <>
      <Stack>
        <Box sx={{ marginBottom: '8px' }}>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>
            {t('print_rx.order_item')}
          </Typography>
        </Box>

        <Stack gap="6px">
          {userOrder.orderItems.map((item) => {
            const { toothNumber, amount, hook, premiumFinishing, hookPrice, premiumFinishingPrice } = item;
            const productType = `${t('patient_page_info.title_product_type')} ${item.prosthesis}`;
            const productOption = `${t('patient_page_info.title_product_option')} ${
              item.method
            } - ${item.material} - ${item.shape}`;
            const shadeType = `${t('patient_page_info.title_shade')} ${
              item.shadeType === 'ETC' ? t('dialog_select_shade.etc') : item.shadeType
            }`;
            const hookOption = `${t('product_list.hook')} ${capitalizeFirstLetter(hook)} ${hookPrice ? `(+ ${formattedPrice(hookPrice, t)})` : ''}`;
            const finishOption = `${t('finish_option.title')}: ${t(`finish_option.table_item_title_${premiumFinishing ? 'premium' : 'basic'}`)} ${premiumFinishingPrice ? `(+ ${formattedPrice(premiumFinishingPrice, t)})` : ''}`;

            return (
              <Stack
                key={toothNumber}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ gap: '66px' }}
              >
                <Box>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
                    {t(FDIToPalmer[toothNumber as number]?.short, {
                      variable: FDIToPalmer[toothNumber as number]?.num ?? '##',
                    })}
                  </Typography>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
                    {`${productType}, ${productOption}, ${shadeType}, ${hookOption}, ${finishOption}`}
                  </Typography>
                </Box>
                <Box sx={{ minWidth: 'fit-content' }}>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
                    {formattedPrice(amount, t)}
                  </Typography>
                </Box>
              </Stack>
            );
          })}
        </Stack>
      </Stack>

      <Divider style={{ margin: '12px 0' }} />

      {userOrder.patientPrintingFilesResponse.length > 0 && (
        <>
          <Stack>
            <Box sx={{ marginBottom: '8px' }}>
              <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>
                {t('print_rx.additional_options')}
              </Typography>
            </Box>

            <Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
                    {`${t('order_overview.3d_model')} (${t('order_overview.order_quantity')} ${userOrder.patientPrintingFilesResponse.length} ${t(`print_rx.${userOrder.patientPrintingFilesResponse.length > 1 ? 'files' : 'file'}`)})`}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
                    {formattedPrice(userOrder.printingFilesAmount, t)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>

          <Divider style={{ margin: '12px 0' }} />
        </>
      )}

      <Stack sx={{ marginBottom: '12px' }}>
        <Box>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>
            {t('print_rx.note')}
          </Typography>
        </Box>

        <Stack sx={{ marginTop: '12px', padding: '12px 8px', backgroundColor: '#f6f7f8' }} justifyContent="center">
          <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
            {userOrder.note || '-'}
            {/*TEST: Make the future of digital medicine for everyone...! I'm just wondering if this section would be wandered or*/}
            {/*not, when it's tooooooo long enought to go longggg*/}
          </Typography>
        </Stack>
      </Stack>

      <Stack>
        <Box sx={{ marginBottom: '12px' }}>
          <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>
            {t('print_rx.photos')}
          </Typography>
        </Box>

        {imageFiles2D.length ? (
          <Stack gap="20px" direction="row" sx={{ flexWrap: 'wrap', padding: '16px 20px', backgroundColor: '#f6f7f8' }}>
            {imageFiles2D.map((fileInfo) => (
              <img key={fileInfo.fileName} src={fileInfo.fileUrl} alt={fileInfo.fileName} height="90" />
            ))}
          </Stack>
        ) : (
          <Stack sx={{ padding: '16px 8px', backgroundColor: '#f6f7f8' }} justifyContent="center">
            <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
              -
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

const PaymentInfoSection = ({ userOrder }: Props) => {
  const { t } = useTranslation();
  const paymentList = PAYMENT_INFO(userOrder, t);

  return (
    <Stack>
      <Box sx={{ marginBottom: '8px' }}>
        <Typography fontWeight={'700'} fontSize={'10px'} lineHeight={'14px'}>
          {t('print_rx.payment')}
        </Typography>
      </Box>

      <Stack gap="4px" sx={{ width: '220px' }}>
        {paymentList.map((item, index) => {
          const boldIndices = ['0', (paymentList.length - 1).toString()];
          const colorChangeIndex = paymentList.length - 1;

          if (item.key === 'Divider') return <Divider key={item.key} style={{ margin: '2px 0' }} />;
          return (
            <Stack direction="row" justifyContent="space-between" key={item.key + index}>
              <Box>
                <Typography
                  fontWeight={boldIndices.includes(index.toString()) ? '600' : '400'}
                  fontSize={'10px'}
                  lineHeight={'14px'}
                >
                  {item.key}
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontWeight={boldIndices.includes(index.toString()) ? '600' : '400'}
                  fontSize={'10px'}
                  lineHeight={'14px'}
                  sx={{
                    fontFamily: 'inherit',
                    color: index === colorChangeIndex ? theme.palette.primary.main : '#000',
                  }}
                >
                  {item.value}
                </Typography>
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

type PalmerPropType = {
  selectedToothList: SelectedToothListType; // toothNumber[]
  onToothClick?: (clickedToothNumber: number) => void;
  onBridgeClick?: (clickedBridgeNumberPair: string) => void;
  selectedBridgeNumberPairList: Array<string>;
  style?: Record<string, string>;
};

const ShippingAddressInfoSection = ({ userOrder }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack>
        <Box sx={{ marginBottom: '12px' }}>
          <Typography variant="H10">{t('print_rx.shipping_address')}</Typography>
        </Box>

        <Stack direction="column">
          <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
            {userOrder.receiver?.number.number}
          </Typography>
          <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>
            {formattedAddress(userOrder.shippingAddress!)}
          </Typography>
          <Typography
            fontWeight={'400'}
            fontSize={'10px'}
            lineHeight={'14px'}
            sx={{ fontFamily: 'inherit', marginTop: '4px' }}
          >
            {`${t('shipping_information.title_delivery_instruction')} ${userOrder.deliveryInstruction || '-'}`}
          </Typography>
        </Stack>
      </Stack>

      {/*<Divider style={{ margin: '12px 0' }} />*/}

      {/*<Stack>*/}
      {/*  <Box sx={{ marginBottom: '8px' }}>*/}
      {/*    <Typography variant="H10">{`${t('print_rx.payment_method')}`}</Typography>*/}
      {/*  </Box>*/}
      {/*  <Box>*/}
      {/*    <Typography fontWeight={'400'} fontSize={'10px'} lineHeight={'14px'}>*/}
      {/*      {t('Pay later')}*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</Stack>*/}
    </>
  );
};

const RxPalmer = ({
  selectedToothList,
  onToothClick,
  onBridgeClick,
  selectedBridgeNumberPairList,
  style,
}: Readonly<PalmerPropType>) => {
  const { pathname } = useLocation();
  const isViewOnly = pathname.includes('remake');

  const getToothStyle = (id: number) => {
    const selected = selectedToothList.find((selected) => selected.toothNumber === id);
    if (!selected) return 'DEFAULT';

    if (selected && selected.prosthesis === '') return 'CLICKED';
    else if (selected.prosthesis === 'CROWN') return 'CROWN_SELECTED';
    else if (selected.prosthesis === 'IN_ONLAY' || selected.prosthesis === 'ONLAY') return 'INLAY_ONLAY_SELECTED';
    else if (selected && selected.prosthesis !== '') return 'CROWN_SELECTED';
    else return 'DEFAULT';
  };

  const getBridgeStyle = (id: string): BRIDGE_STYLE_KEY => {
    if (selectedBridgeNumberPairList.includes(id)) return 'SELECTED';
    // if (availableBridgeList.includes(id)) return 'AVAILABLE';
    return 'DEFAULT';
  };

  return (
    <Box component={'svg'} sx={{ width: '100%', height: '100%' }}>
      {/* Permanent 경우 */}
      {RX_BRIDGE_DATA_SET.map(({ id, cx, cy, r, transform }) => {
        return (
          <Box
            key={id}
            component="circle"
            onClick={() => {
              if (isViewOnly) return;
              onBridgeClick && onBridgeClick(id);
            }}
            sx={{ ...BRIDGE_STYLE[getBridgeStyle(id as BRIDGE_STYLE_KEY)] }}
            cx={cx}
            cy={cy}
            r={r}
            transform={transform && transform}
          />
        );
      })}
      {RX_PALMER_DATA_SET.map((el) => {
        return (
          <Box
            key={el.id}
            component={'g'}
            onClick={() => {
              if (isViewOnly) return;
              onToothClick && onToothClick(el.id);
            }}
            sx={{
              ...TOOTH_STYLE[getToothStyle(el.id)],
              ...(isViewOnly ? TOOTH_STYLE.VIEW_ONLY : {}),
              width: '132px',
              height: '224px',
            }}
          >
            <path className="tooth" d={el.tooth.d} stroke={theme.palette.grey[500]} fill={theme.palette.common.white} />
            <path className="text" d={el.text.d} fill={theme.palette.text.secondary} />
          </Box>
        );
      })}
    </Box>
  );
};

const RxSquare = ({
  selectedToothList,
  onToothClick,
  onBridgeClick,
  selectedBridgeNumberPairList,
  style,
}: Readonly<PalmerPropType>) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isViewOnly = pathname.includes('remake');

  const getToothStyle = (id: number) => {
    const selected = selectedToothList.find((selected) => selected.toothNumber === id);
    if (!selected) return 'DEFAULT';

    if (selected && selected.prosthesis === '') return 'CLICKED';
    else if (selected.prosthesis === 'CROWN') return 'CROWN_SELECTED';
    else if (selected.prosthesis === 'IN_ONLAY' || selected.prosthesis === 'ONLAY') return 'INLAY_ONLAY_SELECTED';
    else if (selected && selected.prosthesis !== '') return 'CROWN_SELECTED';
    else return 'DEFAULT';
  };

  const getBridgeStyle = (id: string): BRIDGE_STYLE_KEY => {
    if (selectedBridgeNumberPairList.includes(id)) return 'SELECTED';
    // if (availableBridgeList.includes(id)) return 'AVAILABLE';
    return 'DEFAULT';
    // return 'SELECTED';
  };

  return (
    <>
      <Typography variant="Subtitle_Semibold10" sx={{ marginBottom: '8px' }}>
        {t('combination.dropdown_permanent')}
      </Typography>
      <Box component={'svg'} sx={{ width: '100%', height: '100%' }}>
        <rect x="12" y="33" width="245" height="1" rx="0.5" fill="#D9D9D9" />
        <rect x="132" y="53" width="39" height="1" transform="rotate(-90 132 53)" fill="#D9D9D9" />
        <path
          d="M0.639205 35V29.1818H2.71307C3.16383 29.1818 3.53788 29.2595 3.83523 29.4148C4.13447 29.5701 4.35795 29.785 4.50568 30.0597C4.65341 30.3324 4.72727 30.6477 4.72727 31.0057C4.72727 31.3617 4.65246 31.6752 4.50284 31.946C4.35511 32.215 4.13163 32.4242 3.83239 32.5739C3.53504 32.7235 3.16098 32.7983 2.71023 32.7983H1.1392V32.0426H2.63068C2.91477 32.0426 3.14583 32.0019 3.32386 31.9205C3.50379 31.839 3.63542 31.7206 3.71875 31.5653C3.80208 31.41 3.84375 31.2235 3.84375 31.0057C3.84375 30.786 3.80114 30.5956 3.71591 30.4347C3.63258 30.2737 3.50095 30.1506 3.32102 30.0653C3.14299 29.9782 2.90909 29.9347 2.61932 29.9347H1.51705V35H0.639205ZM3.51136 32.375L4.94886 35H3.94886L2.53977 32.375H3.51136Z"
          fill="#6A6E74"
        />
        <path d="M261.639 35V29.1818H262.517V34.2443H265.153V35H261.639Z" fill="#6A6E74" />
        {/*Permanent 경우*/}
        {RX_SQUARE_BRIDGE_SET.map(({ id, cx, cy, r, transform }) => {
          return (
            <Box
              key={id}
              component="circle"
              // onClick={() => {
              //   if (isViewOnly) return;
              //   onBridgeClick && onBridgeClick(id);
              // }}
              sx={{ ...BRIDGE_STYLE[getBridgeStyle(id as BRIDGE_STYLE_KEY)] }}
              cx={cx}
              cy={cy}
              r={r}
              transform={transform}
              // stroke="#CDD1D5"
            />
          );
        })}
        {RX_SQUARE_DATA_SET.map((el) => {
          return (
            <Box
              key={el.id}
              component={'g'}
              onClick={() => {
                if (isViewOnly) return;
                onToothClick && onToothClick(el.id);
              }}
              sx={{
                ...TOOTH_STYLE[getToothStyle(el.id)],
                ...(isViewOnly ? TOOTH_STYLE.VIEW_ONLY : {}),
                width: '132px',
                height: '224px',
                fill: '#fff',
              }}
            >
              <path
                className="tooth"
                d={el.tooth.d}
                stroke={theme.palette.grey[500]}
                fill={theme.palette.common.white}
              />
              <rect
                className="square"
                x={el.tooth.x}
                y={el.tooth.y}
                width={el.tooth.width}
                height={el.tooth.height}
                rx={el.tooth.rx}
                stroke="#6A6E74"
              />
              <path className="text" d={el.text.d} fill={theme.palette.text.secondary} />
            </Box>
          );
        })}
      </Box>
    </>
  );
};
