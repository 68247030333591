import { DialogType } from '../../../store/globalDialog/atoms';

export const IMPORT_FILE_LIMITATIONS: DialogType = {
  id: 'import_file_upload_limitations',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialog_upload_limit_exceeded.title',
    content: 'dialog_upload_limit_exceeded.body',
    color: 'error',
    trigger: {
      action: {
        text: 'dialog_upload_limit_exceeded.btn_confirm',
        onClick: () => {},
      },
    },
  },
};

export const UNABLE_ORDER_PRODUCT: DialogType = {
  id: 'dialog_unable_to_order_product',
  priority: 3,
  type: 'common',
  dialogContent: {
    title: 'dialog_unable_to_order_product.title',
    content: 'dialog_unable_to_order_product.body',
    color: 'error',
    trigger: {
      action: {
        text: 'dialog_unable_to_order_product.btn_confirm',
        onClick: () => {},
      },
    },
  },
};
