import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@imago-cloud/design-system';

type SearchTargetProps = {
  value: string;
  onChange: (event: React.ChangeEvent<{ value: string }>) => void;
  options: { label: string; value: string }[];
};

export const SearchTarget = ({ value, onChange, options }: SearchTargetProps) => {
  const { t } = useTranslation();

  return (
    <Select sx={{ width: '220px' }} value={value} onChange={onChange}>
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {t(label)}
        </MenuItem>
      ))}
    </Select>
  );
};
