import { ROUTES } from './routes';

export interface IMenuItem {
  title: string;
  path: string;
  icon: string;
  selectedIcon: string;
  children?: IMenuItemChildren[];
}

export interface IMenuItemChildren {
  title: string;
  path: string;
  id: string;
  index?: boolean;
}

export const MENU_ITEMS: IMenuItem[] = [
  {
    title: 'user_side_snb.dashboard',
    path: ROUTES.DASHBOARD_ROUTER.root,
    icon: 'NavbarDashboard',
    selectedIcon: 'NavbarDashboardBlue',

    children: [
      {
        title: 'user_side_snb.my_statistics',
        path: `${ROUTES.DASHBOARD_ROUTER.root}/${ROUTES.DASHBOARD_ROUTER.placedDashboard}`,
        id: 'connect:placed_order_dashboard',
      },
      {
        title: 'partner_lab_side_snb.received_statistics',
        path: `${ROUTES.DASHBOARD_ROUTER.root}/${ROUTES.DASHBOARD_ROUTER.receiveDashboard}`,
        id: 'connect:received_order_dashboard',
      },
    ],
  },
  {
    title: 'user_side_snb.orders',
    path: `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.placedOrderList}`,
    icon: 'NavbarCaseList',
    selectedIcon: 'NavbarCaseListBlue',
    children: [
      {
        title: 'partner_lab_side_snb.received_orders',
        path: `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.receivedOrderList}`,
        id: 'connect:received_order_list',
      },
      {
        title: 'user_side_snb.my_orders',
        path: `${ROUTES.ORDER_ROUTER.root}/${ROUTES.ORDER_ROUTER.placedOrderList}`,
        id: 'connect:placed_order_list',
      },
    ],
  },
  {
    title: 'user_side_snb.my_organization',
    path: `${ROUTES.MY_ORGANIZATION_ROUTER.root}/${ROUTES.MY_ORGANIZATION_ROUTER.organization}`,
    icon: 'NavbarMyInfo',
    selectedIcon: 'NavbarMyInfoBlue',
    children: [
      {
        title: 'user_side_snb.organization',
        path: `${ROUTES.MY_ORGANIZATION_ROUTER.root}/${ROUTES.MY_ORGANIZATION_ROUTER.organization}`,
        id: 'connect:organization',
      },
      {
        title: 'partner_lab_side_snb.operation',
        path: `${ROUTES.MY_ORGANIZATION_ROUTER.root}/${ROUTES.MY_ORGANIZATION_ROUTER.operation}`,
        id: 'connect:operation',
      },
      {
        title: 'partner_lab_side_snb.sales',
        path: `${ROUTES.MY_ORGANIZATION_ROUTER.root}/${ROUTES.MY_ORGANIZATION_ROUTER.sales}`,
        id: 'connect:sales',
      },
      {
        title: 'Coupon',
        path: `${ROUTES.MY_ORGANIZATION_ROUTER.root}/${ROUTES.MY_ORGANIZATION_ROUTER.coupon}`,
        id: 'connect:coupon',
      },
      {
        title: 'Payment history',
        path: `${ROUTES.MY_ORGANIZATION_ROUTER.root}/${ROUTES.MY_ORGANIZATION_ROUTER.paymentHistory}`,
        id: 'connect:payment-history',
      },
      {
        title: 'Refund history',
        path: `${ROUTES.MY_ORGANIZATION_ROUTER.root}/${ROUTES.MY_ORGANIZATION_ROUTER.refundHistory}`,
        id: 'connect:refund-history',
      },
    ],
  },
];

export const INDEX_ROUTERS = [
  `${ROUTES.MY_ORGANIZATION_ROUTER.root}/${ROUTES.MY_ORGANIZATION_ROUTER.organization}`,
  ROUTES.DASHBOARD_ROUTER.root,
];

export const ORDER_DETAIL_ROUTES = [
  `${ROUTES.ORDER_ROUTER.root}/received-order-detail`,
  `${ROUTES.ORDER_ROUTER.root}/placed-order-detail`,
];
