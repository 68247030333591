import React, { MouseEvent } from 'react';
import { IconButton, Label, Ricon, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import useBeforeUnload from 'shared/hooks/useBeforeUnload';

type BackStepButtonPropType = {
  stepName: string;
  order?: string;
  onPrevious: () => void;
  isDirty: boolean;
};
export default function BackStepTitle({ stepName, order, onPrevious, isDirty }: Readonly<BackStepButtonPropType>) {
  useBeforeUnload(isDirty);

  const handlePreviousClick = (event: MouseEvent) => {
    if (!isDirty) return onPrevious();
    if (!window.confirm('Do you want to leave this page? Any unsaved changes will be lost.')) {
      event.preventDefault();
      return false;
    }
    return onPrevious();
  };

  return (
    <Stack
      sx={{ position: 'absolute', padding: '20px', width: '600px', top: '0', left: '0', zIndex: 2 }}
      direction="row"
      gap="16px"
      alignItems="center"
    >
      <IconButton variant="outlined" size="36" color="grey800" onClick={(e) => handlePreviousClick(e)}>
        <Ricon icon={'ri-arrow-left-s-line'} />
      </IconButton>
      <Stack direction="row" gap="8px">
        <Typography variant="H18">{stepName}</Typography>
        {order && <Label color="primary">{order}</Label>}
      </Stack>
    </Stack>
  );
}
