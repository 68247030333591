import { theme } from '@imago-cloud/design-system';
import { alpha } from '@mui/material';

// TEST
const CLICKED = {
  '& > .tooth': {
    // fill: theme.palette.blue[10],
    stroke: theme.palette.primary.main,
  },
  // cursor: 'pointer',
  '& > .text': {
    fill: theme.palette.primary.main,
  },
};

const DEFAULT = {
  '&:hover': {
    '& > .tooth': {
      fill: theme.palette.blue[10],
      stroke: theme.palette.primary.main,
    },
    cursor: 'pointer',
    '& > .text': {
      fill: theme.palette.primary.main,
    },
  },
};

const CROWN_SELECTED = {
  '& > .tooth': {
    fill: theme.palette.blue[10],
    stroke: theme.palette.primary.main,
  },
  cursor: 'pointer',
  '& > .text': {
    fill: theme.palette.primary.main,
  },
  '& > .square': {
    // fill: theme.palette.grey['700'],
    fill: theme.palette.primary.main,
  },
};

const COPING_SELECTED = {
  '& > .tooth': {
    fill: alpha(theme.palette.warning.light, 0.08),
    stroke: theme.palette.warning.main,
  },
  cursor: 'pointer',
  '& > .text': {
    fill: theme.palette.warning.dark,
  },
};

const INLAY_ONLAY_SELECTED = {
  '& > .tooth': {
    fill: theme.palette.red[10],
    stroke: theme.palette.red[500],
  },
  cursor: 'pointer',
  '& > .text': {
    fill: theme.palette.red[600],
  },
  '& > .square': {
    fill: theme.palette.red[500],
  },
};
const PONTIC_SELECTED = {
  '& > .tooth': {
    fill: alpha(theme.palette.brand!.main, 0.08) ?? '#00A4B114',
    stroke: theme.palette.brand?.main ?? '#00A4B1',
  },
  cursor: 'pointer',
  '& > .text': {
    fill: theme.palette.brand?.dark ?? '#00808C',
  },
};

const VENEER_SELECTED = {
  '& > .tooth': {
    fill: alpha(theme.palette.success.main, 0.08),
    stroke: theme.palette.success.main,
  },
  cursor: 'pointer',
  '& > .text': {
    fill: theme.palette.success.dark,
  },
};
const VIEW_ONLY = {
  '&:hover': {
    cursor: 'default',
  },
};
export const TOOTH_STYLE = {
  DEFAULT,
  CROWN_SELECTED,
  COPING_SELECTED,
  INLAY_ONLAY_SELECTED,
  PONTIC_SELECTED,
  VENEER_SELECTED,
  CLICKED,
  VIEW_ONLY,
};
